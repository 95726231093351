import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './Pagination.module.scss';
const cx = classNames.bind(styles);

import NewSelect from '../select/NewSelect';
import { PaginationDto } from '../../../api/dto/PaginationDto';
import usePaginationEventV2 from './hooks/usePaginationEventV2';

const pageOptions = [10, 20, 30, 40, 50].map((v) => ({
  label: String(v),
  value: String(v),
}));

export interface Props {
  page: PaginationDto;
  countWord?: string;
}

const PaginationV2: FC<Props> = (Props) => {
  const {
    page: { totalCount },
  } = Props;
  const { next, prev, possibleNext, possiblePrev, drawPage, changeSize } = usePaginationEventV2(Props);

  return (
    <div className={cx('container')}>
      <div className={cx('left')}>
        총 {totalCount} {Props.countWord ?? '개'}
      </div>
      <div className={cx('middle')}>
        <button className={cx('prev', 'btn', possiblePrev() && 'possible')} disabled={!possiblePrev()} onClick={prev} />
        <ol className={cx('pagination')}>{drawPage()}</ol>
        <button className={cx('next', 'btn', possibleNext() && 'possible')} disabled={!possibleNext()} onClick={next} />
      </div>
      <div className={cx('right')}>
        <span className={cx('margin')}>페이지 행</span>
        <NewSelect
          size={'small'}
          options={pageOptions}
          defaultValue={{ value: '10', label: '10' }}
          onChange={changeSize}
        />
      </div>
    </div>
  );
};

export default PaginationV2;
