// Created by kdw0601 on 2022-10-20
import styles from './MonthRangePicker.module.scss'
import classNames from 'classnames/bind'
import React, { useEffect, useRef, useState } from 'react'
import MonthPicker from './MonthPicker'
import useOnClickOutside from '../useOnClickOutside'
import InputNumberStep from './InputNumberStep'

const cx = classNames.bind(styles)

interface Props {
  start: Date;
  end: Date;
  onChange?: (startDate: Date, endDate: Date) => void;
  disabled?: boolean;
}

const TODAY = new Date()

const MonthRangePicker = ({ onChange, disabled, start, end }: Props) => {
  const [startDate, setStartDate] = useState(start ?? TODAY)
  const [endDate, setEndDate] = useState(end ?? TODAY)
  const [isPickerVisible, setIsPickerVisible] = useState(false)
  const [startYear, setStartYear] = useState(start.getFullYear() ?? TODAY.getFullYear());
  const [startMonth, setStartMonth] = useState(start.getMonth() ?? TODAY.getMonth());
  const [endYear, setEndYear] = useState(end.getFullYear() ?? TODAY.getFullYear());
  const [endMonth, setEndMonth] = useState(end.getMonth() ?? TODAY.getMonth());
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => {
    setIsPickerVisible(false)
  })

  useEffect(() => {
    setStartDate(start)
    setEndDate(end)
  }, [start, end])
  
  useEffect(() => {
    const newStartDate = new Date(startYear, startMonth + 1);
    const newEndDate = new Date(endYear, endMonth + 1);
    onChange && onChange(newStartDate, newEndDate);
  }, [isPickerVisible])

  useEffect(() => {
    setStartDate(new Date(startYear, startMonth + 1));
    setEndDate(new Date(endYear, endMonth + 1));
  }, [startMonth, startYear, endMonth, endYear])

  return (
    <div className={cx('wrap')} onClick={() => !disabled && setIsPickerVisible(true)} ref={ref}>
      <MonthPicker value={startDate} onChange={setStartDate} disabled={disabled} />
      ~
      <MonthPicker value={endDate} onChange={setEndDate} disabled={disabled} />
      {isPickerVisible && (
        <div className={cx('picker-wrap')}>
          <div className={cx('picker')}>
            <InputNumberStep defaultValue={startYear} min={2000} max={9999} onChange={setStartYear} />
            년
            <InputNumberStep defaultValue={startMonth + 1} min={1} max={12} onChange={v => setStartMonth(v - 1)} />
            월
          </div>
          <div className={cx('picker')}>
            <InputNumberStep defaultValue={endYear} min={2000} max={9999} onChange={setEndYear} />
            년
            <InputNumberStep defaultValue={endMonth + 1} min={1} max={12} onChange={v => setEndMonth(v - 1)} />
            월
          </div>
        </div>
      )}
    </div>
  )
}

export default MonthRangePicker