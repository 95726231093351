import React from 'react';
import classNames from 'classnames/bind';
import style from './ContractDetailPage.module.scss';
import Layout from '../../../components/common/Layout/Layout';
import { useParams, useSearchParams } from 'react-router-dom';
import ContractDetailForm from './ContractDetailForm';

const cx = classNames.bind(style);

const ProductDetailPage = () => {
  const { id } = useParams();
  const [search] = useSearchParams();

  return (
    <Layout>
      <h1 className={cx('title')}>계약 {id ? '수정' : '등록'}</h1>
      <ContractDetailForm
        id={id ? Number(id) : undefined}
        mainContractId={Number(search.get('mainContractId')) || undefined}
      />
    </Layout>
  );
};

export default ProductDetailPage;
