import { useRef, useState } from 'react';
import useOnClickOutside from '../useOnClickOutside'

interface PopoverPosition {
  top: number;
  left: number;
  zIndex?: number;
  minWidth?: number;
}

const useListPopover = () => {
  const [popoverPosition, setPopoverPosition] = useState<PopoverPosition | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const clickAreaRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [isListMounted, setIsListMounted] = useState(false);

  useOnClickOutside(optionsRef, () => {
    setPopoverPosition(null);
  });

  const handleClickArea = () => {
    if (popoverPosition) {
      setPopoverPosition(null);
    }
  };

  const closeThenFocus = () => {
    setPopoverPosition(null);
    clickAreaRef.current?.focus();
  };

  return {
    closeThenFocus,
    popoverPosition,
    setPopoverPosition,
    clickAreaRef,
    wrapperRef,
    handleClickArea,
    optionsRef,
    isListMounted,
    setIsListMounted,
  };
};

export default useListPopover;
