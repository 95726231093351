import { createTable } from '../../../util/createTable';
import { ProductFindListV1Dto } from '../../../api/dto/ProductFindListV1Dto';
import dayjs from 'dayjs';
import { putProductUseStatus } from '../../../api/function/product';
import { UseType } from '../../../api/types/UseType';
import { useQueryClient } from 'react-query';
import Switch from '../../common/switch/Switch';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCheckAuthorizedAction } from '../../../feature/authority/utils/withAuthority';

export const productListTableBuilder = createTable({
  productId: {},
  name: {},
  createdDate: {},
  creator: {},
  lastModifiedDate: {},
  modifier: {},
  useType: {},
});

export const ProductListTableHeader = productListTableBuilder.createHeaderComponent({
  productId: { children: '상품번호' },
  name: { children: '상품명' },
  createdDate: { children: '최초 등록일' },
  creator: { children: '최종 수정일' },
  lastModifiedDate: { children: '최초 등록자' },
  modifier: { children: '최종 수정자' },
  useType: { children: '사용 여부' },
});

export const ProductListTableRow = ({ data }: { data: ProductFindListV1Dto }) => {
  const navigate = useNavigate();
  const { creator, createdDate, lastModifiedDate, modifier, productId, useType, name } = data;
  const queryClient = useQueryClient();
  const isAuthorizedToggleUse = useCheckAuthorizedAction('createEditUseProduct');

  const handleChangeUseType = (id: number, useYn: boolean) => {
    putProductUseStatus(id, useYn ? UseType.USE : UseType.UNUSED).then(() =>
      queryClient.invalidateQueries('productList')
    );
  };

  const Row = productListTableBuilder.createRowComponent({
    productId: { children: productId },
    name: { children: name },
    createdDate: { children: dayjs(createdDate).format('YYYY.MM.DD') },
    creator: { children: dayjs(lastModifiedDate).format('YYYY.MM.DD') },
    lastModifiedDate: { children: creator ?? '-' },
    modifier: { children: modifier ?? '-' },
    useType: {
      onClick: (e) => e.stopPropagation(),
      children: (
        <Switch
          isOn={useType === 'USE'}
          disabled={!isAuthorizedToggleUse}
          toggle={() => handleChangeUseType(productId, useType !== 'USE')}
        />
      ),
    },
  });

  return <Row onClick={() => navigate(`/product/${productId}`)} />;
};
