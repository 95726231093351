// Created by kdw0601 on 2024-08-08

import { PopupOpenType } from '../../../api/types/PopupOpenType';
import Switch from '../../common/switch/Switch';
import React, { useState } from 'react';
import { postPopupOpenType } from '../../../api/function/popup';
import { toggleValue } from '../../../util/toggleValue';
import { usePopupListStore } from './store/usePopupListStore';

interface Props {
  openType: PopupOpenType;
  id: number;
}

const PopupOpenSwitch = ({ id, openType }: Props) => {
  const invalidateData = usePopupListStore((state) => state.actions.invalidateData);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeOpenType = () => {
    setIsLoading(true);
    postPopupOpenType({
      id,
      openType: toggleValue(openType, PopupOpenType.HIDDEN, PopupOpenType.OPEN),
    })
      .then(invalidateData)
      .finally(() => setIsLoading(false));
  };

  return <Switch isOn={openType === PopupOpenType.OPEN} toggle={handleChangeOpenType} disabled={isLoading} />;
};

export default PopupOpenSwitch;
