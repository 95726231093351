// Created by kdw0601 on 2024-07-03
import Table from '../../common/table/Table';
import Empty from '../../common/empty/Empty';
import React from 'react';
import { popupListTableBuilder, PopupListTableHeader, PopupListTableRow } from './popupListTableBuilder';
import { PopupListDto } from '../../../api/dto/PopupListDto';

interface Props {
  list: PopupListDto[];
}

const PopupListTable = ({ list }: Props) => {
  return (
    <Table>
      <PopupListTableHeader />
      <tbody>
        {list.map((v) => (
          <PopupListTableRow key={v.id} data={v} />
        ))}
        {!list.length && (
          <tr>
            <td colSpan={popupListTableBuilder.columnCount}>
              <Empty />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default PopupListTable;
