// Created by kdw0601 on 2022-03-14
import React from 'react';
import { forwardRef, InputHTMLAttributes, useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import styles from './Toggle.module.scss';
import useForwardedRef from '../../../hooks/useForwardedRef'

const cx = classNames.bind(styles);


export interface ToggleProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'size'> {
    onToggle?: (checked: boolean) => void;
    throttleMillisec?: number;
    size?: 'large' | 'middle' | 'small'
}

const Toggle = forwardRef<HTMLInputElement, ToggleProps>(function Toggle(props: ToggleProps, ref) {
    const { onToggle, throttleMillisec, size = 'middle', className, ...attr } = props;
    const [isChecked, setIsChecked] = useState(attr.checked ?? false);
    const [isThrottling, setIsThrottling] = useState(false);
    const checkboxRef = useForwardedRef<HTMLInputElement>(ref);

    useEffect(() => {
        if (attr.checked !== undefined) {
            setIsChecked(attr.checked);
        }
    }, [attr.checked]);

    const handleClick = () => {
        if (isThrottling) {
            return;
        }

        if (throttleMillisec) {
            setIsThrottling(true);
            setTimeout(() => {
                setIsThrottling(false);
            }, throttleMillisec);
        }

        if (checkboxRef.current) {
            checkboxRef.current.click();
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onToggle && onToggle(e.currentTarget.checked);
        if (attr.checked === undefined) {
            setIsChecked(e.currentTarget.checked);
        }
    };

    return (
        <>
            <input type={'checkbox'} {...attr} hidden ref={checkboxRef} onChange={handleChange} />
            <span className={cx(className, size, 'toggleWrap', (isThrottling || attr.disabled) && 'disabled')} >
                <div className={cx('container', isChecked && 'checked')} onClick={handleClick}>
                    <div />
                </div>
            </span>
        </>
    );
});

export default Toggle;
