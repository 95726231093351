import { get, post, put } from '../../../util/fetch';
import { GetCompositionListRs, GetProductDetailV1Rs, GetProductListV1Rs, GetSimpleProductInfoV1Rs } from './rs';
import { UseType } from '../../types/UseType';
import { PostProductV1Rq } from './rq';

// 상품 리스트 조회
export const getProductListV1 = (page: { page: number; size: number }, search: string) => {
  return get<GetProductListV1Rs>(
    `/api/v1/products?page=${page.page}&sort=createdDateTime:DESC&size=${page.size}${search && `&keyword=${search}`}`
  );
};

export const getProductDetailV1 = (id: number) => {
  return get<GetProductDetailV1Rs>(`/api/v1/products/${id}`);
};

export const putProductUseStatus = (productId: number, useType: UseType) => {
  return put(`/api/v1/products/${productId}/use-status`, { useType });
};

export const postProductV1 = (rq: PostProductV1Rq) => {
  return post('/api/v1/products', rq);
};

export const getCompositionList = () => get<GetCompositionListRs>('/api/v1/compositions');

export const getSimpleProductInfoV1 = (productId: number) => {
  return get<GetSimpleProductInfoV1Rs>(`/api/v1/products/${productId}/simple`);
};
