import React, { FC, LegacyRef, useContext, useState } from 'react'
import { useQuery } from 'react-query'
import classNames from 'classnames/bind'
import Checkbox from '../common/Checkbox'
import {
  getJDValid,
  saveSelectPosition,
  searchPositionList,
} from '../../../../repositories/challenge/detail/CreateChallengeRepository'
import style from './SearchPosition.module.scss'
import { ChallengeContext } from '../../../../pages/challenge/list/ChallengeContext'
import useIntersectObserver from '../../../../hooks/useIntersectionObserver'
import { useAlert } from '../../../common/alert/AlertContextProvider'
import { AxiosError, AxiosResponse } from "axios";

const cx = classNames.bind(style)

interface Props {
  show: boolean
  setShowList: (show: boolean) => void
  search: string
  jobGroupCode: string
}

const SearchPosition: FC<Props> = ({
  show,
  search,
  setShowList,
  jobGroupCode,
}) => {
  const {
    JOB_GROUP_INFO,
    matchingChallengeId,
    setShowMessage,
    SELECT_POSITION: { revealPositions, setRevealPositions },
  } = useContext(ChallengeContext)

  const [pagination, setPagination] = useState<{
    page: number
    size: number
  }>({
    page: 1,
    size: 10,
  })

  //포지션검색결과
  const { data: searchList } = useQuery(
    ['positionList', search, jobGroupCode, pagination],
    async () => {
      const rs = await searchPositionList(search, jobGroupCode, pagination)

      return {
        contents: rs.data?.contents,
        pageMeta: rs.data?.pageMeta,
      }
    }
  )

  const { setRef } = useIntersectObserver(
    async () => {
      if ((searchList?.pageMeta?.totalItemCount ?? 20) >= pagination.size + 10)
        setPagination({ ...pagination, size: pagination.size + 10 })
    },
    {
      threshold: 0.5,
    }
  )

  const alert = useAlert()

  return show ? (
    <ul className={cx('position-list', 'modal-scroll')}>
      {searchList?.contents?.length ? (
        searchList?.contents?.map((position, i) => (
          <li
            key={position.revealPositionId}
            className={cx('position')}
            ref={
              i === searchList?.contents?.length - 1
                ? (setRef as LegacyRef<HTMLLIElement>)
                : null
            }
          >
            <Checkbox
              id={`${position.revealPositionId}position`}
              checked={
                !!revealPositions.filter(
                  (v) => v.revealPositionId === position.revealPositionId
                ).length
              }
              onChange={async (e) => {
                try {
                  if (e.target.checked) {
                    const { data } = await getJDValid({
                      positionId: position.positionId,
                      publicKey: position.publicKey,
                    })

                    if (data.isOk) {
                      await saveSelectPosition({
                        matchingChallengeId: matchingChallengeId!,
                        revealPositions: [
                          ...revealPositions,
                          { ...position, modify: false },
                        ].map(({ revealPositionId }, order) => ({
                          order,
                          revealPositionId,
                        })),
                      })

                      setRevealPositions([
                        ...revealPositions,
                        { ...position, modify: false },
                      ])
                    } else {
                      setShowMessage('잡다에 등록되지 않은 포지션 입니다.')
                      setTimeout(() => {
                        setShowMessage('')
                      }, 2000)
                    }
                  } else {
                    setRevealPositions(
                      revealPositions.filter(
                        (v) => v.revealPositionId !== position.revealPositionId
                      )
                    )
                  }
                } catch (e) {
                  setShowList(false)
                  alert((e as AxiosError<AxiosResponse>).response?.data.data.cause ?? '')
                }
              }}
            />
            <label
              className={cx('label')}
              htmlFor={`${position.revealPositionId}position`}
            >
              {position.companyName} |{' '}
              {JOB_GROUP_INFO.filter(
                ({ code }) => position.jobGroupCode === Number(code)
              )[0]?.name ?? ''}{' '}
              | {position.positionName}
            </label>
          </li>
        ))
      ) : (
        <li>포지션이 존재하지 않습니다.</li>
      )}
    </ul>
  ) : (
    <></>
  )
}

export default SearchPosition
