import React, { Suspense, useState } from 'react';
import classNames from 'classnames/bind';
import style from './ChallengePage.module.scss';
import Layout from '../../../components/common/Layout/Layout';
import Spinner from '../../../components/common/spinner/Spinner';
import ChallengeList from '../../../components/challenge/list/ChallengeList';
import ChallengeCreateModal from '../../../components/challenge/create/ChallengeCreateModal';
import { ReactComponent as AddIcon } from '../../../asset/icon/icon_add_18_line.svg';
import { ChallengeProvider } from './ChallengeContext';
import { withRedirectUnauthorized } from '../../../feature/authority/utils/withAuthority';

const cx = classNames.bind(style);

const ChallengePage = () => {
  const [createModal, setCreateModal] = useState(false);
  const [search, setSearch] = useState('');

  return (
    <ChallengeProvider>
      <>
        <Layout>
          <Suspense fallback={<Spinner />}>
            <div className={cx('container')}>
              <h1 className={cx('title')}>매칭 챌린지 목록</h1>
              <div className={cx('toolbar')}>
                <div className={cx('search')}>
                  <input placeholder={'매칭 챌린지명'} value={search} onChange={(e) => setSearch(e.target.value)} />
                </div>
                <button className={cx('create')} onClick={() => setCreateModal(true)}>
                  <AddIcon width={18} height={18} fill={'white'} />
                  매칭 챌린지 만들기
                </button>
              </div>
              <Suspense fallback={<Spinner />}>
                <ChallengeList search={search} createModal={createModal} />
              </Suspense>
            </div>
          </Suspense>
        </Layout>
        {createModal && (
          <ChallengeCreateModal
            close={() => {
              setCreateModal(false);
            }}
          />
        )}
      </>
    </ChallengeProvider>
  );
};

export default withRedirectUnauthorized('matchingChallenge', ChallengePage);
