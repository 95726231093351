// Created by kdw0601 on 2022-05-27
import React from 'react';
import { forwardRef, InputHTMLAttributes } from 'react';
import styles from './InputText.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export interface InputTextProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  password?: boolean;
  onEnter?: () => void;
  error?: string;
}

const InputText = forwardRef<HTMLInputElement, InputTextProps>(function InputText(props, ref) {
  const { password, error, onEnter, className, ...attr } = props;
  const handlePressEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onEnter && onEnter();
    }
  };

  return (
    <div className={cx('wrapper')}>
      <input
        className={cx('input', error && 'error', className)}
        type={password ? 'password' : 'text'}
        {...attr}
        onKeyDown={handlePressEnter}
        ref={ref}
      />
      {error && <div className={cx('error-message')}>{error}</div>}
    </div>
  );
});

export default InputText;
