import { post } from '../../util/fetch';
import { LoginForm } from '../../components/intro/useLoginForm';
import { LoginResponse, LoginResponseV2 } from './LoginResponse';

export const login = (request: LoginForm) => {
  return post<LoginResponse>('/api/v1/administrator/login', request);
};

export const loginV2 = (request: LoginForm) => {
  return post<LoginResponseV2>('/api/v2/administrator/login', request);
};
