// Created by kdw0601 on 2024-07-03
import { productListTableBuilder, ProductListTableHeader, ProductListTableRow } from './productListTableBuilder';
import { ProductFindListV1Dto } from '../../../api/dto/ProductFindListV1Dto';
import Table from '../../common/table/Table';
import Empty from '../../common/empty/Empty';
import React from 'react';

interface Props {
  list: ProductFindListV1Dto[];
}

const ProductListTable = ({ list }: Props) => {
  return (
    <Table>
      <ProductListTableHeader />
      <tbody>
        {list.map((v) => (
          <ProductListTableRow key={v.productId} data={v} />
        ))}
        {!list.length && (
          <tr>
            <td colSpan={productListTableBuilder.columnCount}>
              <Empty />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default ProductListTable;
