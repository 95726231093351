import React, { useCallback } from 'react';
import classNames from 'classnames/bind';

import styles from '../Pagination.module.scss';
import { Props } from '../Pagination';
import { SingleValue } from 'react-select';

const cx = classNames.bind(styles);

const usePaginationEvent = ({ page: { totalPageCount, totalItemCount, size, page }, pageHandler }: Props) => {
  const possiblePrev = useCallback(() => {
    return page !== 1;
  }, [totalPageCount, totalItemCount, size, page]);

  const possibleNext = useCallback(() => {
    return page !== totalPageCount;
  }, [totalPageCount, totalItemCount, size, page]);

  const drawPage = useCallback(() => {
    let pageList: any[] = [];
    const minScope =
      page <= 2 ? 1 : page + 2 > totalPageCount ? (totalPageCount - 5 < 1 ? 1 : totalPageCount - 4) : page - 2;
    const maxScope =
      page <= 2 ? (totalPageCount < 5 ? totalPageCount : 5) : page + 2 > totalPageCount ? totalPageCount : page + 2;

    for (let i = minScope; i <= maxScope; i++) {
      pageList = [
        ...pageList,
        <li
          key={i}
          className={cx(i === page && 'active')}
          onClick={() => {
            pageHandler({
              page: i,
              totalItemCount,
              size,
              totalPageCount,
            });
          }}
        >
          {i}
        </li>,
      ];
    }
    return pageList;
  }, [totalPageCount, totalItemCount, size, page]);

  const next = useCallback(
    () =>
      pageHandler({
        page: page + 1,
        totalItemCount,
        size,
        totalPageCount,
      }),
    [totalPageCount, totalItemCount, size, page]
  );

  const prev = useCallback(
    () =>
      pageHandler({
        page: page - 1,
        totalItemCount,
        size,
        totalPageCount,
      }),
    [totalPageCount, totalItemCount, size, page]
  );

  const changeSize = useCallback(
    (val: SingleValue<{ value: string }>) => {
      if (!val) return;

      pageHandler({
        page: 1,
        totalItemCount,
        size: Number(val.value),
        totalPageCount,
      });
    },
    [totalPageCount, totalItemCount, size, page]
  );

  return {
    next,
    prev,
    possibleNext,
    possiblePrev,
    changeSize,
    drawPage,
  };
};

export default usePaginationEvent;
