import { useRecoilState, useSetRecoilState } from 'recoil'
import { LoginVO, passwordExpiryDateAtom, sessionAtom, SessionVO } from '../store/auth.store'
import { refreshToken } from '../repositories/token/TokenRepository'
import { deleteCookie, getCookie, setCookie } from '../util/cookie'
import { loginV2 } from '../repositories/login/LoginRepository'
import { LoginForm } from '../components/intro/useLoginForm'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { useState } from 'react'

const setSessionCookie = ({ token, expiredDateTime }: SessionVO) => {
  setCookie('token', token)
  setCookie('expiredDateTime', expiredDateTime)
}

const setAuthCookie = (loginInfo: LoginVO) => {
  const { token, expiredDateTime, passwordExpiryDate } = loginInfo
  setSessionCookie({ token, expiredDateTime })
  setCookie('passwordExpiryDate', passwordExpiryDate)
}

const useAuth = () => {
  const [isCoolDown, setIsCoolDown] = useState(false)
  const [session, setSession] = useRecoilState(sessionAtom)
  const setPasswordExpiryDate = useSetRecoilState(passwordExpiryDateAtom)
  const navigate = useNavigate()

  const refreshSession = async () => {
    const {
      data: { body },
    } = await refreshToken()
    setSession(body)
  }

  const refreshSessionWithCoolDown = async () => {
    if (isCoolDown) return
    setIsCoolDown(true)
    await refreshSession()
    setIsCoolDown(false)
  }

  const checkPasswordExpired = (): boolean => {
    const passwordExpiryDate = getCookie('passwordExpiryDate')
    const today = dayjs()
    return today.isAfter(dayjs(passwordExpiryDate), 'day')
  }

  // redirect 경로 찾기
  const findRedirectUrl = () =>
    (new URLSearchParams(location.search).get('redirect') ?? '/').replace(/tanent/g, 'tenant')

  const login = async (form: LoginForm, redirectUrlAfterLogin: string) => {
    const {
      data: { body },
    } = await loginV2(form)
    const { token, expiredDateTime, passwordExpiryDate } = body

    setAuthCookie(body)
    setPasswordExpiryDate(passwordExpiryDate)
    setSession({ token, expiredDateTime })

    // 오늘이 비밀번호 만료일 이후라면 비밀번호 재설정 페이지로 이동
    const passwordExpired = checkPasswordExpired()
    if (passwordExpired) {
      navigate({ pathname: '/account/reset-password', search: `?type=expired&redirect=${redirectUrlAfterLogin}` })
    } else {
      navigate(findRedirectUrl())
    }
  }

  const logout = () => {
    deleteCookie('token')
    deleteCookie('expiredDateTime')
    deleteCookie('passwordExpiryDate')
    setSession(null)
  }

  const backToLogin = (redirectUrl: string) => {
    location.href = `/login?redirect=${redirectUrl}`
  }

  return {
    session,
    refreshSession,
    refreshSessionWithCoolDown,
    login,
    logout,
    backToLogin,
    findRedirectUrl,
    isCoolDown,
  }
}
export default useAuth
