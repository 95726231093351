import Layout from '../common/Layout/Layout';
import InputText from '../common/input/InputText';
import { useSettingsMatch } from './useSettingsMatch';
import { useEffect, useState } from 'react';
import styles from './SettingsMatch.module.scss';
import classNames from 'classnames/bind';
import ButtonCommon from '../common/button/ButtonCommon';
import { getJobGroupAndJobList, putJobMatchable } from '../../api/function/common';
import Spinner from '../common/spinner/Spinner';
const cx = classNames.bind(styles);

const SettingsMatch = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { data, actions } = useSettingsMatch();
  const isInvalid = actions.isSameAsOrigin();

  async function fetchData() {
    setLoading(true);
    setError(false);
    try {
      const { data } = await getJobGroupAndJobList();
      actions.setJobGroupListData(data.jobGroupList);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout>
      <div className={cx('container')}>
        <div className={cx('sub')}>
          <div className={cx('header')}>
            <h2>매칭 불가능 직무 설정</h2>
            <ButtonCommon
              disabled={isInvalid}
              onClick={async () => {
                await putJobMatchable(Array.from(data.value));
                await fetchData();
              }}
            >
              저장
            </ButtonCommon>
          </div>
          <h5>매칭 불가능한 직무를 선택하고 저장을 눌러주세요.</h5>
          {error ? (
            <div className={cx('error')}>
              <h4>에러가 발생했습니다.</h4>
              <ButtonCommon onClick={fetchData}>재시도</ButtonCommon>
            </div>
          ) : loading ? (
            <Spinner />
          ) : (
            <div className={cx('')}>
              <h4>직무 목록</h4>
              <div className={cx('job-groups')}>
                {Array.from(data.jobMap.entries()).map(([key, value]) => {
                  return (
                    <div key={key} className={cx('list-wrapper')}>
                      <h5>{value.name}</h5>
                      <div className={cx('list')}>
                        {value.jobList.map((v) => {
                          return (
                            <div
                              key={v.code}
                              className={cx('item')}
                              onClick={() => {
                                actions.toggleValue(v.code);
                              }}
                            >
                              <span>{`${v.name}`}</span>
                              <span className={cx(data.value.has(v.code) && 'unable')}>
                                {data.value.has(v.code) ? '불가' : '가능'}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SettingsMatch;
