// Created by kdw0601 on 2024-08-08
import styles from './PopupDetail.module.scss';
import classNames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import { usePopupDetailStore } from './hooks/usePopupDetailStore';
import React, { useEffect, useRef, useState } from 'react';
import { getPopupDetail } from '../../../api/function/popup';
import Layout from '../../common/Layout/Layout';
import InputText from '../../common/input/InputText';
import Input from '../../challenge/create/common/Input';
import dayjs from '../../../util/dayjs';
import { DatePicker } from '../../common/datePicker';
import NewSelect from '../../common/select/NewSelect';
import ContractLineButton from '../../contract/common/ContractLineButton';
import { ContractAttachFileType } from '../../../api/types/ContractAttachFileType';
import { fileUpload } from '../../../repositories/common/FileRepository';
import { getBaseUrl } from '../../../util/fetch';
import ButtonCommon from '../../common/button/ButtonCommon';
import BlackButton from '../../challenge/create/common/BlackButton';

const cx = classNames.bind(styles);

const PopupDetailImageUpload = () => {
  const { set } = usePopupDetailStore((state) => state.actions);
  const fileUid = usePopupDetailStore((state) => state.data.fileUid);
  const fileRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file, file.name);
    const { fileUid, fileName } = await fileUpload(formData);
    set('fileUid')(fileUid);
    set('fileName')(fileName);

    e.target.value = '';
  };

  const handleClickDelete = () => {
    set('fileUid')('');
    set('fileName')('');
  };

  return (
    <div className={cx('attach-wrap')}>
      <div>
        <ContractLineButton type={'button'} onClick={() => fileRef.current?.click()}>
          첨부
        </ContractLineButton>
        <input
          type={'file'}
          ref={fileRef}
          accept={'.pdf, .jpg, .jpeg, .png'}
          onChange={handleFileUpload}
          style={{ position: 'absolute', width: 0, height: 0 }}
        />
      </div>
      {fileUid && <img src={`${getBaseUrl()}/api/file/view/${fileUid}`} className={cx('popup-image')} />}
      {fileUid && (
        <div>
          <BlackButton onClick={handleClickDelete} type={'button'} size={'large'}>
            삭제
          </BlackButton>
        </div>
      )}
    </div>
  );
};

export default PopupDetailImageUpload;
