// Created by kdw0601 on 2022-12-29
import { ReactNode, useEffect } from 'react';
import List, { CustomListItemProps, MultipleVariant, SingleVariant } from '../list/List';
import ListOption from '../list/ListOption';
import Portal from '../portal/Portal';
import styles from './ListPopover.module.scss';
import classNames from 'classnames/bind';
import React from 'react';
import useListPopover from './useListPopover'

const cx = classNames.bind(styles);

export type ListPopoverAlignType = `${'top' | 'bottom'}-${'left' | 'right' | 'center'}`;

interface Props<T, CustomData = unknown> {
  children: ReactNode;
  options: ListOption<T, CustomData>[];
  align?: ListPopoverAlignType;
  gap?: number;
  zIndex?: number;
  onChange?: (val: T) => void;
  className?: string;
  minWidth?: number;
  variant?: MultipleVariant | SingleVariant;
  closeOnChange?: boolean;
  value?: T | T[];
  onChangeOpenState?: (isOpen: boolean) => void;
  customItem?: (props: CustomListItemProps<T, CustomData>) => JSX.Element | null;
  customItemHeight?: number;
  scrollAfterNItems?: number;
  forDropdown?: boolean;
  disabled?: boolean;
  color?: 'default' | 'dark';
}

/**
 * 명세서: {@link https://midasitweb-jira.atlassian.net/wiki/spaces/ATS/pages/3624402945/ListPopover}
 */
const ListPopover = <T, CustomData = unknown>(props: Props<T, CustomData>) => {
  const {
    children,
    options,
    align = 'bottom-left',
    onChange,
    className,
    minWidth = 150,
    variant,
    value,
    gap = 8,
    zIndex,
    disabled,
    onChangeOpenState,
    forDropdown,
    customItem,
    customItemHeight,
    color,
    scrollAfterNItems = forDropdown ? (variant?.endsWith('sub') ? 4 : 6) : 0,
    closeOnChange = !variant || variant.startsWith('icon'),
  } = props;
  const {
    closeThenFocus,
    popoverPosition,
    clickAreaRef,
    handleClickArea,
    setPopoverPosition,
    optionsRef,
    isListMounted,
    setIsListMounted,
  } = useListPopover();

  useEffect(() => {
    if (isListMounted && !closeOnChange) reposition();
  }, [value, closeOnChange]);

  const reposition = () => {
    if (!clickAreaRef.current) return;
    const { top, left, bottom, right, width: areaWidth } = clickAreaRef.current.getBoundingClientRect();
    let [vertical, horizontal] = align.split('-');
    const containerHeight =
      20 +
      Math.min(
        scrollAfterNItems < 1 ? Number.MAX_VALUE : scrollAfterNItems,
        options.filter(({ hidden }) => !hidden).length
      ) *
        (customItemHeight ?? (variant?.endsWith('sub') ? 52 : 32));
    const containerWidth = optionsRef.current?.offsetWidth ?? minWidth;
    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth;
    if (vertical === 'bottom' && screenHeight - bottom - containerHeight < 20) {
      vertical = 'top';
    }

    if (vertical === 'top' && top - containerHeight < 20) {
      vertical = 'bottom';
    }

    if (horizontal === 'left' && screenWidth - left - containerWidth + 10 < 20) {
      horizontal = 'right';
    }

    if (horizontal === 'right' && right - containerWidth + 10 < 20) {
      horizontal = 'left';
    }

    setPopoverPosition({
      top: vertical === 'bottom' ? bottom + gap : top - containerHeight - gap,
      left:
        horizontal === 'center'
          ? left - (containerWidth - areaWidth) / 2
          : horizontal === 'left'
          ? left
          : right - containerWidth,
      minWidth,
      zIndex,
    });
  };

  useEffect(() => {
    if (!popoverPosition) return;

    const close = (e: Event) => {
      if (optionsRef.current?.contains(e.target as HTMLElement)) return;
      setPopoverPosition(null);
    };

    window.addEventListener('resize', reposition, { capture: true });
    window.addEventListener('scroll', close, { capture: true });

    return () => {
      window.removeEventListener('resize', reposition, { capture: true });
      window.removeEventListener('scroll', close, { capture: true });
    };
  }, [popoverPosition, optionsRef]);

  const handleClickSelect = (val: T) => {
    if (closeOnChange) {
      closeThenFocus();
    }
    onChange?.(val);
  };

  const handleMouseDown = () => {
    if (disabled) return;
    if (!popoverPosition) {
      reposition();
    }
    handleClickArea();
  };

  return (
    <>
      <div
        className={cx('wrapper', className)}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={handleMouseDown}
        ref={clickAreaRef}
      >
        {children}
      </div>
      {popoverPosition && (
        <Portal domId={'tooltip-root'}>
          <List
            variant={variant}
            color={color}
            scrollAfterNItems={scrollAfterNItems}
            customItem={customItem}
            customItemHeight={customItemHeight}
            onMount={() => {
              reposition();
              setIsListMounted(true);
              onChangeOpenState?.(true);
            }}
            onUnmount={() => {
              setIsListMounted(false);
              onChangeOpenState?.(false);
            }}
            className={cx('options', isListMounted && 'visible')}
            style={popoverPosition}
            options={options}
            value={value}
            onChange={handleClickSelect}
            ref={optionsRef}
          />
        </Portal>
      )}
    </>
  );
};

export default ListPopover;
