import React, { useState } from 'react'
import style from './IntroPage.module.scss'

import classNames from 'classnames/bind'
import NewLoginForm from '../../components/intro/NewLoginForm'
import NewSignupForm from '../../components/intro/NewSignupForm'

const cx = classNames.bind(style)

const IntroPage = () => {
  const [tab, setTab] = useState<'login' | 'signup'>('login')

  return (
    <div className={cx('container')}>
      <div className={cx('border-box', tab)}>
        <ul className={cx('tab')}>
          <li
            className={cx(tab === 'login' && 'active')}
            onClick={() => setTab('login')}
          >
            로그인
          </li>
          <li
            className={cx('signup-tab', tab === 'signup' && 'active')}
            // onClick={() => setTab('signup')}
          >
            회원가입
          </li>
        </ul>
        {tab === 'login' && <NewLoginForm />}
        {tab === 'signup' && <NewSignupForm />}
      </div>
    </div>
  )
}

export default IntroPage
