import React, { FC } from 'react'
import classNames from 'classnames/bind'

import style from './Alert.module.scss'

const cx = classNames.bind(style)

interface Props {
  outsideClick: () => void
  children: React.ReactNode
}

const Alert: FC<Props> = ({ outsideClick, children }) => {
  return (
    <>
      <div
        className={cx('alert')}
        onClick={() => outsideClick && outsideClick()}
      />
      <div className={cx('body')}>{children}</div>
    </>
  )
}

export default Alert
