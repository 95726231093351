import { useEffect, useRef, useState } from 'react'
import { fileUpload, viewFileByUid } from '../../../../repositories/common/FileRepository'
import { AttachFileVO } from '../../../../repositories/contract/vo/AttachFileVO'
import { MinuteRound } from '../../../../util/date'
import { ContractListVO } from '../../../../repositories/contract/vo/ContractListVO'
import { useForm } from 'react-hook-form'
import ContractSettlementSaveRequest from '../../../../repositories/contract/request/ContractSettlementSaveRequest'
import { getSettlementDetail, saveContractSettlement } from '../../../../repositories/contract/contractRepository'
import { AxiosError, AxiosResponse } from "axios";
import SettlementStatus from '../../../../repositories/contract/vo/SettlementStatus'
import { ContractSettlementVO } from '../../../../repositories/contract/vo/ContractSettlementVO'
import { useQuery, useQueryClient } from 'react-query'
import SettlementComposition from '../../../../repositories/contract/vo/SettlementComposition'
import ContractListCompositionListVO from '../../../../repositories/contract/vo/ContractListCompositionListVO'

interface AddPointForm {
  contractDate: string;
  APPLICANT_QUANTITY: string;
  ACCA_REQUEST_QUANTITY: string;
  ACCA_QUANTITY: string;
  PHS_REQUEST_QUANTITY: string;
}

type SettlementCompositionType = Exclude<(keyof typeof SettlementComposition), 'ATS'>;

export const COMPOSITION_TYPE_LIST: SettlementCompositionType[] = [
  'APPLICANT_QUANTITY',
  'ACCA_REQUEST_QUANTITY',
  'ACCA_QUANTITY',
  'PHS_REQUEST_QUANTITY'
]

export const COMPOSITION_LABEL: { [key in SettlementCompositionType]: string } = {
  APPLICANT_QUANTITY: '지원자수 (명)',
  ACCA_REQUEST_QUANTITY: '역검요청 (명)',
  ACCA_QUANTITY: '역검FULL열람 (명)',
  PHS_REQUEST_QUANTITY: '개발자검사 요청 (명)'
}

const useAddPointModal = (contract: ContractListVO, settlement?: ContractSettlementVO) => {
  const [attachFileList, setAttachFileList] = useState<AttachFileVO[]>([])
  const [isMounted, setIsMounted] = useState(false)
  const [contractDate] = useState<Date>(
    settlement?.settlementDate ? new Date(settlement.settlementDate) : MinuteRound()
  )
  const [supplyPrice, setSupplyPrice] = useState(settlement?.supplyPrice ?? 0)
  const [settlementStatus, setSettlementStatus] = useState<keyof typeof SettlementStatus>(settlement?.settlementStatus ?? 'UNSETTLED')
  const [isSaving, setIsSaving] = useState(false)
  const queryClient = useQueryClient()
  const [quantities, setQuantities] = useState(COMPOSITION_TYPE_LIST.reduce(
    (acc, compositionType) => Object.assign(acc, { [compositionType]: settlement?.quantityList.find(v => v.compositionType === compositionType)?.quantity ?? 0 }), {}
  ) as { [key in SettlementCompositionType]: number })

  const compositions = COMPOSITION_TYPE_LIST.reduce((acc, compositionType) =>
      Object.assign(acc, { [compositionType]: contract.compositionList.find(v => v.type === compositionType) })
    , {}) as { [key in SettlementCompositionType]: ContractListCompositionListVO }

  const setQuantity = (compositionType: keyof typeof SettlementComposition, val: number) => {
    setQuantities({ ...quantities, [compositionType]: val })
  }

  const { data } = useQuery(['settlementDetail', settlement?.contractSettlementId], async () => {
    if (settlement?.contractSettlementId) {
      const rs = await getSettlementDetail(settlement?.contractSettlementId)
      return rs.data.fileList
    }

    return []
  }, {cacheTime: 0})

  const fileRef = useRef<HTMLInputElement>(null)
  const {
    register,
    handleSubmit
  } = useForm<AddPointForm>()

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true)
      return
    }
    setSupplyPrice(fixedPrice)
  }, [quantities])

  useEffect(() => {
    if (data) {
      setAttachFileList([...data])
    }
  }, [data])

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return
    if (attachFileList.length >= 5) {
      e.target.value = ''
      return
    }

    const formData = new FormData()
    formData.append('file', file, file.name)
    const { fileUid, fileName } = await fileUpload(formData)

    const reader = new FileReader()
    try {
      const fileUrl = await new Promise<string>((res, rej) => {
        reader.readAsDataURL(file)
        reader.onload = () => {
          if (typeof reader.result === 'string')
            res(reader.result)
          else rej()
        }
      })

      setAttachFileList(val => ([...val, { fileName, fileUid, fileUrl }]))
    } finally {
      e.target.value = ''
    }
  }

  const handleClickFileLink = async (fileUid: string, fileName: string) => {
    if (!fileUid) return
    await viewFileByUid(fileUid, fileName)
  }

  const handleDeleteAttachFile = (uid: string) => {
    setAttachFileList(list => list.filter(({ fileUid }) => uid !== fileUid))
  }

  const regis = COMPOSITION_TYPE_LIST.reduce((acc, compositionType) =>
    Object.assign(acc, {
      [compositionType]: register(compositionType, {
        validate: () => quantities[compositionType] === null ? '필수 입력사항입니다.' : undefined
      })
    }), {}) as { [key in SettlementCompositionType]: ReturnType<typeof register> }

  const fixedPrice = COMPOSITION_TYPE_LIST.reduce((acc, v) => acc + quantities[v] * (compositions[v].price ?? 0), 0)

  const onSubmit = handleSubmit(async () => {
    const req: ContractSettlementSaveRequest = {
      contractNumber: contract.number,
      fileUidList: attachFileList.map(v => v.fileUid),
      quantityList: COMPOSITION_TYPE_LIST.map(compositionType => ({
        compositionType,
        quantity: quantities[compositionType]
      })),
      settlementDate: contractDate.toISOString().substring(0, 10),
      settlementStatus,
      supplyPrice,
      type: 'ADDITIONAL'
    }

    try {
      setIsSaving(true)
      await saveContractSettlement(req, settlement?.contractSettlementId)
      await queryClient.invalidateQueries('contractList')
    } catch (e) {
      throw (e as AxiosError<AxiosResponse>).response?.data.data.cause ?? '';
    } finally {
      setIsSaving(false)
    }
  })

  return {
    fileRef,
    handleFileUpload,
    attachFileList,
    handleClickFileLink,
    handleDeleteAttachFile,
    fixedPrice,
    quantities,
    setQuantity,
    supplyPrice,
    settlementStatus,
    setSettlementStatus,
    regis,
    onSubmit,
    isSaving,
    compositions,
    setSupplyPrice,
    disabled: settlement?.settlementStatus === 'SETTLEMENT_COMPLETED'
  }
}

export default useAddPointModal