import React, { FC, useContext, useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import style from './ChallengeCreateModal.module.scss'
import Modal from '../../common/modal/Modal'
import Step from './common/Step'
import Step1BasicInfo from './step1BasicInfo/Step1BasicInfo'
import BlackButton from './common/BlackButton'
import LineButton from './common/LineButton'
import { ChallengeContext } from '../../../pages/challenge/list/ChallengeContext'
import Step2SelectPosition from './step2SelectPosition/Step2SelectPosition'
import useBasicInfo from './step1BasicInfo/useBasicInfo'
import useSelectPosition from './step2SelectPosition/useSelectPosition'
import Step3ParticipationList from './step3ParticipationList/Step3ParticipationList'
import useParticipation from './step3ParticipationList/useParticipation'
import Step4Contents from './step4Contents/Step4Contents'
import useContents from './step4Contents/useContents'
import { ReactComponent as CloseIcon } from '../../../asset/icon/icon_close_32_line.svg'
import Message from '../../common/message/Message'

const cx = classNames.bind(style)

interface Props {
  matchingChallengeId?: number
  close: () => void
}

const ChallengeCreateModal: FC<Props> = ({ close, matchingChallengeId }) => {
  const { step, setStep, setMatchingChallengeId, initialize } =
    useContext(ChallengeContext)
  const { submitBasicInfo, validation } = useBasicInfo()
  const { submitSelectPosition } = useSelectPosition()
  const { submitParticipation } = useParticipation()
  const { submitContents } = useContents()
  const [showToast, setShowToast] = useState(false)

  useEffect(() => {
    if (matchingChallengeId) {
      setMatchingChallengeId(matchingChallengeId)
      setStep(1)
    }
  }, [])

  return (
    <>
      <Modal
        onClose={() => {
          initialize(true)
          close()
        }}
        contentClassName={cx('container', 'padding', 'modal-scroll')}
      >
        <>
          <div className={cx('content', 'modal-scroll')}>
            <button
              onClick={() => {
                initialize(true)
                close()
              }}
              className={cx('close')}
            >
              <CloseIcon />
            </button>
            <Step step={step} setStep={setStep} />
            <div className={cx('right', 'modal-scroll')}>
              {step === 1 && <Step1BasicInfo />}
              {step === 2 && <Step2SelectPosition />}
              {step === 3 && <Step3ParticipationList />}
              {step === 4 && <Step4Contents />}
            </div>
          </div>
          <div className={cx('modal-footer')}>
            <BlackButton
              size={'medium'}
              onClick={async () => {
                step === 2 && (await submitSelectPosition(true))
                step === 3 && (await submitParticipation(true))
                step === 4 && (await submitContents(true))
                setStep(step - 1)
              }}
              disabled={step === 1}
            >
              이전
            </BlackButton>
            <div className={cx('right')}>
              <LineButton
                onClick={async () => {
                  step === 1 && (await submitBasicInfo(true))
                  step === 2 && (await submitSelectPosition(true))
                  step === 3 && (await submitParticipation(true))
                  step === 4 && (await submitContents(true))
                  setShowToast(true)
                  setTimeout(() => setShowToast(false), 3000)
                }}
              >
                임시저장
              </LineButton>
              <BlackButton
                type={'submit'}
                size={'medium'}
                onClick={async () => {
                  step === 1 && (await submitBasicInfo(false))
                  step === 2 && (await submitSelectPosition(false))
                  step === 3 && (await submitParticipation(false))
                  if (step === 4) {
                    await submitContents(false)
                    close()
                  }
                }}
                disabled={validation()}
              >
                {step === 4 ? '저장' : '다음'}
              </BlackButton>
            </div>
          </div>
        </>
      </Modal>
      {showToast && (
        <Message>
          <span>입력한 내용이 임시저장 되었습니다.</span>
        </Message>
      )}
    </>
  )
}

export default ChallengeCreateModal
