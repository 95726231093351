import { get, post, put } from '../../util/fetch';
import { UpdatePasswordFormRequest } from './request/MemberRequest';
import { AccountDto } from '../../api/dto/AcountDto';

export const getMemberInfo = () => {
  return get<{
    body: AccountDto;
  }>('/api/v1/administrator/self');
};

// 비밀번호 만료일 연장
export const postponePasswordExpiry = () => {
  return put('/api/v1/administrator/password/expiry');
};

// 비밀번호 변경
export const updatePassword = (data: UpdatePasswordFormRequest) => {
  return put('api/v1/administrator/edit-password', data);
};

// 비밀번호 초기화
export const resetPassword = (data: { email: string }) => {
  return post('/api/v1/administrator/default-reset-password', data);
};
