import { get, post, put, remove } from '../../util/fetch';
import ProductSimpleListResponse from './response/ProductSimpleListResponse';
import ProductSimpleInfoResponse from './response/ProductSimpleInfoResponse';
import { ProductSaveRq } from './detail/ProductSaveRq';
import { ProductListResponse } from './response/ProductListResponse';
import GetContractNumberListRs from './response/GetContractNumberListRs';

// 상품 리스트 조회
export const getProductList = (page: { page: number; size: number }, search: string) => {
  return get<ProductListResponse>(`/api/products?page=${page.page}&size=${page.size}${search && `&keyword=${search}`}`);
};

// 상품 판매 여부 변경
export const putOpenType = (data: { id: number; openType: 'OPEN' | 'CLOSED' }) =>
  put<{ openType: 'OPEN' | 'CLOSED' }>(`/api/products/${data.id}/disclosure`, { openType: data.openType });

//계약 상품리스트 조회
export const getSimpleProductList = () => {
  return get<ProductSimpleListResponse>('/api/v1/products/simple');
};

//계약 상품 간단조회
export const getSimpleProductInfo = (productId: number) => {
  return get<ProductSimpleInfoResponse>(`/api/v1/products/${productId}/simple`);
};

// export const getProductDetail = (id?: string) => {
//   if (id) {
//     return get<ProductDetailResponse>(`/api/v1/products/${id}`);
//   }
//
//   const empty: ProductDetailResponse = {
//     productId: 0,
//     description: '',
//     pricePerApplicant: 0,
//     name: '',
//     compositionList: [],
//     customType: 'CUSTOM',
//     editableType: 'EDITABLE',
//     payType: 'PREPAID',
//   };
//
//   return Promise.resolve({ data: empty });
// };

export const saveProduct = (id: number, product: ProductSaveRq) => {
  if (id) {
    return put<void>(`/api/products/${id}`, product);
  }
  return post<void>('/api/products', product);
};

export const getContractNumberList = (id: number) =>
  get<GetContractNumberListRs>(`/api/products/contract-number-list?productIdList=${id}`);

export const deleteProduct = (productId: number) => remove(`/api/products`, { productId });
