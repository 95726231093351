import React from 'react'
import classNames from 'classnames/bind'
import style from './NewLoginForm.module.scss'
import useLoginForm from './useLoginForm'

const cx = classNames.bind(style)

const NewLoginForm = () => {
  const { onSubmit, emailRegister, passwordRegister } = useLoginForm()

  return (
    <form className={cx('container')} onSubmit={onSubmit}>
      <div className={cx('format')}>
        <span className={cx('label')}>ID : </span>
        <input
          {...emailRegister}
          className={cx('input')}
          placeholder={'id'}
          name={'email'}
        />
      </div>
      <div className={cx('format')}>
        <span className={cx('label')}>Password : </span>
        <input
          {...passwordRegister}
          type={'password'}
          className={cx('input')}
          placeholder={'password'}
          name={'password'}
        />
      </div>
      <button type={'submit'} className={cx('button')}>
        로그인
      </button>
    </form>
  )
}

export default NewLoginForm
