import { PopupListDto } from '../../../../api/dto/PopupListDto';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface Actions {
  setData: (data: PopupListDto[]) => void;
  invalidateData: () => void;
}

interface Data {
  list: PopupListDto[];
  needsRefresh: boolean;
}

interface IPopupListStore {
  data: Data;
  actions: Actions;
}

const initialData: Data = {
  needsRefresh: false,
  list: [],
};

export const usePopupListStore = create<IPopupListStore>()(
  immer((set) => ({
    data: initialData,
    actions: {
      setData: (data) =>
        set((state) => {
          state.data.list = data;
          state.data.needsRefresh = false;
        }),
      invalidateData: () =>
        set((state) => {
          state.data.needsRefresh = true;
        }),
    },
  }))
);
