import React from 'react'
import classNames from 'classnames/bind'
import style from './ProductListPage.module.scss'
import Layout from '../../../components/common/Layout/Layout'
import ProductList from '../../../components/product/list/ProductList'

const cx = classNames.bind(style)

const ProductListPage = () => {
  return (
    <Layout>
      <div className={cx('container')}>
        <h2 className={cx('title')}>상품목록</h2>
        <ProductList/>
      </div>
    </Layout>
  )
}

export default ProductListPage
