import ButtonCommon from '../button/ButtonCommon'
import Modal from '../modal/Modal'
import classNames from 'classnames/bind'

import style from './ModalExpiredSession.module.scss'
import useAuth from '../../../hooks/useAuth'

const cx = classNames.bind(style)

const ModalExpiredSession = () => {
  const { backToLogin } = useAuth()
  const onClickConfirm = () => {
    const redirectUrl = encodeURIComponent(location.pathname)
    backToLogin(redirectUrl)
  }

  return (
    <Modal>
      <div className={cx('modal-container')}>
        <div className={cx('modal-title')}>자동 로그아웃 안내</div>
        <div className={cx('modal-description')}>
          <p>장시간 서비스 이용이 없어 자동 로그아웃 됩니다.</p>
          <p>다시 로그인 해주세요.</p>
        </div>
        <ButtonCommon className={cx('modal-confirm')} size="small" type="black" onClick={onClickConfirm}>
          확인
        </ButtonCommon>
      </div>
    </Modal>
  )
}

export default ModalExpiredSession
