import { get } from '../../util/fetch'

export const getJobGroup = () => {
  return get<{ contents: { id: number; name: string; code: string }[] }>(
    '/api/job-group'
  )
}

export const getWorkingArea = () => {
  return get<
    {
      id: number
      code: string
      name: string
      alias: string
    }[]
  >('/api/working-area')
}
