// Created by kdw0601 on 2024-08-08
import { usePopupDetailStore } from './hooks/usePopupDetailStore';
import React from 'react';
import Radio from '../../common/radio/Radio';
import { PopupStopViewingType, popupStopViewingTypeText } from '../../../api/types/PopupStopViewingType';
import classNames from 'classnames/bind';
import styles from './PopupDetail.module.scss';
import Switch from '../../common/switch/Switch';
import { PopupOpenType } from '../../../api/types/PopupOpenType';
import { toggleValue } from '../../../util/toggleValue';

const cx = classNames.bind(styles);

const PopupDetailOpenYnSwitch = () => {
  const { set } = usePopupDetailStore((state) => state.actions);
  const openType = usePopupDetailStore((state) => state.data.openType);

  return (
    <div className={cx('stop-viewing-wrap')}>
      <Switch
        isOn={openType === PopupOpenType.OPEN}
        toggle={() => set('openType')(toggleValue(openType, PopupOpenType.OPEN, PopupOpenType.HIDDEN))}
      />
    </div>
  );
};

export default PopupDetailOpenYnSwitch;
