import React from 'react'
import { FC } from 'react'
import classNames from 'classnames/bind'

import styles from './Switch.module.scss'

const cx = classNames.bind(styles)

interface Props {
  isOn: boolean
  toggle: () => void
  disabled?: boolean
  className?: string
}

const Switch: FC<Props> = ({ isOn, toggle, disabled, className }) => {
  return (
    <section
      className={cx('switch', isOn && 'on', disabled && 'disabled', className)}
      onClick={() => {
        if (!disabled) toggle()
      }}
    >
      <span />
    </section>
  )
}

export default Switch
