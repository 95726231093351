// Created by kdw0601 on 2024-08-06
import styles from './ProductDetailV1.module.scss';
import classNames from 'classnames/bind';
import React from 'react';
import { totalPriceSelector, useProductDetailStore } from './store/useProductDetailStore';
import InputNumber from '../../common/input/InputNumber';

const cx = classNames.bind(styles);

const ProductDetailV1Pricing = () => {
  const totalPrice = useProductDetailStore(totalPriceSelector);

  return (
    <div className={cx('itemGroup')}>
      <h4 />
      <div className={cx('total')}>
        <div>계</div>
        <div style={{ flexGrow: 1 }}>
          <div className={cx('total-row')}>
            VAT 제외
            <div className={cx('value')}>
              <InputNumber
                placeholder={'금액을 입력하세요.'}
                min={0}
                value={totalPrice.toLocaleString()}
                className={cx('price')}
                disabled
              />
            </div>
          </div>
          <div className={cx('total-row')}>
            VAT 포함 (10%)
            <div className={cx('value')}>
              <InputNumber
                placeholder={'금액을 입력하세요.'}
                min={0}
                value={Math.round(totalPrice * 1.1).toLocaleString()}
                className={cx('price')}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailV1Pricing;
