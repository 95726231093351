import React, { FC, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import Header from './component/Header';
import { ko } from 'date-fns/locale';

export interface DatePickerProps {
  minDate?: Date;
  maxDate?: Date;
  customInput?: React.ReactNode;
  disabled?: boolean;
  date: Date;
  onChange(date: Date): void;
}

const DatePicker = ({ minDate, maxDate, customInput, disabled, date, onChange }: DatePickerProps) => {
  const [mode, setMode] = useState<'DAY' | 'MONTH' | 'YEAR'>('DAY');

  /**
   * @description YEAR PICKER minDate 특성상 현재년을 선택못해 -1년해줘야함
   */
  const yearMinDate = (minDate?: Date): Date | null => {
    if (!minDate) return null;
    const date = new Date();
    date.setFullYear(minDate.getFullYear() - 1);
    return date;
  };

  const yearMaxDate = (maxDate?: Date): Date | null => {
    if (!maxDate) return null;
    const date = new Date();
    date.setFullYear(maxDate.getFullYear());
    return date;
  };

  return (
    <ReactDatePicker
      selected={date}
      locale={ko}
      renderCustomHeader={(props) => <Header {...props} setMode={setMode} mode={mode} />}
      onChange={onChange}
      onCalendarClose={() => {
        setMode('DAY');
      }}
      dateFormat={'yyyy-MM-dd'}
      showMonthYearPicker={mode === 'MONTH'}
      showYearPicker={mode === 'YEAR'}
      disabled={disabled}
      minDate={mode === 'YEAR' ? yearMinDate(minDate) : minDate}
      maxDate={mode === 'YEAR' ? yearMaxDate(maxDate) : maxDate}
      customInput={customInput as any}
    />
  );
};

export default DatePicker;
