import { ComponentProps } from 'react'

export type TableColumns<Key extends string = string> = Readonly<Record<Key, ComponentProps<'td'>>>;

export const createTable = <Keys extends string>(columnData: TableColumns<Keys>) => {
  const createHeaderComponent = (cellProps: Record<Keys, ComponentProps<'th'>>) => {
    return function CustomTableHeader(headerProps: ComponentProps<'thead'>) {
      return (
        <thead {...headerProps}>
        <tr>
          {Object.entries(columnData).map(([key, props]) => (
              <td key={key} {...cellProps[key as Keys]} {...(props as ComponentProps<'th'>)} />
    ))}
      </tr>
      </thead>
    );
    };
  };

  const createRowComponent = (cellProps: Record<Keys, ComponentProps<'td'>>) => {
    return function CustomTableRow(rowProps: ComponentProps<'tr'>) {
      return (
        <tr {...rowProps}>
        {Object.entries(columnData).map(([key, props]) => (
            <td key={key} {...cellProps[key as Keys]} {...(props as ComponentProps<'td'>)} />
    ))}
      </tr>
    );
    };
  };

  return { createHeaderComponent, createRowComponent, columnCount: Object.keys(columnData).length };
};
