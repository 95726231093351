// Created by kdw0601 on 2024-07-22
import styles from './TenantDetail.module.scss';
import classNames from 'classnames/bind';
import Switch from '../../common/switch/Switch';
import React, { useState } from 'react';
import { useTenantDetailStore } from './store/useTenantDetailStore';
import { toggleValue } from '../../../util/toggleValue';
import Select from 'react-select';
import { putApproveTenant, saveCurationOptionV1 } from '../../../api/function/tenant';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../common/alert/AlertContextProvider';
import { ContractApprovalStatusTypeV2 } from '../../../types/CompanyApprovalStatusType';

const cx = classNames.bind(styles);

const automationLevelOptions = [
  { value: null, label: '선택 안 함' },
  { value: 1, label: '1 : 솔버추천' },
  { value: 2, label: '2 : 직무적합도' },
  { value: 3, label: '3 : 레벨링' },
  { value: 4, label: '4 : 활성도' },
  { value: 5, label: '5 : 인재검수' },
  { value: 6, label: '6 : 배송' },
] as const;

const approveOptions = [
  { value: null, label: '선택 안 함' },
  { value: 'APPROVAL_COMPLETE', label: '가입 승인' },
  { value: 'APPROVAL_DENIED', label: '가입 거절' },
] as const;

const TenantStatusAndCurationOption = () => {
  const { automationLevel, automationType, rating } = useTenantDetailStore((state) => state.data.curationOption);
  const initialApproveStatus = useTenantDetailStore((state) => state.data.approvedStatus);
  const [approveStatus, setApproveStatus] = useState<null | Extract<
    ContractApprovalStatusTypeV2,
    'APPROVAL_COMPLETE' | 'APPROVAL_DENIED'
  >>(null);
  const tenantId = useTenantDetailStore((state) => state.data.id);
  const { setAutomationLevel, setAutomationType, setRating } = useTenantDetailStore((state) => state.actions);
  const navigate = useNavigate();
  const alert = useAlert();

  const handleToggleAutomationType = () => {
    setAutomationType(toggleValue(automationType, 'USE', 'UNUSED'));
  };

  const handleChangeLevel = (level: number | null) => {
    setAutomationLevel(level);
  };

  const handleChangeRating = (rating: number | null) => {
    if (!rating) {
      setRating(null);
      return;
    }

    setRating(Math.min(Math.max(1, rating), 10));
  };

  const handleClickSave = async () => {
    try {
      await saveCurationOptionV1({ rating, automationType, automationLevel, tenantId });
    } catch {
      alert('큐레이션 설정 저장에 실패했습니다.');
      return;
    }

    if (approveStatus) {
      try {
        await putApproveTenant([tenantId], approveStatus);
      } catch {
        alert('가입승인 상태 저장에 실패했습니다.');
        return;
      }
    }

    alert('저장되었습니다.');
    navigate('/tenant/list');
  };

  return (
    <>
      <strong className={cx('sub')}>큐레이팅 설정</strong>
      <div className={cx('format')}>
        <label className={cx('th')}>자동화 여부</label>
        <Switch isOn={automationType === 'USE'} toggle={handleToggleAutomationType} />
      </div>
      <div className={cx('format')}>
        <label className={cx('th')}>자동화 단계</label>
        <Select
          className={cx('auto-level-select')}
          options={automationLevelOptions}
          onChange={(v) => v && handleChangeLevel(v.value)}
          value={{
            value: automationLevel,
            label: automationLevelOptions.find((v) => v.value === automationLevel)?.label,
          }}
          menuPosition={'fixed'}
        />
      </div>
      <div className={cx('format')}>
        <label className={cx('th')}>기업등급</label>
        <input
          type={'number'}
          className={cx('input')}
          value={rating ?? ''}
          placeholder={'기업 등급을 입력해주세요. (1~10)'}
          min={1}
          max={10}
          onChange={(e) => handleChangeRating(e.target.valueAsNumber)}
        />
      </div>
      {(initialApproveStatus === 'APPROVAL_WAITING' || initialApproveStatus === 'APPROVAL_DENIED') && (
        <>
          <strong className={cx('sub')}>가입승인</strong>
          <div className={cx('format')}>
            <Select
              className={cx('auto-level-select')}
              options={approveOptions}
              onChange={(v) => v && setApproveStatus(v.value)}
              value={approveOptions.find((v) => v.value === approveStatus)}
              menuPosition={'fixed'}
            />
          </div>
        </>
      )}
      <button className={cx('button')} onClick={handleClickSave}>
        저장하기
      </button>
    </>
  );
};

export default TenantStatusAndCurationOption;
