import React, { Suspense } from 'react'
import classNames from 'classnames/bind'
import style from './TenantDetailPage.module.scss'
import Spinner from '../../../components/common/spinner/Spinner'
import Layout from '../../../components/common/Layout/Layout'
import TenantDetail from '../../../components/tenant/detail/TenantDetail'

const cx = classNames.bind(style)

const TenantDetailPage = () => {
  return (
    <Layout>
      <Suspense fallback={<Spinner />}>
        <div className={cx('container')}>
          <TenantDetail />
        </div>
      </Suspense>
    </Layout>
  )
}

export default TenantDetailPage
