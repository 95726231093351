import { FC } from 'react'
import classNames from 'classnames/bind'
import * as React from 'react'
import * as ReactTinyPopover from 'react-tiny-popover'

import styles from './Popover.module.scss'

const cx = classNames.bind(styles)

interface Props {
  isOpen: boolean
  close?: (e: MouseEvent) => void
  content: JSX.Element
  children: JSX.Element
  className?: string
  align?: ReactTinyPopover.PopoverAlign
  positions?: ReactTinyPopover.PopoverPosition[]
}

const Popover: FC<Props> = ({
  isOpen,
  close,
  content,
  children,
  className,
  align,
  positions,
}) => {
  return (
    <ReactTinyPopover.Popover
      containerClassName={cx('popover', className)}
      isOpen={isOpen}
      content={content}
      positions={positions || ['bottom']}
      onClickOutside={close}
      align={align || 'start'}
      padding={5}
    >
      {children}
    </ReactTinyPopover.Popover>
  )
}

export default Popover
