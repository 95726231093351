import { useEffect } from 'react';
import { useAccountStore } from '../components/account/hooks/useAccountStore';
import { getMemberInfo } from '../repositories/member/MemberRepository';
import { getCookie } from '../util/cookie';
import { useLocation } from 'react-router-dom';

export const useFetchAccountInfo = () => {
  const setData = useAccountStore((state) => state.actions.setData);
  const location = useLocation();
  const token = getCookie('token');
  useEffect(() => {
    if (!token || location.pathname.includes('login')) {
      return;
    }

    getMemberInfo().then(({ data: { body } }) => setData(body));
  }, [token]);
};
