import React, {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  FC,
  Ref
} from 'react'
import classNames from 'classnames/bind'
import style from './Checkbox.module.scss'

const cx = classNames.bind(style)

type Props = {
  id: string
  ref?: Ref<HTMLInputElement>
  checked: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  variant?: 'default' | 'round'
  hasChild?: boolean
} & ComponentPropsWithoutRef<'input'>

const Checkbox: FC<Props> = (props) => {
  return (
    <>
      <input
        {...props}
        className={cx(
          'input',
          `variant-${props.variant || 'default'}`,
          props.checked && 'checked'
        )}
        type={'checkbox'}
        id={props.id}
        onChange={props.onChange}
      />
      <label className={cx('label', props.className)} htmlFor={props.id}>
        {props.children}
      </label>
    </>
  )
}

export default Checkbox
