// Created by kdw0601 on 2022-09-02
import styles from './ContractDetailErrorMessage.module.scss'
import classNames from 'classnames/bind'
import React from 'react';

const cx = classNames.bind(styles)

interface Props {
  field?: {message?: string};
  className?: string;
}

const ContractDetailErrorMessage = ({field, className}: Props) => {
  return (
    field?.message ? <div className={cx('error', className)}>{field.message}</div> : null
  )
}

export default ContractDetailErrorMessage