// From ATS

import {
  emailPattern,
  englishLowercasePattern,
  englishUppercasePattern,
  invalidPasswordSpecialPattern,
  invalidSpecialPattern,
  numberAndEnglishPattern,
  numberPattern,
  phonePattern,
  specialPattern,
} from './patterns'

const CONTINUOUS_KEY_BOARD =
  '`12 90-= ~!@#$%^&*()_+ /*- qwertyuiop[] asdfghjkl;\' zxcvbnm,./ QWERTYUIOP{} ASDFGHJKL:" ZXCVBNM<>?'
const CONTINUOUS_SERIAL_CHAR_LOWER = 'abcdefghijklmnopqrstuvwxyz'
const CONTINUOUS_SERIAL_CHAR_UPPER = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

const CONTINUOUS_SERIAL_NUM = '01234567890'

export const UPDATE_PASSWORD_ERROR_MESSAGE: Record<string, string> = {
  AdministratorAuthenticationException: '현재 비밀번호와 일치하지 않아요.',
  PreviousPasswordException: '이전 비밀번호를 다시 사용할 수 없어요.',
  EmptyPasswordException: '비밀번호가 입력되지 않았어요.',
  PasswordLengthException: '최소 8자리 이상의 길이로 구성하세요.',
  UnavailableCharacterException: '$, <, >, \', ", ?, / 는 사용할 수 없어요.',
  CombinationCharactersException: '영문, 숫자, 특수문자 중 3종류 이상 사용하세요.',
  RepeatedNumberException: '동일한 숫자는 3자리 이상 사용할 수 없어요.',
  RepeatedCharacterException: '동일한 문자는 3자리 이상 사용할 수 없어요.',
  StraightNumberException: '3자리 연속된 숫자는 비밀번호로 적합하지 않아요.',
  StraightCharacterException: '3자리 연속된 문자는 비밀번호로 적합하지 않아요.',
  NearKeyPositionCharacterException: '3자리 연속 키보드에 근접한 문자는 비밀번호로 적합하지 않아요.',
  IncludeForbiddenWordException: '금지된 단어가 포함되어 있어요.',
  NotMatchedRefreshPasswordException: '해당 조건으로 발급받은 토큰이 없어요.',
}

export type UpdatePasswordErrorCodeType = keyof typeof UPDATE_PASSWORD_ERROR_MESSAGE
export const updatePasswordErrorCodeSet = new Set(
  Object.keys(UPDATE_PASSWORD_ERROR_MESSAGE) as UpdatePasswordErrorCodeType[]
)

export const signupEmailValidate = (value: string) => {
  if (!emailPattern.test(value)) return '올바른 이메일 형식을 입력해 주세요.'
}

export const signupNameValidate = (value: string, maxLength = 16) => {
  if (value.length > maxLength) return `${maxLength}글자까지만 입력 가능합니다.`
  if (invalidSpecialPattern.test(value)) return UPDATE_PASSWORD_ERROR_MESSAGE['UnavailableCharacterException']
}

export const signupPasswordValidate = (value: string, email: string) => {
  let typeCount = 0

  if (numberPattern.test(value)) typeCount++
  if (englishLowercasePattern.test(value)) typeCount++
  if (englishUppercasePattern.test(value)) typeCount++
  if (specialPattern.test(value)) typeCount++

  if (invalidPasswordSpecialPattern.test(value)) {
    return UPDATE_PASSWORD_ERROR_MESSAGE['UnavailableCharacterException']
  }
  if (typeCount < 3) return UPDATE_PASSWORD_ERROR_MESSAGE['CombinationCharactersException']

  if (value.length < 8) return UPDATE_PASSWORD_ERROR_MESSAGE['PasswordLengthException']

  if (email) {
    const id = email.split('@')[0]
    if (value.includes(id)) return '이메일(ID)는 비밀번호로 사용할 수 없어요.'
  }

  for (let i = 0; i < value.length - 2; i++) {
    const t = value.substring(i, i + 3)
    const tR = t.split('').reverse().join('') // t는 이번에 검사할 3글자, tR은 역순

    if (numberPattern.test(t) && t[0] === t[1] && t[1] === t[2]) {
      return UPDATE_PASSWORD_ERROR_MESSAGE['RepeatedNumberException']
    }

    if ((englishLowercasePattern.test(t) || englishUppercasePattern.test(value)) && t[0] === t[1] && t[1] === t[2]) {
      return UPDATE_PASSWORD_ERROR_MESSAGE['RepeatedCharacterException']
    }
    if (
      CONTINUOUS_SERIAL_CHAR_LOWER.includes(t) ||
      CONTINUOUS_SERIAL_CHAR_LOWER.includes(tR) ||
      CONTINUOUS_SERIAL_CHAR_UPPER.includes(t) ||
      CONTINUOUS_SERIAL_CHAR_UPPER.includes(tR)
    ) {
      return UPDATE_PASSWORD_ERROR_MESSAGE['StraightCharacterException']
    }
    if (CONTINUOUS_SERIAL_NUM.includes(t) || CONTINUOUS_SERIAL_NUM.includes(tR)) {
      return UPDATE_PASSWORD_ERROR_MESSAGE['StraightNumberException']
    }
    if (CONTINUOUS_KEY_BOARD.includes(t) || CONTINUOUS_KEY_BOARD.includes(tR)) {
      return UPDATE_PASSWORD_ERROR_MESSAGE['NearKeyPositionCharacterException']
    }
  }
}

export const signupPhoneValidate = (value: string) => {
  if (!phonePattern.test(value)) return '올바른 전화번호가 아닙니다.'
}

export const domainValidate = (value: string) => {
  if (!numberAndEnglishPattern.test(value)) return '도메인 주소는 영문, 숫자만 입력 가능합니다.'
}
