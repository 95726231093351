/* eslint-disable no-console*/

import { getCookie, setCookie } from './cookie';
import axios from 'axios';

const DEVELOP_URL = 'https://ats-bo.ats.kr-dv-jainwon.com';
const STAGING_URL = 'https://ats-bo.ats.kr-st-jainwon.com';
const STAGING2_URL = 'https://st2-ats-bo.ats.kr-st-jainwon.com';
const PRODUCTION_URL = 'https://ats-bo.ats.kr-pr-jainwon.com';

// 전역 객체에서 다른 파일에서 export된 함수를 사용할 시 에러가 발생하여
// fetch.ts에 있는 함수를 그대로 가져왔습니다.
const getBaseUrl = (): string => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return PRODUCTION_URL;
    case 'staging':
      return STAGING_URL;
    case 'staging2':
      return STAGING2_URL;
    default:
      return DEVELOP_URL;
  }
};

type Log = [number, string];
abstract class FetcherLogger {
  static savedTime = 0;
  static pushedLog: Log[] = [];
  static pushLog(msg: string, showLog = false) {
    if (showLog) console.log(msg);
    this.pushedLog.push([new Date().getTime(), msg]);
  }
  static showLogs(reset?: boolean) {
    this.pushedLog.forEach(([time, msg]) => {
      const logTime = new Date(time);
      console.log(
        `${logTime.getHours()}:${logTime.getMinutes()}:${logTime.getSeconds()}:${logTime.getMilliseconds()} :: ${msg}`
      );
    });
    if (reset) this.pushedLog = [];
  }
}

interface IRequest {
  method: AxiosMethods;
  path: string;
  data?: unknown;
  resolve: (value: any) => void;
  reject: (reason?: any) => void;
}

type AxiosMethods = 'post' | 'get' | 'put' | 'delete';

/**
 * @class FetchController
 * 앱 내부에서 사용하는 Fetch요청을 컨트롤 하는 전역 싱글톤 클래스.
 * refresh-token요청이 발생하면 refresh-token이후로 들어오는 fetch요청들을 pending 후
 * refresh-token요청에 대한 response를 받은 후 pending된 request를 보내는 함수
 */
abstract class FetchController {
  // Pending된 request들의 정보가 담기는 배열
  private static pendingRequests: IRequest[] = [];
  // 현재 Token을 refresh중인지 판별하는 상태
  private static isRefreshingToken = false;
  // Refresh token의 중복 발생 방지.
  // Refresh token 요청이 두 번 이상 들어오면 property를 return 함
  private static refreshTokenPromise: null | Promise<any> = null;
  static readonly refreshTokenUrl = '/api/v1/administrator/token/re-issue';

  // 전역 axios 인스턴스
  static axiosInstance = axios.create({ baseURL: getBaseUrl() });

  // 로거 활성화 여부
  private static readonly activateLogger = process.env.REACT_APP_ENV === undefined;

  // Axios의 요청 메소드를 확인하여 반환하는 함수
  static getAxiosMethod(method: AxiosMethods, path: string, data?: unknown) {
    switch (method) {
      case 'get':
        return this.axiosInstance.get(path, this.getTokenAndPushToHead());
      case 'delete':
        return this.axiosInstance.delete(path, { data, ...this.getTokenAndPushToHead() });
      case 'post':
        return this.axiosInstance.post(path, data, this.getTokenAndPushToHead());
      case 'put':
        return this.axiosInstance.put(path, data, this.getTokenAndPushToHead());
      default:
        throw new Error(`Cannot find axios method of ${method}`);
    }
  }
  /**
   * 일반 fetch 대응
   */
  static sendFetchRequest<T>(method: AxiosMethods, path: string, data?: unknown): Promise<{ data: T }> {
    // 현재 refresh token의 응답을 기다리고 있을 때
    if (path === this.refreshTokenUrl) {
      return this.sendRefreshTokenRequest<T>();
    }
    if (this.activateLogger) {
      FetcherLogger.pushLog(`Got fetch ${method}:${path}`);
    }
    if (this.isRefreshingToken) {
      if (this.activateLogger) {
        FetcherLogger.pushLog(`Fetch pended ${method}:${path}`);
      }
      return new Promise((resolve, reject) => {
        // request에 대한 정보와 resolve, reject 함수를 pending
        const request: IRequest = { path, method, resolve, reject, data };
        this.pendingRequests.push(request);
      });
    } else {
      // 아니면 바로 fetch
      return this.getAxiosMethod(method, path, data);
    }
  }

  /**
   * Token Refresh 요청
   */
  static sendRefreshTokenRequest<T>(): Promise<{ data: T }> {
    // 현재 refresh token에 대한 response를 기다리고 있다면, 해당 promise를 반환
    if (this.isRefreshingToken && this.refreshTokenPromise) {
      return this.refreshTokenPromise;
    }

    this.isRefreshingToken = true;
    if (this.activateLogger) {
      FetcherLogger.pushLog(`Got refresh token, will pending other requests`);
      FetcherLogger.savedTime = new Date().getTime();
    }
    this.refreshTokenPromise = new Promise((resolve, reject) => {
      this.axiosInstance
        .post(this.refreshTokenUrl, undefined, this.getTokenAndPushToHead())
        .then((response) => {
          this.isRefreshingToken = false;
          if (response.status === 200) {
            // refresh token이 성공적일 때 쿠키 설정 후 pending된 request를 실행
            const { token, expiredDateTime } = response.data.body;
            setCookie('token', token);
            setCookie('tokenExpiredDateTime', expiredDateTime);
            if (this.activateLogger) {
              FetcherLogger.pushLog(`Refresh token completed in ${new Date().getTime() - FetcherLogger.savedTime}ms`);
              FetcherLogger.pushLog('Processing pended requests');
            }
            this.processPendingRequests();
            resolve(response);
            if (this.activateLogger) {
              console.groupCollapsed('Logs until current refreshToken');
              FetcherLogger.showLogs(true);
              console.groupEnd();
            }
          } else {
            reject('Failed to refresh token');
          }
        })
        .catch((error) => {
          reject('Failed to refresh token');
          this.isRefreshingToken = false;
          throw error;
        });
    });

    return this.refreshTokenPromise;
  }

  /**
   * Pending된 요청 일괄 처리
   */
  private static processPendingRequests() {
    if (this.activateLogger) {
      FetcherLogger.pushLog(`${this.pendingRequests.length} total pended requests`);
    }
    if (this.pendingRequests.length > 0) {
      const requests = this.pendingRequests.slice();
      this.pendingRequests = [];
      requests.forEach((request) => {
        this.getAxiosMethod(request.method, request.path, request.data)
          .then((response) => {
            request.resolve(response);
          })
          .catch((error) => {
            request.reject(error);
          });
      });
    }
  }

  /**
   * fetch가 실행될 시점의 쿠키 받아오는 함수
   * refresh token에 의해 변경된 쿠키를 request시점에 받아오기 위한 util함수
   */
  private static getTokenAndPushToHead() {
    const token = getCookie('token');
    return token ? { headers: { Authorization: `Bearer ${token}` } } : {};
  }
}

export default FetchController;
