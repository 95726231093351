import React, { FC, useContext, useState } from 'react'
import classNames from 'classnames/bind'
import style from './ChallengeDetailList.module.scss'
import { ReactComponent as SortIcon } from '../../../asset/icon/icon_sort_18_filled.svg'
import { useParams } from 'react-router-dom'
import { getPositionLInk } from '../../../repositories/challenge/list/ChallengeRepository'
import ChallengePositionFilterRequest, {
  CHALLENGE_ORDERYPE,
} from '../../../repositories/challenge/list/request/ChallengePositionFilterRequest'
import Pagination from '../../common/pagination/Pagination'
import PaginationResponse from '../../../repositories/common/PaginationResponse'
import { ChallengeContext } from '../../../pages/challenge/list/ChallengeContext'
import { RECRUITMENT_TYPE } from '../ChallengeDefinition'
import ChallengePositionFilterResponse from '../../../repositories/challenge/list/response/ChallengePositionFilterResponse'
import Checkbox from '../create/common/Checkbox'
import { useQuery } from 'react-query'
import {
  getJobGroup,
  getWorkingArea,
} from '../../../repositories/common/CommonRepository'

const cx = classNames.bind(style)

interface Props {
  list: ChallengePositionFilterResponse[]
  page: PaginationResponse
  setPage: (page: PaginationResponse) => void
  filter: ChallengePositionFilterRequest & CHALLENGE_ORDERYPE
  setFilter: (
    filter: ChallengePositionFilterRequest & CHALLENGE_ORDERYPE
  ) => void
  check: Set<number>
  setCheck: (v: Set<number>) => void
  isAll: boolean
  setIsAll: (v: boolean) => void
  ids: number[]
}

const ChallengeDetailList: FC<Props> = ({
  list,
  page,
  setPage,
  filter,
  setFilter,
  check,
  setCheck,
  isAll,
  setIsAll,
  ids,
}) => {
  //근무지역
  const { data: WORKING_AREA } = useQuery(['workingArea'], getWorkingArea)

  //직군
  const { data: JOB_GROUP_INFO } = useQuery(['jobgroup'], getJobGroup)

  return (
    <div className={cx('container')}>
      {list.length ? (
        <table className={cx('table')}>
          <thead className={cx('header')}>
            <tr>
              {(
                [
                  {
                    name: (
                      <Checkbox
                        id={'all'}
                        checked={isAll}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setIsAll(true)
                            setCheck(new Set([...ids]))
                          } else {
                            setIsAll(false)
                            setCheck(new Set())
                          }
                        }}
                      />
                    ),
                    value: '',
                  },
                  { name: '참여 기업명', value: '' },
                  { name: '참여 매칭 포지션', value: '' },
                  { name: '직군', value: '' },
                  { name: '근무형태', value: '' },
                  { name: '근무지역', value: '' },
                  { name: '채용인원', value: '' },
                  { name: '매칭지원자', value: '' },
                  { name: '공고지원자', value: '' },
                  { name: '검증진행중', value: '' },
                  { name: '면접제안', value: '' },
                  { name: '최종합격', value: '' },
                ] as { name: string; value: any }[]
              ).map(({ name, value }) => (
                <th key={value + name}>
                  {value ? (
                    <button
                      className={cx('sort-button')}
                      onClick={() => {
                        const { orderType, direction } = filter

                        setFilter({
                          orderType: value,
                          direction:
                            orderType === value
                              ? direction === 'ASC'
                                ? 'DESC'
                                : 'ASC'
                              : 'ASC',
                        })
                      }}
                    >
                      {name}
                      <SortIcon />
                    </button>
                  ) : (
                    name
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {list.map(
              ({
                revealPositionId,
                positionName,
                companyName,
                workingAreaCode,
                recruitmentClassification,
                jobdaMatching,
                needApplicantCount,
                normalApplicant,
                verificationApplicant,
                interviewProposal,
                finalAcceptance,
                jobGroupCode,
                publicKey,
                positionId,
              }) => (
                <tr key={revealPositionId} className={cx('row')}>
                  <td>
                    <Checkbox
                      id={`check=${revealPositionId}`}
                      checked={check.has(revealPositionId) || isAll}
                      onChange={(e) => {
                        if (e.target.checked) {
                          check.add(revealPositionId)
                        } else {
                          check.delete(revealPositionId)
                        }
                        setCheck(new Set(check))
                      }}
                    />
                  </td>
                  <td>{companyName}</td>
                  <td
                    className={cx('positionName')}
                    onClick={async () => {
                      const { data } = await getPositionLInk(
                        publicKey,
                        positionId
                      )
                      window.open(data?.url)
                    }}
                  >
                    {positionName}
                  </td>
                  <td>
                    {JOB_GROUP_INFO?.data.contents.filter(
                      ({ code }) => jobGroupCode === Number(code)
                    )[0]?.name ?? ''}
                  </td>
                  <td>{RECRUITMENT_TYPE[recruitmentClassification]}</td>
                  <td>
                    {WORKING_AREA?.data.filter(
                      ({ code }) => Number(code) === workingAreaCode
                    )[0]?.name ?? ''}
                  </td>
                  <td>{needApplicantCount}</td>
                  <td>{jobdaMatching}</td>
                  <td>{normalApplicant}</td>
                  <td>{verificationApplicant}</td>
                  <td>{interviewProposal}</td>
                  <td>{finalAcceptance}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      ) : (
        <div className={cx('empty')}>
          <i className={cx('img')} />
          <strong className={cx('title')}>선택된 포지션이 없어요</strong>
          <p className={cx('desc')}>
            포지션을 등록하고 매칭챌린지를 시작해보세요
          </p>
        </div>
      )}
      {!!list.length && (
        <Pagination
          page={page}
          pageHandler={async (page) => {
            setPage(page)
          }}
        />
      )}
    </div>
  )
}

export default ChallengeDetailList
