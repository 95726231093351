import React from 'react'
import classNames from 'classnames/bind'
import style from './Empty.module.scss'

const cx = classNames.bind(style)

interface Props {
  message?: string;
}

const Empty = ({message}: Props) => {
  return (
    <div className={cx('container')}>
      <div className={cx('content')}>
        <div className={cx('img')} />
        <span>{message ?? '기업이 존재하지 않습니다.'}</span>
      </div>
    </div>
  )
}

export default Empty
