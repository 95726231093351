// Created by kdw0601 on 2022-10-17
import styles from './UsageList.module.scss';
import classNames from 'classnames/bind';
import React, { Fragment } from 'react';
import useUsageList from './useUsageList';
import InputText from '../../common/input/InputText';
import Table from '../../common/table/Table';
import { ContractStatus, ContractStatusType } from '../../../repositories/contract/vo/ContractStatus';
import Pagination from '../../common/pagination/Pagination';
import { ReactComponent as DownIcon } from '../../../asset/icon/icon_arrow_bottom_24_line.svg';
import MultiSelectFilter from '../../common/selectFilter/MultiSelectFilter';
import Empty from '../../common/empty/Empty';
import SettlementComposition from '../../../repositories/contract/vo/SettlementComposition';
import { COMPOSITION_TYPE_LIST } from '../../contract/list/AddPointModal/useAddPointModal';
import ProductType from '../../../repositories/usage/vo/ProductType';

const cx = classNames.bind(styles);

const CONTRACT_STATUS_FILTER = {
  COMPLETE: ContractStatus.COMPLETE,
  TERMINATION: ContractStatus.TERMINATION,
};

type SettlementCompositionType = Exclude<keyof typeof SettlementComposition, 'ATS'>;

const COMPOSITION_LABEL: { [key in SettlementCompositionType]: string } = {
  APPLICANT_QUANTITY: '지원자',
  ACCA_REQUEST_QUANTITY: '역검 요청수',
  ACCA_QUANTITY: '역검FULL 열람수',
  PHS_REQUEST_QUANTITY: '개발자검사 요청수',
};

const UsageList = () => {
  const {
    data,
    search,
    setSearch,
    setPagination,
    contractTypeFilter,
    setContractTypeFilter,
    setContractStatusFilter,
    contractStatusFilter,
  } = useUsageList();

  return (
    <>
      <div className={cx('toolbar')}>
        <div className={cx('search')}>
          <InputText
            placeholder={'계약번호, 기업명, 사업자 등록번호를 입력해주세요.'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {/*<ButtonCommon type={'gray-line'}>엑셀 다운로드</ButtonCommon>*/}
        </div>
      </div>
      <div className={cx('table-wrap')}>
        <Table className={cx('table')}>
          <thead>
            <tr className={cx('column-group')}>
              <td colSpan={2}>기업</td>
              <td colSpan={5}>계약</td>
              {COMPOSITION_TYPE_LIST.map((v) => (
                <td key={v} colSpan={3} className={cx('composition')}>
                  {COMPOSITION_LABEL[v]}
                </td>
              ))}
            </tr>
            <tr>
              <td>기업명</td>
              <td>사업자등록번호</td>
              <td>
                <MultiSelectFilter
                  className={cx('filter')}
                  value={contractTypeFilter}
                  onChange={(v) => setContractTypeFilter(v as (keyof typeof ProductType)[])}
                  options={Object.entries(ProductType).map(([value, label]) => ({ label, value }))}
                >
                  계약상품
                  <DownIcon />
                </MultiSelectFilter>
              </td>
              <td>계약번호</td>
              <td>
                <MultiSelectFilter
                  className={cx('filter')}
                  value={contractStatusFilter}
                  onChange={(v) => setContractStatusFilter(v as ContractStatusType[])}
                  options={Object.entries(CONTRACT_STATUS_FILTER).map(([value, label]) => ({ label, value }))}
                >
                  상태
                  <DownIcon />
                </MultiSelectFilter>
              </td>
              <td>시작일</td>
              <td>만료일</td>
              {COMPOSITION_TYPE_LIST.map((v) => (
                <Fragment key={v}>
                  <td>계약수량</td>
                  <td>사용량</td>
                  <td>잔여량</td>
                </Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.list.map((v) => (
              <tr key={v.contractNumber}>
                <td>{v.companyName}</td>
                <td>{v.businessNumber}</td>
                <td>{ProductType[v.productType]}</td>
                <td>{v.contractNumber}</td>
                <td>{ContractStatus[v.contractStatus]}</td>
                <td>{v.startDate}</td>
                <td>{v.endDate}</td>
                {COMPOSITION_TYPE_LIST.map(
                  (type, i, arr, comp = v.compositionList.find((v) => v.compositionType === type)) => (
                    <Fragment key={type}>
                      <td>{comp?.limitType === 'UNLIMITED' ? '무제한' : comp?.contractCount}</td>
                      <td>{comp?.usageCount}</td>
                      <td>{comp?.limitType === 'UNLIMITED' ? '무제한' : comp?.remainingCount}</td>
                    </Fragment>
                  )
                )}
              </tr>
            ))}
            {!data?.list.length && (
              <tr>
                <td colSpan={7 + COMPOSITION_TYPE_LIST.length * 3}>
                  <Empty message={'계약이 존재하지 않습니다.'} />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {data?.pagination && (
        <Pagination
          page={{
            size: data.pagination.size,
            page: data.pagination.page + 1,
            totalPageCount: data.pagination.totalPages,
            totalItemCount: data.pagination.totalCount,
          }}
          pageHandler={setPagination}
        />
      )}
    </>
  );
};

export default UsageList;
