import { CompanyInfoDetailResponse } from '../../../../repositories/company/detail/CompanyInfoDetailResponse';
import { UseType } from '../../../../api/types/UseType';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface Actions {
  setData: (data: CompanyInfoDetailResponse) => void;
  setAutomationType: (useType: UseType) => void;
  setAutomationLevel: (val: number | null) => void;
  setRating: (val: number | null) => void;
}

const defaultData: CompanyInfoDetailResponse = {
  id: 0,
  approvedStatus: 'TENANT_BEFORE_CREATING',
  reason: '',
  accountStatus: 'STOP',
  email: '',
  managerName: '',
  phoneNumber: '',
  companyName: '',
  businessNumber: '',
  ceoName: '',
  lastLoginDateTime: '',
  joinDateTime: '',
  contractFileUid: '',
  businessLicenseFileUid: '',
  agreementList: [],
  curationOption: {
    automationType: UseType.UNUSED,
    automationLevel: null,
    rating: null,
  },
};

interface ITenantDetailStore {
  data: CompanyInfoDetailResponse;
  actions: Actions;
}

export const useTenantDetailStore = create<ITenantDetailStore>()(
  immer((set) => ({
    data: defaultData,
    actions: {
      setAutomationLevel: (level) =>
        set(({ data: { curationOption } }) => {
          curationOption.automationLevel = level;
        }),
      setAutomationType: (type) =>
        set(({ data: { curationOption } }) => {
          curationOption.automationType = type;
        }),
      setRating: (rating) =>
        set(({ data: { curationOption } }) => {
          curationOption.rating = rating;
        }),
      setData: (data) =>
        set((state) => {
          state.data = data;
        }),
    },
  }))
);
