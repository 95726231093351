import { get } from '../../util/fetch';
import UsageListResponse from './response/UsageListResponse';
import { toSearchParams } from '../../util/searchBuilder';
import ProductType from './vo/ProductType';
import { ContractStatusType } from '../contract/vo/ContractStatus';

export const getUsageList = (params: {
  page: number;
  size: number;
  keyword: string;
  sort: string[];
  contractTypeFilter: (keyof typeof ProductType)[];
  contractStatusFilter: ContractStatusType[];
}) => {
  const { contractStatusFilter, contractTypeFilter, keyword, size, page } = params;
  const paramString = toSearchParams({
    page,
    size,
    keyword,
    sort: 'createdDateTime:desc',
    'contract-type': contractTypeFilter.length === Object.keys(ProductType).length ? '' : contractTypeFilter.join(','),
    status: contractStatusFilter.length % 2 ? contractStatusFilter.join(',') : 'COMPLETE,TERMINATION',
  });

  return get<UsageListResponse>(`/api/v2/usage${paramString}`);
};
