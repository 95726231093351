import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import style from './PositionList.module.scss'
import dayjs from 'dayjs'
import ListPopover from '../../../common/popover/ListPopover'
import { ChallengeContext } from '../../../../pages/challenge/list/ChallengeContext'
import {
  getJDURL,
  getSelectPosition,
  modifyPositionName,
  removeChallengePosition,
} from '../../../../repositories/challenge/detail/CreateChallengeRepository'
import { useQuery } from 'react-query'
import { ReactComponent as ModifyIcon } from '../../../../asset/icon/icon_edit_24_line.svg'

const cx = classNames.bind(style)

const PositionList = () => {
  const {
    matchingChallengeId,
    BASIC_INFO: {
      periodOfAcceptanceEndDateTime,
      periodOfAcceptanceStartDateTime,
    },
    SELECT_POSITION: { revealPositions, setRevealPositions },
  } = useContext(ChallengeContext)
  const [companyCount, setCompanyCount] = useState(0)
  const [showIdx, setShowIdx] = useState<null | number>(null)
  const [name, setName] = useState('')

  //포지션 불러오기
  useQuery(
    'position-list',
    async () => await getSelectPosition(matchingChallengeId!),

    {
      enabled: !!matchingChallengeId,
      onSuccess: async (data) => {
        data?.data?.length &&
          setRevealPositions(
            data?.data?.map((rs) => ({ ...rs, modify: false }))
          )
      },
    }
  )

  useEffect(() => {
    setCompanyCount(
      new Set([...revealPositions.map(({ companyName }) => companyName)]).size
    )
  }, [revealPositions])

  return (
    <div className={cx('container', 'modal-scroll')}>
      {revealPositions.length ? (
        <>
          <table className={cx('table')}>
            <thead className={cx('header')}>
              <tr>
                <td>기업명({companyCount})</td>
                <td>포지션명({revealPositions.length})</td>
                <td>
                  채용인원(
                  {revealPositions.reduce(
                    (acc, cur) => acc + cur.needApplicantCount,
                    0
                  )}
                  )
                </td>
                <td>포지션 시작일</td>
                <td>포지션 마감일</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {revealPositions.map(
                ({
                  revealPositionId,
                  positionName,
                  closingDateTime,
                  needApplicantCount,
                  companyName,
                  openingDateTime,
                  modify,
                  publicKey,
                  positionId,
                }) => (
                  <tr
                    key={revealPositionId}
                    className={cx('row')}
                    onClick={async () => {
                      const { data } = await getJDURL({ publicKey, positionId })
                      window.open(data.url)
                    }}
                  >
                    <td>{companyName}</td>
                    <td className={cx('positionName')}>
                      {modify ? (
                        <input
                          className={cx('table-input')}
                          maxLength={40}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setName(e.target.value)
                          }}
                          value={name}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' && name.length) {
                              setRevealPositions(
                                revealPositions.map((position) => ({
                                  ...position,
                                  positionName:
                                    position.revealPositionId ===
                                    revealPositionId
                                      ? name
                                      : position.positionName,
                                  modify: false,
                                }))
                              )
                              modifyPositionName({
                                name,
                                revealPositionId: revealPositionId,
                              })
                            }
                          }}
                        />
                      ) : (
                        <div className={cx('name-group')}>
                          <span className={cx('name')}>{positionName} </span>
                          <button
                            onClick={(e) => {
                              e.stopPropagation()
                              setRevealPositions(
                                revealPositions.map((position) => ({
                                  ...position,
                                  modify:
                                    position.revealPositionId ===
                                    revealPositionId,
                                }))
                              )
                              setName(positionName)
                              setShowIdx(null)
                            }}
                            className={cx('edit')}
                          >
                            <ModifyIcon fill={''} width={18} height={18} />
                          </button>
                        </div>
                      )}
                    </td>
                    <td>{needApplicantCount}명</td>
                    <td className={cx('closingTime')}>
                      {(openingDateTime
                        ? dayjs(openingDateTime).format('YYYY.MM.DD A h:mm')
                        : '-'
                      )
                        .replace('AM', '오전')
                        .replace('PM', '오후')}
                    </td>
                    <td
                      className={cx(
                        'closingTime',
                        dayjs(
                          dayjs(periodOfAcceptanceEndDateTime).format(
                            'YYYY.MM.DD h:mm'
                          )
                        ) !==
                          dayjs(
                            dayjs(closingDateTime).format('YYYY.MM.DD h:mm')
                          ) && 'diff'
                      )}
                    >
                      {(closingDateTime
                        ? dayjs(closingDateTime).format('YYYY.MM.DD A h:mm')
                        : '상시채용'
                      )
                        .replace('AM', '오전')
                        .replace('PM', '오후')}
                    </td>
                    <td>
                      <ListPopover
                        close={() => setShowIdx(null)}
                        isOpen={showIdx === revealPositionId}
                        content={
                          <ul>
                            <li
                              onClick={async (e) => {
                                e.stopPropagation()
                                setRevealPositions(
                                  revealPositions.filter(
                                    (position) =>
                                      position.revealPositionId !==
                                      revealPositionId
                                  )
                                )
                                await removeChallengePosition({
                                  matchingChallengeId: matchingChallengeId!,
                                  revealPositionId,
                                })

                                setShowIdx(null)
                              }}
                            >
                              포지션 제외
                            </li>
                          </ul>
                        }
                      >
                        <button
                          className={cx('more')}
                          onClick={(e) => {
                            e.stopPropagation()
                            setShowIdx((v: number | null) =>
                              v === revealPositionId ? null : revealPositionId
                            )
                          }}
                        />
                      </ListPopover>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          <div className={cx('info')}>
            <div className={cx('content')}>
              <p className={cx('paragraph')}>
                챌린지 진행 기간 :{' '}
                {dayjs(periodOfAcceptanceStartDateTime)
                  .format('YYYY.MM.DD A h:mm')
                  .replace('AM', '오전')
                  .replace('PM', '오후')
                  .replace('오전 0:00', '오전 12:00')
                  .replace('오후 0:00', '오후 12:00')}{' '}
                ~{' '}
                {dayjs(periodOfAcceptanceEndDateTime)
                  .format('YYYY.MM.DD A h:mm')
                  .replace('AM', '오전')
                  .replace('PM', '오후')
                  .replace('오전 0:00', '오전 12:00')
                  .replace('오후 0:00', '오후 12:00')}
              </p>
              <p className={cx('paragraph', 'red')}>
                챌린지가 최종 생성되면 모든 포지션 마감기한은 챌린지
                마감기한으로 통일됩니다.
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className={cx('empty')}>
          <i className={cx('img')} />
          <strong className={cx('title')}>선택된 포지션이 없어요</strong>
          <p className={cx('desc')}>
            포지션을 등록하고 매칭챌린지를 시작해보세요
          </p>
        </div>
      )}
    </div>
  )
}

export default PositionList
