// Created by kdw0601 on 2024-08-08
import styles from './PopupDetail.module.scss';
import classNames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import { usePopupDetailStore } from './hooks/usePopupDetailStore';
import React, { useEffect, useState } from 'react';
import { getPopupDetail } from '../../../api/function/popup';
import Layout from '../../common/Layout/Layout';
import InputText from '../../common/input/InputText';
import PopupDetailDateRange from './PopupDetailDateRange';
import PopupDetailImageUpload from './PopupDetailImageUpload';
import PopupDetailImageLink from './PopupDetailImageLink';
import PopupDetailStopViewing from './PopupDetailStopViewing';
import PopupDetailOpenYnSwitch from './PopupDetailOpenYnSwitch';
import PopupDetailSaveButton from './PopupDetailSaveButton';

const cx = classNames.bind(styles);

const PopupDetail = () => {
  const { id } = useParams();
  const { setData, set } = usePopupDetailStore((state) => state.actions);
  const title = usePopupDetailStore((state) => state.data.title);
  const errors = usePopupDetailStore((state) => state.errors);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!id) {
      setIsLoaded(true);
    }

    if (id) {
      getPopupDetail(Number(id))
        .then((res) => setData(res.data))
        .then(() => setIsLoaded(true));
    }

    return () => {
      usePopupDetailStore.setState(usePopupDetailStore.getInitialState());
      setIsLoaded(false);
    };
  }, [id]);

  if (!isLoaded) {
    return null;
  }

  return (
    <Layout>
      <div className={cx('container')}>
        <h2 className={cx('title')}>팝업 {id ? '수정' : '등록'}</h2>
        <div className={cx('itemGroup')}>
          <h4>팝업 내용</h4>
          <div className={cx('item')}>
            <div className={cx('label')}>
              제목<strong>*</strong>
            </div>
            <div className={cx('value')}>
              <InputText
                defaultValue={title}
                maxLength={50}
                onChange={(e) => set('title')(e.currentTarget.value)}
                error={errors.title}
              />
            </div>
          </div>
          <div className={cx('item')}>
            <div className={cx('label')}>게시 기간</div>
            <div className={cx('value', 'flex')}>
              <PopupDetailDateRange />
            </div>
          </div>
          <div className={cx('item')}>
            <div className={cx('label')}>이미지 업로드</div>
            <div className={cx('value', 'image')}>
              <PopupDetailImageUpload />
              {errors.fileUid && <div className={cx('error-message')}>{errors.fileUid}</div>}
            </div>
          </div>
          <div className={cx('item')}>
            <div className={cx('label')}>연결 링크</div>
            <div className={cx('value')}>
              <PopupDetailImageLink />
            </div>
          </div>
          <div className={cx('item')}>
            <div className={cx('label')}>그만보기</div>
            <div className={cx('value')}>
              <PopupDetailStopViewing />
            </div>
          </div>
          <div className={cx('item')}>
            <div className={cx('label')}>게시 여부</div>
            <div className={cx('value')}>
              <PopupDetailOpenYnSwitch />
            </div>
          </div>
        </div>
        <div className={cx('footer')}>
          <PopupDetailSaveButton id={id ? Number(id) : undefined} />
        </div>
      </div>
    </Layout>
  );
};

export default PopupDetail;
