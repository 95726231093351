import { get, post, put, remove } from '../../util/fetch';
import ContractListResponse from './response/ContractListResponse';
import ContractDetailResponse from './response/ContractDetailResponse';
import { ContractSaveRequest } from './request/ContractSaveRequest';
import ContractSettlementSaveRequest from './request/ContractSettlementSaveRequest';
import { ContractStatusType } from './vo/ContractStatus';
import CompanyInfoVO from './vo/CompanyInfoVO';
import { ContractSettlementDetailVO } from './vo/ContractSettlementDetailVO';
import { toSearchParams } from '../../util/searchBuilder';
import ContractSettlementListRequest from './request/ContractSettlementListRequest';
import ContractSettlementListResponse from './response/ContractSettlementListResponse';
import SettlementStatus from './vo/SettlementStatus';
import ContractMatchingSaveRequest from './request/ContractMatchingSaveRequest';

//계약리스트 조회
export const getContractList = (
  tab: ContractStatusType | '',
  { page, pageSize }: { page: number; pageSize: number },
  search: string,
  productType?: string
) => {
  const searchParams = toSearchParams({
    page,
    size: pageSize,
    'contract-status': tab,
    keyword: search,
    sort: 'number:desc',
    'product-type': productType,
  });

  return get<ContractListResponse>(`/api/contract/v1${searchParams}`);
};

//계약상세 조회
export const getContractDetail = (contractId?: string) => {
  if (contractId) {
    return get<ContractDetailResponse>(`/api/contracts/${contractId}`);
  }

  const empty: ContractDetailResponse = {
    contractId: 0,
    product: { productId: 0, name: '', payType: 'POSTPAID', compositionList: [] },
    number: '',
    contractCompany: { companyName: '', name: '', email: '', phone: '', businessNumber: '', publicKey: '' },
    contractDate: '',
    supplyPrice: 0,
    endDate: '',
    status: 'WAIT',
    memo: '',
    fileList: [],
    startDate: '',
  };

  return Promise.resolve({ data: empty });
};

export const saveContractDetail = (data: ContractSaveRequest, id?: number) => {
  if (id) {
    return put(`/api/contracts/${id}`, data);
  }
  return post('/api/contracts', data);
};

//사업자등록번호 조회
export const getCompanyInfo = (businessNumber: string) => {
  return get<CompanyInfoVO>(`/api/companies/${businessNumber}`);
};

export const deleteContracts = (deleteIdList: number[]) => {
  return remove('/api/contracts', { deleteIdList });
};

export const getSettlementDetail = (id: number) => {
  return get<ContractSettlementDetailVO>(`/api/contract-settlements/${id}`);
};

export const saveContractSettlement = (data: ContractSettlementSaveRequest, id?: number) => {
  if (id) {
    return put(`/api/contract-settlements/${id}`, data);
  }
  return post('/api/contract-settlements', data);
};

export const saveContractMatching = (data: ContractMatchingSaveRequest) => {
  return post('/api/contract-settlements/matching', data);
};

export const deleteContractSettlement = (id: number) => {
  return remove('/api/contract-settlements', { deleteIdList: [id] });
};

export const getContractSettlementList = (data: ContractSettlementListRequest) => {
  return post<ContractSettlementListResponse>('/api/contract-settlements/list', data);
};

export const changeContractSettlementState = (id: number, state: keyof typeof SettlementStatus) => {
  return put(`/api/contract-settlements/${id}/${state}`);
};

export const extendContract = (contractId: number | string, endDate: string) => {
  return put(`/api/v1/contracts/${contractId}/extension`, { endDate });
};
