import React, { FC } from 'react';
import classNames from 'classnames/bind';
import Select, { ActionMeta, MenuPlacement, SingleValue } from 'react-select';
import { ReactComponent as DownIcon } from '../../../asset/icon/icon_arrow_bottom_24_line.svg';

import styles from './NewSelect.module.scss';

const cx = classNames.bind(styles);

export type SelectOption = {
  value: string;
  label: string;
  [key: string]: string | number;
};

type Props = {
  size: 'small' | 'large' | 'tiny';
  options: SelectOption[];
  onChange?: (newValue: SingleValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => void;
  value?: SingleValue<SelectOption>;
  name?: string;
  className?: string;
  placeholder?: string;
  loadingMessage?: string;
  noOptionsMessage?: string;
  defaultValue?: SingleValue<SelectOption>;
  disabled?: boolean;
  wide?: boolean;
  menuPlacement?: MenuPlacement;
};

//TODO: 스타일 적용 방법을 바꿔야 할 것 같음
const NewSelect: FC<Props> = ({
  size,
  options,
  onChange,
  value,
  className,
  placeholder,
  loadingMessage,
  noOptionsMessage,
  disabled,
  wide,
  defaultValue,
  name = '',
  menuPlacement = 'auto',
}) => {
  const iconSize = (v: string) => {
    switch (v) {
      case 'tiny':
        return 18;
      case 'small':
        return 18;
      case 'large':
        return 32;
    }
  };

  return (
    <Select
      menuPlacement={menuPlacement}
      isDisabled={disabled}
      isSearchable={false}
      className={className}
      options={options}
      onChange={onChange}
      value={value}
      loadingMessage={() => loadingMessage || '로딩중입니다...'}
      noOptionsMessage={() => noOptionsMessage || '옵션이 없습니다.'}
      placeholder={placeholder || '선택'}
      defaultValue={defaultValue}
      name={name}
      components={{
        DropdownIndicator: function DropdownIndicator(props) {
          return (
            <div
              className={cx(
                'indicator',
                size === 'small' && 'small',
                size === 'large' && 'large',
                size === 'tiny' && 'tiny',
                props.isDisabled && 'disabled',
                props.isFocused && 'focused'
              )}
            >
              <DownIcon fill={'#6A7081'} />
            </div>
          );
        },
        LoadingIndicator: undefined,
        IndicatorSeparator: null,
      }}
      styles={{
        container: (provided) => ({
          ...provided,
          width: wide ? '100%' : 'auto',
          height: size === 'tiny' ? '32px' : '',
        }),
        control: (provided, state) => ({
          ...provided,
          boxShadow: 'none',
          background: 'white',
          borderRadius: '4px',
          transition: 'all .4s',
          minHeight: 'auto',
          height: size === 'tiny' ? '32px' : '',
          border: '1px solid #DEE2E6',
          '&:hover': {
            background: state.isFocused ? 'white' : '#e9ecef',
          },
          position: 'relative',
          zIndex: 500,
          cursor: 'pointer',
        }),
        placeholder: (provided, state) => ({
          ...provided,
          color: state.isDisabled ? '#ced4da' : '#adb5bd',
          fontSize: size === 'small' || size === 'tiny' ? '14px' : '16px',
          height: size === 'tiny' ? '32px' : '',
          lineHeight: size === 'tiny' ? '30px' : '',
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: size === 'tiny' ? '0 8px' : '',
          lineHeight: size === 'tiny' ? '32px' : '',
          paddingLeft: size === 'small' ? '10px' : '14px',
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: state.isDisabled ? '#ced4da' : '#25282B',
          fontSize: size === 'small' || size === 'tiny' ? '14px' : '16px',
          height: size === 'tiny' ? '32px' : '',
          lineHeight: size === 'tiny' ? '30px' : '',
          margin: '0 2px 0 0',
          paddingRight: '3px',
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: '5px',
          zIndex: 500,
        }),
        menuList: (provided) => ({
          ...provided,
          padding: '10px 5px',
        }),
        option: (provided, state) => ({
          ...provided,
          borderRadius: '4px',
          background: state.isFocused ? '#f1f3f5' : 'transparent',
          padding: '6px 10px',
          fontSize: size === 'small' || size === 'tiny' ? '14px' : '16px',
          color: state.isSelected ? '#12b886' : state.isFocused ? '#25282B' : '#495057',
          zIndex: 500,
        }),
      }}
    />
  );
};

export default NewSelect;
