import { get, getFiledownloadXlsx, put } from '../../../util/fetch';
import { CompanyInfoDetailResponse } from './CompanyInfoDetailResponse';
import { ContractApprovalStatusType } from '../../../types/CompanyApprovalStatusType';

export const companyInfoDetail = (id: string) => {
  return get<CompanyInfoDetailResponse>(`/api/v1/tenant/${id}`);
};

export const companyInfoDetailSave = (
  id: string,
  request: {
    managerName: string;
    phoneNumber: string;
    ceoName: string;
    businessNumber: string;
    companyName: string;
    tenantApprovalStatus: ContractApprovalStatusType;
  }
) => {
  return put(`/api/v1/tenant/${id}`, request);
};

export const getAcceptMemberDownload = (id: number) => {
  return getFiledownloadXlsx(`api/v1/tenant/invite/${id}`, '가입승인목록');
};
