import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import style from './Layout.module.scss';
import { Link, useLocation } from 'react-router-dom';
import Header from './Header';
import { useAuthorizeRender } from '../../../feature/authority/utils/withAuthority';

const cx = classNames.bind(style);

interface Props {
  children: React.ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const [depth1, setDepth1] = useState<
    'tenant' | 'matching' | 'product' | 'contract' | '' | 'statistics' | 'challenge' | 'popup' | 'settings'
  >('tenant');
  const authorizeRender = useAuthorizeRender();

  useEffect(() => {
    setDepth1((pathname.split('/')[1] ?? '') as typeof depth1);
  }, [pathname]);

  return (
    <div className={cx('container')}>
      {/*<Suspense fallback={<div></div>}>*/}
      {/*  <ErrorBoundary*/}
      {/*    fallback={<div></div>}*/}
      {/*    didCatch={() => {*/}
      {/*      deleteCookie('token')*/}
      {/*      navigate('/login')*/}
      {/*    }}*/}
      {/*  >*/}
      <Header />
      {/*  </ErrorBoundary>*/}
      {/*</Suspense>*/}
      <div className={cx('align')}>
        <div className={cx('sidebar')}>
          <div className={cx('logo')} />
          <ul className={cx('depth1')}>
            <li
              className={cx(depth1 === 'tenant' && 'active')}
              onClick={() => {
                setDepth1('tenant');
              }}
            >
              <a>기업 관리</a>
              <ul className={cx('depth2')}>
                <li className={cx(pathname.startsWith('/tenant/list') && 'active')}>
                  <Link to={'/tenant/list'}>기업계정 목록</Link>
                </li>
              </ul>
            </li>
            {authorizeRender(
              'matchingChallenge',
              <li
                className={cx(depth1 === 'challenge' && 'active')}
                onClick={() => {
                  setDepth1('challenge');
                }}
              >
                <a>매칭 챌린지 관리</a>
                <ul className={cx('depth2')}>
                  <li className={cx(pathname.startsWith('/challenge/list') && 'active')}>
                    <Link to={'/challenge/list'}>매칭 챌린지 목록</Link>
                  </li>
                </ul>
              </li>
            )}
            <li
              className={cx(depth1 === 'product' && 'active')}
              onClick={() => {
                setDepth1('product');
              }}
            >
              <a>상품 관리</a>
              <ul className={cx('depth2')}>
                <li className={cx(pathname.startsWith('/product/list') && 'active')}>
                  <Link to={'/product/list'}>상품 목록</Link>
                </li>
              </ul>
            </li>
            <li
              className={cx(depth1 === 'contract' && 'active')}
              onClick={() => {
                setDepth1('contract');
              }}
            >
              <a>계약 관리</a>
              <ul className={cx('depth2')}>
                <li className={cx(pathname.startsWith('/contract/list') && 'active')}>
                  <Link to={'/contract/list'}>계약 목록</Link>
                </li>
                {/*                <li className={cx(pathname.startsWith('/contract/detail') && 'active')}>
                  <Link to={'/contract/detail'}>계약 등록</Link>
                </li>*/}
              </ul>
            </li>
            <li
              className={cx(depth1 === 'statistics' && 'active')}
              onClick={() => {
                setDepth1('statistics');
              }}
            >
              <a>조회 및 통계</a>
              <ul className={cx('depth2')}>
                <li className={cx(pathname.startsWith('/statistics/usage') && 'active')}>
                  <Link to={'/statistics/usage'}>사용량 조회</Link>
                </li>
                {authorizeRender(
                  'settles',
                  <li className={cx(pathname.startsWith('/statistics/settles') && 'active')}>
                    <Link to={'/statistics/settles'}>정산 내역</Link>
                  </li>
                )}
                <li className={cx(pathname.startsWith('/statistics/match') && 'active')}>
                  <Link to={'/statistics/match'}>매칭 인재 현황</Link>
                </li>
              </ul>
            </li>
            {authorizeRender(
              'popup',
              <li
                className={cx(depth1 === 'popup' && 'active')}
                onClick={() => {
                  setDepth1('popup');
                }}
              >
                <a>팝업 관리</a>
                <ul className={cx('depth2')}>
                  <li className={cx(pathname.startsWith('/popup/list') && 'active')}>
                    <Link to={'/popup/list'}>팝업 목록</Link>
                  </li>
                </ul>
              </li>
            )}
            {authorizeRender(
              'popup',
              <li
                className={cx(depth1 === 'settings' && 'active')}
                onClick={() => {
                  setDepth1('settings');
                }}
              >
                <a>설정</a>
                <ul className={cx('depth2')}>
                  <li className={cx(pathname.startsWith('/settings/match') && 'active')}>
                    <Link to={'/settings/match'}>매칭 직무</Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
        <div className={cx('content', 'modal-scroll')}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
