// Created by kdw0601 on 2024-07-05
import styles from './ContractDetailForm.module.scss';
import classNames from 'classnames/bind';
import { ContractAttachFileDto } from '../../../api/dto/ContractAttachFileDto';
import { ContractAttachFileType } from '../../../api/types/ContractAttachFileType';
import ContractLineButton from '../../../components/contract/common/ContractLineButton';
import React, { useRef } from 'react';

const cx = classNames.bind(styles);

interface Props {
  onClickFile: (fileUid: string, fileName?: string) => void;
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>, type: ContractAttachFileType) => void;
  fileType: ContractAttachFileType;
  fileList: ContractAttachFileDto[];
  disabled?: boolean;
  deleteDisabled?: boolean;
  onDelete: (fileUid: string) => void;
}

const ContractAttachFileView = (props: Props) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const { fileType, onChangeFile, onClickFile, fileList, disabled, deleteDisabled, onDelete } = props;

  return (
    <div className={cx('attach-wrap')}>
      <div>
        <ContractLineButton disabled={disabled} type={'button'} onClick={() => fileRef.current?.click()}>
          첨부
        </ContractLineButton>
        <input
          type={'file'}
          ref={fileRef}
          accept={'.pdf, .jpg, .jpeg, .png'}
          onChange={(e) => onChangeFile(e, fileType)}
          style={{ position: 'absolute', width: 0, height: 0 }}
        />
      </div>
      <ul className={cx('file-list')}>
        {fileList
          .filter((v) => v.type === fileType)
          .map(({ fileName, fileUid }) => (
            <li key={fileUid}>
              <span onClick={() => onClickFile(fileUid, fileName)}>{fileName}</span>
              {!deleteDisabled && <div onClick={() => onDelete(fileUid)} className={cx('delete')} />}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default ContractAttachFileView;
