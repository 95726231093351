import { useForm } from 'react-hook-form'
import { CompanyInfoDetailResponse } from '../../../repositories/company/detail/CompanyInfoDetailResponse'
import { useEffect, useState } from 'react'
import { companyInfoDetailSave } from '../../../repositories/company/detail/CompanyInfoDetailRepository'
import { useNavigate, useParams } from 'react-router-dom'
import { ContractApprovalStatusType } from '../../../types/CompanyApprovalStatusType'

interface CompanyInfoDetailForm {
  managerName: string
  phoneNumber: string
  ceoName: string
  businessNumber: string
  companyName: string
  reason: string
}

const useCompanyInfoDetailForm = (data: CompanyInfoDetailResponse) => {
  const { id } = useParams()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CompanyInfoDetailForm>()
  const [accountStatus, setAccountStatus] = useState<'STOP' | 'USE'>(data.accountStatus)
  const [approvedStatus, setApprovedStatus] = useState<ContractApprovalStatusType>(data.approvedStatus)
  const { managerName, phoneNumber, ceoName, businessNumber, companyName, reason } = data

  useEffect(() => {
    setAccountStatus(data.accountStatus)
    setApprovedStatus(data.approvedStatus)
  }, [data])

  const managerRegis = register('managerName', {
    required: '이름을 입력해주세요.',
    value: managerName,
  })

  const phoneNumberRegis = register('phoneNumber', {
    required: '핸드폰 번호를 입력해 주세요.',
    value: phoneNumber,
  })

  const ceoNameRegis = register('ceoName', {
    required: '대표자명을 입력해 주세요.',
    value: ceoName,
  })

  const businessNumberRegis = register('businessNumber', {
    required: '사업자 등록번호를 입력해 주세요.',
    value: businessNumber,
  })

  const companyNameRegis = register('companyName', {
    required: '회사명을 입력해주세요.',
    value: companyName,
  })

  const rejectedReasonRegis = register('reason', {
    validate: (value) => {
      if (approvedStatus === 'APPROVAL_DENIED' && !value) {
        return '반려 사유를 입력해주세요.'
      }
      return true
    },
    value: reason,
  })

  const onSubmit = handleSubmit(async (form: CompanyInfoDetailForm) => {
    try {
      await companyInfoDetailSave(id as string, {
        ...form,
        tenantApprovalStatus: approvedStatus,
      })
      navigate('/tenant/list')
    } catch (e) {
      console.error(e)
    }
  })

  return {
    onSubmit,
    managerRegis,
    ceoNameRegis,
    phoneNumberRegis,
    businessNumberRegis,
    companyNameRegis,
    accountStatus,
    setAccountStatus,
    approvedStatus,
    setApprovedStatus,
    reset,
    rejectedReasonRegis,
    errors,
  }
}

export default useCompanyInfoDetailForm
