import { get, put } from '../../../util/fetch';
import { GetContractDetailV1Rs, GetContractListV1Rs } from './rs';
import { SaveContractV1Rq } from './rq';

export const getContractListV1 = (searchParam: string) => {
  return get<GetContractListV1Rs>(`/api/v1/contracts?${searchParam}&sort=createdDateTime:DESC`);
};

export const getContractDetailV1 = (contractId: number) => {
  return get<GetContractDetailV1Rs>(`/api/v1/contracts/${contractId}`);
};

/** @deprecated */
export const saveContractDetailV1 = (rq: SaveContractV1Rq) => {
  return put('/api/v1/contracts', rq);
};

export const saveContractDetailV2 = (rq: SaveContractV1Rq) => {
  return put('/api/v2/contracts', rq);
};
