import { createTable } from '../../../util/createTable';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PopupListDto } from '../../../api/dto/PopupListDto';
import PopupOpenSwitch from './PopupOpenSwitch';
import dayjs from '../../../util/dayjs';

export const popupListTableBuilder = createTable({
  id: {},
  title: {},
  startDateTime: {},
  endDateTime: {},
  openType: {},
  createdDateTime: {},
});

export const PopupListTableHeader = popupListTableBuilder.createHeaderComponent({
  id: { children: '번호' },
  title: { children: '제목' },
  startDateTime: { children: '게시 시작' },
  endDateTime: { children: '게시 종료' },
  openType: { children: '게시 여부' },
  createdDateTime: { children: '등록 일자' },
});

export const PopupListTableRow = ({ data }: { data: PopupListDto }) => {
  const navigate = useNavigate();
  const { openType, endDatetime, startDateTime, title, id, createdDateTime } = data;

  const Row = popupListTableBuilder.createRowComponent({
    id: { children: id },
    title: { children: title },
    startDateTime: { children: startDateTime && dayjs(startDateTime).format('YYYY.MM.DD HH:mm') },
    endDateTime: { children: endDatetime && dayjs(endDatetime).format('YYYY.MM.DD HH:mm') },
    openType: { children: <PopupOpenSwitch openType={openType} id={id} />, onClick: (e) => e.stopPropagation() },
    createdDateTime: { children: createdDateTime && dayjs(createdDateTime).format('YYYY.MM.DD HH:mm:ss') },
  });

  return <Row onClick={() => navigate(`/popup/${id}`)} />;
};
