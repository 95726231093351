// Created by kdw0601 on 2024-07-04
import styles from './ProductDetailV1.module.scss';
import classNames from 'classnames/bind';
import React from 'react';
import ProductDetailV1TitleAndDescription from './ProductDetailV1TitleAndDescription';
import ProductDetailV1Composition from './ProductDetailV1Composition';
import ProductDetailV1Footer from './ProductDetailV1Footer';
import ProductDetailV1Pricing from './ProductDetailV1Pricing';

const cx = classNames.bind(styles);

const ProductDetailV1 = () => {
  return (
    <div className={cx('container')}>
      <ProductDetailV1TitleAndDescription />
      {/*상품구성*/}
      <div className={cx('itemGroup')}>
        <h4>상품구성</h4>
        <ProductDetailV1Composition showQuantityPrice />
      </div>
      <ProductDetailV1Pricing />
      <ProductDetailV1Footer />
    </div>
  );
};

export default ProductDetailV1;
