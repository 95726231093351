import { useQuery } from 'react-query'
import usePagination from '../../../hooks/usePagination'
import { useEffect, useState } from 'react'
import useDebouncedEffect from '../../../hooks/useDebouncedEffect'
import { useNavigate } from 'react-router-dom'
import { getMatchStatisticsList } from '../../../repositories/matchStatistics/matchStatisticsRepository'

const useMatchStatisticsList = () => {
  const [pagination, setPagination, resetPagination] = usePagination()
  const searchParams = new URLSearchParams(location.search)
  const keyword = searchParams.get('search')
  const navigate = useNavigate();

  const [search, setSearch] = useState(keyword ?? '')

  const {data, refetch} = useQuery(
    ['matchStatisticsList', pagination],
    async () => {
      return await getMatchStatisticsList({
        page: pagination.page,
        size: pagination.size,
        keyword: search
      });
    },
    {refetchOnWindowFocus: false}
  )

  useDebouncedEffect(
    () => {
      const params = new URLSearchParams(location.search)
      search ? params.set('search', search) : params.delete('search')
      navigate(params.toString() ? ('?' + params) : '')
    },
    500,
    [search]
  )

  useEffect(() => {
    resetPagination()
    setSearch(keyword ?? '')
    refetch()
  }, [keyword])

  return {
    data: data?.data,
    search,
    setSearch,
    setPagination,
  };
}

export default useMatchStatisticsList;