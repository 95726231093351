// Created by kdw0601 on 2022-10-18
import styles from './MultiSelectFilter.module.scss'
import classNames from 'classnames/bind'
import React, { forwardRef, PropsWithChildren, useEffect, useImperativeHandle, useRef, useState } from 'react'

const cx = classNames.bind(styles)

interface Props {
  options: {label: string; value: string}[];
  className?: string;
  value?: string[]
  onChange?: (val: string[]) => void;
}

export interface SelectFilterElement {
  value: string[];
}

const MultiSelectFilter = forwardRef<SelectFilterElement, PropsWithChildren<Props>>(function SelectFilter(props, ref) {
  const {value, onChange, children, className, options} = props;
  const [selected, setSelected] = useState<string[]>(value ?? []);
  const [isVisible, setIsVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    get value() {
      return selected;
    }
  }))

  useEffect(() => {
    value && setSelected(value);
  }, [value])

  useEffect(() => {
    if (!isMounted) return;
    if (isMounted && !isVisible) {
      onChange && onChange(selected);
    }
  }, [isVisible])

  useEffect(() => {
    setIsMounted(true)
  }, [])


  const handleClickOption = (e: React.MouseEvent, value: string) => {

    const isIncluded = selected.includes(value);
    if (isIncluded) {
      setSelected(selected.filter(v => v !== value));
    } else {
      setSelected([...selected, value]);
    }
  }

  const handleClickArea = () => {
    setIsVisible(v => !v);
    if (!isVisible) {
      const handler = (e: MouseEvent) => {
        if (dropdownRef.current?.contains(e.target as Node)) {
          return;
        }

        setIsVisible(false);
        window.removeEventListener('mousedown', handler);
      }

      window.addEventListener('mousedown', handler);
    }
  }

  const rect = dropdownRef.current?.getBoundingClientRect();

  return (
    <div className={cx('click-area')} ref={dropdownRef}>
      <div className={className} onClick={handleClickArea}>
      {children}
      </div>
      <div className={cx('dropdown', isVisible && 'visible')} style={rect && {top: rect.bottom, left: rect.left}} >
        {options.map((v, i) => (
          <div key={i} onClick={e => handleClickOption(e, v.value)}>
            <div className={cx('checkbox', selected.includes(v.value) && 'checked')}/>
            {v.label}
          </div>
        ))}
      </div>
    </div>
  )
})

export default MultiSelectFilter