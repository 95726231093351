import { get, post, postFileDownload, put } from '../../../util/fetch'
import PaginationResponse from '../../common/PaginationResponse'
import ChallengeListInfo from './response/ChallengeListResponse'
import ChallengePositionFilterRequest, {
  CHALLENGE_ORDERYPE,
} from './request/ChallengePositionFilterRequest'
import ChallengePositionFilterResponse from './response/ChallengePositionFilterResponse'

export const challengeList = ({
  page,
  size,
  search,
  filter: { orderType, direction },
}: {
  page: number
  size: number
  search: string
  filter: {
    orderType: string
    direction: string
  }
}) => {
  return get<{
    contents: ChallengeListInfo[]
    pageMeta: PaginationResponse
  }>(
    `/api/matching-challenge?size=${size}&page=${page}&name=${search}&sort=${
      orderType.length ? `${orderType}:${direction}` : ''
    }`
  )
}

export const getPositionLInk = (publicKey: string, positionId: number) => {
  return post<{ url: string }>('/api/matching-challenge/jobda-position-url', {
    publicKey,
    positionId,
  })
}

export const modifyJobdaOpenYn = (
  matchingChallengeId: number,
  openYn: boolean
) => {
  return put(`/api/matching-challenge/${matchingChallengeId}/jobda-open`, {
    openYn,
  })
}

export const challengeInfo = (matchingChallengeId: number) => {
  return get<{
    challengeName: string
    status: 'READY' | 'START' | 'END' | 'EDITING' | ''
  }>(`/api/matching-challenge/${matchingChallengeId}/simple`)
}

export const challengePositionFilterList = (
  matchingChallengeId: number,
  {
    orderType,
    direction,
    pageSize,
    pageNumber,
    search,
  }: ChallengePositionFilterRequest &
    CHALLENGE_ORDERYPE & {
      pageSize: number
      pageNumber: number
      search: string
    }
) => {
  return get<{
    contents: ChallengePositionFilterResponse[]
    pageMeta: PaginationResponse
    ids: number[]
  }>(
    `/api/matching-challenge/${matchingChallengeId}/participate-positions/filter?page=${pageNumber}&size=${pageSize}&name=${search}`
  )
}

export const challengePositionExcelDownload = (
  matchingChallengeId: number,
  request: { revealPositionIds: number[] }
) => {
  return postFileDownload(
    `/api/matching-challenge/${matchingChallengeId}/reveal-position-excel`,
    '챌린지 포지션목록.xls',
    request
  )
}

//챌린지종료
export const challengeTerminate = (matchingChallengeId: number) => {
  return put(`/api/matching-challenge/${matchingChallengeId}/terminate`)
}

//챌린지활성화
export const challengeDisTerminate = (matchingChallengeId: number) => {
  return put(`/api/matching-challenge/${matchingChallengeId}/disterminate`)
}
