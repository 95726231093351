// Created by kdw0601 on 2024-08-08

import ButtonCommon from '../../common/button/ButtonCommon';
import { useState } from 'react';
import { usePopupDetailStore } from './hooks/usePopupDetailStore';
import { postPopup } from '../../../api/function/popup';
import { useAlert } from '../../common/alert/AlertContextProvider';
import { useNavigate } from 'react-router-dom';

interface Props {
  id?: number;
}

const PopupDetailSaveButton = ({ id }: Props) => {
  const [isSaving, setIsSaving] = useState(false);
  const setError = usePopupDetailStore((state) => state.actions.setError);
  const alert = useAlert();
  const navigate = useNavigate();

  const handleClickSave = async () => {
    const {
      data: { title, fileName, fileUid, endDatetime, connectionLink, openType, startDateTime, stopViewingType },
    } = usePopupDetailStore.getState();

    let errorCount = 0;

    if (!title) {
      setError('title', '제목을 입력해주세요.');
      errorCount++;
    }

    if (!fileUid) {
      setError('fileUid', '이미지를 업로드 해주세요.');
      errorCount++;
    }

    if (endDatetime === startDateTime) {
      alert('게시 시작 일시와 종료 일시가 같습니다.');
      errorCount++;
    }

    if (errorCount) {
      return;
    }

    try {
      setIsSaving(true);
      await postPopup({
        id,
        connectionLink,
        openType,
        startDateTime,
        endDatetime,
        fileUid,
        stopViewingType,
        title,
      });

      navigate('/popup/list');
    } catch (e: any) {
      alert(e.cause);
      return;
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <ButtonCommon size={'large'} onClick={handleClickSave} loading={isSaving}>
      저장
    </ButtonCommon>
  );
};

export default PopupDetailSaveButton;
