export const string = (
  response: string,
  validation?: (response: string) => boolean
) => {
  if (typeof response === 'string') {
    if (validation) {
      if (validation(response)) return response
      else throw `validation Error : ${response}`
    } else return response
  }
  throw `Invalid String Type Error : ${response}`
}

export const number = (
  response: number,
  validation?: (response: number) => boolean
) => {
  if (typeof response === 'number') {
    if (validation) {
      if (validation(response)) return response
      else throw `validation number Error : ${response}`
    } else return response
  }
  throw 'Invalid Number Type Error'
}

export const boolean = (
  response: boolean,
  validation?: (response: boolean) => boolean
) => {
  if (typeof response === 'boolean') {
    if (validation) {
      if (validation(response)) return response
      else throw `validation boolean Error : ${response}`
    } else return response
  }
  throw 'Invalid boolean Type Error'
}
