import { useEffect, useState } from 'react';

interface OptionProps {
  root?: Element | Document | null;
  rootMargin?: string;
  threshold?: number | number[];
}

interface IntersectProps {
  (entries: IntersectionObserverEntry[], observer: IntersectionObserver): void;
}

const useIntersectObserver = (onIntersect: IntersectProps, option: OptionProps) => {
  const [ref, setRef] = useState(null);
  const checkIntersect = ([entry]: any, observer: IntersectionObserver) => {
    entry.isIntersecting && onIntersect(entry, observer);
  };

  useEffect(() => {
    let observer: any;
    if (ref) {
      observer = new IntersectionObserver(checkIntersect, {
        ...option,
      });
      observer.observe(ref);
    }
    return () => observer && observer.disconnect();
  }, [ref, option.root, option.threshold, option.rootMargin, checkIntersect]);
  return { setRef };
};

export default useIntersectObserver;
