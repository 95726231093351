import { useSearchParams } from 'react-router-dom';

export const useSearchParamState = (key: string, defaultValue: string) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const state = searchParams.get(key) ?? defaultValue;
  const setState = (val: string) => {
    setSearchParams((param) => {
      if (val === defaultValue) {
        param.delete(key);
      } else {
        param.set(key, val);
      }
      return param;
    });
  };

  return [state, setState, setSearchParams] as const;
};
