import { UpdatePasswordFormData } from '../../../components/account/changePassword/useExpiredAndUpdatePasswordForm'

export interface UpdatePasswordFormRequest {
  currentPassword: string
  newPassword: string
  logoutAllDeviceYn: boolean
}

export const transformUpdatePasswordForm = (
  data: UpdatePasswordFormData,
  logoutAllDeviceYn: boolean
): UpdatePasswordFormRequest => {
  const { password, newPassword } = data
  return { currentPassword: password, newPassword, logoutAllDeviceYn }
}
