import React from 'react';
import Layout from '../../components/common/Layout/Layout';
import { withRedirectUnauthorized } from '../../feature/authority/utils/withAuthority';
import PopupList from '../../components/popup/list/PopupList';

const PopupListPage = () => {
  return (
    <Layout>
      <div>
        <h2>팝업 목록</h2>
      </div>
      <PopupList />
    </Layout>
  );
};

export default withRedirectUnauthorized('popup', PopupListPage);
