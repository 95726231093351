import React, { ComponentPropsWithoutRef, FC } from 'react'
import classNames from 'classnames/bind'
import style from './ContractLineButton.module.scss'

const cx = classNames.bind(style)

type Props = {
  children: JSX.Element | string
} & ComponentPropsWithoutRef<'button'>

const ContractLineButton: FC<Props> = (props) => {
  return (
    <button type={'button'} {...props} className={cx('button', props.className)}>
      {props.children}
    </button>
  )
}

export default ContractLineButton
