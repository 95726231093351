import { useContext } from 'react'
import { ChallengeContext } from '../../../../pages/challenge/list/ChallengeContext'
import { saveSelectPosition } from '../../../../repositories/challenge/detail/CreateChallengeRepository'
import { AxiosError, AxiosResponse } from "axios";
import { useAlert } from '../../../common/alert/AlertContextProvider'

const useParticipation = () => {
  const {
    matchingChallengeId,
    setStep,
    SELECT_POSITION: { revealPositions },
    JOB_GROUP_INFO,
  } = useContext(ChallengeContext)

  const alert = useAlert()

  const modifyOrdering = (
    list: {
      order: number
      revealPositionId: number
    }[]
  ) => {
    saveSelectPosition({
      matchingChallengeId: matchingChallengeId!,
      revealPositions: list.map(({ revealPositionId }, order) => ({
        revealPositionId,
        order,
      })),
    })
  }

  const submitParticipation = async (temporary: boolean) => {
    await saveSelectPosition({
      matchingChallengeId: matchingChallengeId!,
      revealPositions: revealPositions.map(({ revealPositionId }, order) => ({
        revealPositionId,
        order,
      })),
    })
      .then(() => {
        !temporary && setStep(4)
      })
      .catch((e) => {
        alert((e as AxiosError<AxiosResponse>).response?.data.data.cause ?? '')
      })
  }

  const convertJobGroupCode = (job_code: number) =>
    JOB_GROUP_INFO.filter(({ code }) => code === job_code + '')[0]?.name ?? ''

  return { submitParticipation, modifyOrdering, convertJobGroupCode }
}
export default useParticipation
