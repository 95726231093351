import Tab from '../../../components/common/tab/Tab';
import { ComponentProps } from 'react';
import { ContractApprovalStatusType, ContractApprovalStatusTypeV2 } from '../../../types/CompanyApprovalStatusType';

export type TenantListTabType = 'contracted' | 'pending' | 'approved' | 'denied' | 'etc' | 'all';

export const TENANT_LIST_TABS: ComponentProps<typeof Tab>['list'] = [
  {
    key: 'all',
    name: '전체',
  },
  {
    key: 'pending',
    name: '승인대기',
  },
  {
    key: 'approved',
    name: '승인완료',
  },
  {
    key: 'denied',
    name: '승인거절',
  },
  {
    key: 'contracted',
    name: '계약완료',
  },
  {
    key: 'etc',
    name: '기타',
  },
];

export const tenantListTabToStatusList = (tab: TenantListTabType): ContractApprovalStatusTypeV2[] => {
  switch (tab) {
    case 'contracted':
      return ['CONTRACT_COMPLETE'];
    case 'pending':
      return ['APPROVAL_WAITING'];
    case 'approved':
      return ['APPROVAL_COMPLETE'];
    case 'denied':
      return ['APPROVAL_DENIED'];
    case 'etc':
      return ['TENANT_CREATING', 'TENANT_CREATING_FAILED', 'NOT_AGREEMENT'];
    default:
      return [];
  }
};
