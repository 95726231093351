import { useSearchParams } from 'react-router-dom';
import { useSearchParamState } from '../../../../hooks/useSearchParamState';

export const usePaginationSearchParams = () => {
  const [searchParam] = useSearchParams();
  const [page, setPage] = useSearchParamState('page', '1');
  const [size, setSize] = useSearchParamState('size', '10');

  const searchParamWithPage = new URLSearchParams(searchParam);
  searchParamWithPage.set('page', String(page));
  searchParamWithPage.set('size', String(size));

  return {
    page: Number(page),
    size: Number(size),
    setPage: (val: number) => setPage(String(val)),
    setSize: (val: number) => setSize(String(val)),
    searchParamString: searchParamWithPage.toString(),
    reset: () => {
      setPage('1');
      setSize('10');
    },
  };
};
