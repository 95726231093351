import React, { Suspense, useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import style from './ChallengeDetailPage.module.scss';
import Spinner from '../../../components/common/spinner/Spinner';
import Layout from '../../../components/common/Layout/Layout';
import { useParams } from 'react-router-dom';
import { ReactComponent as SettingIcon } from '../../../asset/icon/icon_settings_32_filled.svg';
import ChallengeCreateModal from '../../../components/challenge/create/ChallengeCreateModal';
import ChallengeDetailList from '../../../components/challenge/detail/ChallengeDetailList';
import { useQuery } from 'react-query';
import {
  challengeInfo,
  challengePositionExcelDownload,
  challengePositionFilterList,
} from '../../../repositories/challenge/list/ChallengeRepository';
import { ChallengeProvider } from '../list/ChallengeContext';
import GreenButton from '../../../components/challenge/create/common/GreenButton';
import { ReactComponent as DownloadIcon } from './../../../asset/icon/icon_download_18_line.svg';
import ChallengePositionFilterRequest, {
  CHALLENGE_ORDERYPE,
} from '../../../repositories/challenge/list/request/ChallengePositionFilterRequest';
import PaginationResponse from '../../../repositories/common/PaginationResponse';
import debounce from 'lodash.debounce';
import { withRedirectUnauthorized } from '../../../feature/authority/utils/withAuthority';

const cx = classNames.bind(style);

const ChallengeDetailPage = () => {
  const { id } = useParams();
  const [showChallengeModifyModal, setShowChallengeModifyModal] = useState(false);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<ChallengePositionFilterRequest & CHALLENGE_ORDERYPE>({
    orderType: 'ORDERING',
    direction: 'ASC',
  });
  const [check, setCheck] = useState<Set<number>>(new Set());
  const [isAll, setIsAll] = useState(false);
  const [pagination, setPagination] = useState<PaginationResponse>({
    isFirst: false,
    isLast: false,
    itemCount: 0,
    offset: 0,
    page: 1,
    size: 10,
    totalItemCount: 0,
    totalPageCount: 0,
  });

  const { data } = useQuery(
    ['challengeInfo', showChallengeModifyModal],
    async () => {
      const rs = await challengeInfo(Number(id));
      return rs.data;
    },
    { enabled: !!id }
  );

  const { data: list } = useQuery(
    ['challengePositionFilterList', filter, pagination, search, showChallengeModifyModal, id],
    async () => {
      const rs = await challengePositionFilterList(Number(id), {
        ...filter,
        pageSize: pagination.size,
        pageNumber: pagination.page,
        search,
      });

      return {
        content: rs.data?.contents,
        pageMeta: rs.data?.pageMeta,
        ids: rs.data?.ids,
      };
    },
    { cacheTime: 1 }
  );

  const useSearchCallback = useCallback(
    debounce((keyword: string) => {
      setSearch(keyword);
    }, 100),
    []
  );

  return (
    <ChallengeProvider>
      <>
        <Layout>
          <div className={cx('container')}>
            <div className={cx('header-wrapper')}>
              <span className={cx('status', data?.status)} />
              <h1 className={cx('title')}>{data?.challengeName}</h1>
              <button onClick={() => setShowChallengeModifyModal(true)} className={cx('icon-button')}>
                <SettingIcon />
              </button>
            </div>
            <div className={cx('toolbar')}>
              <div className={cx('search')}>
                <input
                  placeholder={'기업명, 매칭 포지션명'}
                  onChange={(e) => {
                    useSearchCallback(e.target.value);
                  }}
                  disabled={!list?.content?.length}
                />
              </div>
              <GreenButton
                size={'small'}
                type={'button'}
                disabled={!list?.content?.length}
                onClick={async () =>
                  await challengePositionExcelDownload(Number(id), {
                    revealPositionIds: [...check],
                  })
                }
              >
                <div className={cx('align')}>
                  <DownloadIcon />
                  엑셀 다운로드
                </div>
              </GreenButton>
            </div>
            <Suspense fallback={<Spinner />}>
              <ChallengeDetailList
                list={list?.content ?? []}
                filter={filter}
                setFilter={setFilter}
                page={list?.pageMeta as PaginationResponse}
                setPage={setPagination}
                check={check}
                setCheck={setCheck}
                isAll={isAll}
                setIsAll={setIsAll}
                ids={list?.ids ?? []}
              />
            </Suspense>
          </div>
        </Layout>
        {showChallengeModifyModal && (
          <ChallengeCreateModal
            matchingChallengeId={Number(id)}
            close={() => {
              setShowChallengeModifyModal(false);
            }}
          />
        )}
      </>
    </ChallengeProvider>
  );
};
export default withRedirectUnauthorized('matchingChallenge', ChallengeDetailPage);
