// Created by kdw0601 on 2024-06-20
import styles from './ContractDetailForm.module.scss';
import classNames from 'classnames/bind';
import ContractField from '../../../components/contract/detail/ContractField';
import Input from '../../../components/challenge/create/common/Input';
import React, { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import BlackButton from '../../../components/challenge/create/common/BlackButton';
import Checkbox from '../../../components/challenge/create/common/Checkbox';
import { extendContract } from '../../../repositories/contract/contractRepository';
import { DatePicker } from '../../../components/common/datePicker';

const cx = classNames.bind(styles);

interface Props {
  contractId?: number;
  startDate: Date;
  endDate: Date;
  startDateRegis: UseFormRegisterReturn<'startDate'>;
  endDateRegis: UseFormRegisterReturn<'endDate'>;
  error?: { message: string | undefined };
  isContractComplete?: boolean;
  isContractCanceled?: boolean;
  setStartDate: (val: Date) => void;
  setEndDate: (val: Date) => void;
  disabled?: boolean;
  range: { min: string; max: string } | null;
}

const ContractPeriod = (props: Props) => {
  const {
    startDateRegis,
    endDateRegis,
    error,
    startDate,
    endDate,
    setEndDate,
    setStartDate,
    isContractCanceled,
    isContractComplete,
    contractId,
    disabled,
    range,
  } = props;
  const [isExtendEnabled, setIsExtendEnabled] = useState(false);
  const [tempEndDate, setTempEndDate] = useState(new Date(endDate));
  const [isAgreed, setIsAgreed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isContractCompleteOrCanceled = isContractComplete || isContractCanceled;
  const activeEndDate = isExtendEnabled ? tempEndDate : endDate;
  const setActiveEndDate = isExtendEnabled ? setTempEndDate : setEndDate;

  const handleClickExtendButton = () => {
    setIsExtendEnabled(true);
    setIsAgreed(false);
  };

  const handleClickSubmit = async () => {
    if (!contractId) {
      throw Error('contract id is null');
    }
    try {
      setIsSubmitting(true);
      await extendContract(contractId, tempEndDate.toISOString().substring(0, 10));
      setEndDate(new Date(tempEndDate));
    } catch (e: any) {
      alert('오류 발생');
    } finally {
      setIsAgreed(false);
      setIsExtendEnabled(false);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <ContractField
        leftName={'계약 사용기간'}
        leftRequired
        errorField={error}
        left={
          <div>
            <div className={cx('contract-period')}>
              <DatePicker
                date={startDate}
                disabled={disabled}
                onChange={(date) => {
                  date.getTime() > endDate.getTime() && setEndDate(date);
                  setStartDate(date);
                }}
                minDate={range?.min ? new Date(range.min) : undefined}
                maxDate={range?.max ? new Date(range.max) : undefined}
                customInput={<Input className={cx('picker')} {...startDateRegis} />}
              />{' '}
              ~&nbsp;
              <DatePicker
                date={activeEndDate}
                disabled={disabled}
                onChange={(date) => {
                  setActiveEndDate(date.getTime() < startDate.getTime() ? startDate : date);
                }}
                customInput={
                  <Input
                    className={cx('picker')}
                    {...endDateRegis}
                    value={activeEndDate.toISOString().substring(0, 10)}
                    disabled={disabled}
                  />
                }
                minDate={isExtendEnabled ? endDate : startDate}
                maxDate={range?.max ? new Date(range.max) : undefined}
              />
              {isContractComplete && !isExtendEnabled && (
                <BlackButton type={'button'} size={'large'} onClick={handleClickExtendButton}>
                  계약 연장
                </BlackButton>
              )}
              {isExtendEnabled && (
                <BlackButton
                  type={'button'}
                  size={'large'}
                  onClick={handleClickSubmit}
                  disabled={!isAgreed || isSubmitting}
                >{`저장${isSubmitting ? '중...' : ''}`}</BlackButton>
              )}
            </div>
          </div>
        }
      />
      <ContractField
        left={
          isExtendEnabled ? (
            <div className={cx('caution')}>
              <div>주의</div>
              <div>
                시스템상 계약 정보가 수정됩니다.
                <br />
                신규 계약 및 계약 변경의 경우, 계약 취소 후 새로운 계약을 생성해주시기 바랍니다.
              </div>
              <div className={cx('agree-wrap')}>
                <label htmlFor={'agree'}>
                  {' '}
                  <Checkbox id={'agree'} checked={isAgreed} onChange={(e) => setIsAgreed(e.target.checked)} /> 내용을
                  확인하였습니다.
                </label>
              </div>
            </div>
          ) : (
            <></>
          )
        }
        leftName={''}
      />
    </>
  );
};

export default ContractPeriod;
