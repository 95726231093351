import { useState } from 'react'
import PaginationResponse from '../repositories/common/PaginationResponse'

const init: PaginationResponse = {
  isFirst: false,
  isLast: false,
  itemCount: 0,
  offset: 0,
  page: 1,
  size: 10,
  totalItemCount: 0,
  totalPageCount: 0,
}

const usePagination = () => {
  const [pagination, setPagination] = useState<PaginationResponse>({ ...init })
  const reset = () => {
    setPagination({ ...init })
  }

  return [pagination, setPagination, reset] as [
    PaginationResponse,
    typeof setPagination,
    typeof reset
  ]
}

export default usePagination
