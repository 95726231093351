import { GetPopupDetailRs } from '../../../../api/function/popup/rs';
import { PopupStopViewingType } from '../../../../api/types/PopupStopViewingType';
import { PopupOpenType } from '../../../../api/types/PopupOpenType';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import dayjs from '../../../../util/dayjs';

interface Actions {
  setData: (data: GetPopupDetailRs) => void;
  set: <Key extends keyof Data>(key: Key) => (value: Data[Key]) => void;
  setError: (key: string, message: string) => void;
}

interface Errors {
  [key: string]: string;
}

interface Data {
  title: string;
  startDateTime: string;
  endDatetime: string;
  fileUid: string;
  fileName: string;
  connectionLink: string;
  stopViewingType: PopupStopViewingType;
  openType: PopupOpenType;
}

const defaultData: Data = {
  title: '',
  startDateTime: dayjs().format('YYYY-MM-DDTHH:mm:00'),
  endDatetime: dayjs().format('YYYY-MM-DDTHH:mm:00'),
  fileUid: '',
  fileName: '',
  connectionLink: '',
  stopViewingType: PopupStopViewingType.TODAY,
  openType: PopupOpenType.OPEN,
};

const defaultErrors: Errors = {};

interface IProductDetailStore {
  data: Data;
  actions: Actions;
  errors: Errors;
}

export const usePopupDetailStore = create<IProductDetailStore>()(
  immer((set) => ({
    data: defaultData,
    errors: defaultErrors,
    actions: {
      set:
        <Key extends keyof Data>(key: Key) =>
        (value: Data[Key]) =>
          set(({ data, errors }) => {
            data[key] = value;
            errors[key] = '';
          }),
      setData: (data) =>
        set((state) => {
          state.data = data;
        }),
      setError: (key, message) =>
        set((state) => {
          state.errors[key] = message;
        }),
    },
  }))
);
