// Created by kdw0601 on 2022-10-17
import React from 'react'
import Layout from '../../../components/common/Layout/Layout'
import MatchStatisticsList from '../../../components/matchStatistics/list/MatchStatisticsList'

const MatchPage = () => {
  return (
    <Layout>
      <h2>매칭 인재 현황</h2>
      <MatchStatisticsList/>
    </Layout>
  )
}

export default MatchPage