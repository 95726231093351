import { useForm } from 'react-hook-form'
import { signup } from '../../repositories/signup/SignupRepository'
import { setCookie } from '../../util/cookie'
import { useNavigate } from 'react-router-dom'

export interface SignupForm {
  email: string
  password: string
  repassword: string
  birthday: string
  phoneNumber: string
  gender: 'MALE' | 'FEMALE'
  name: string
}

const useSignupForm = () => {
  const { handleSubmit, register, setValue } = useForm<SignupForm>({
    defaultValues: { gender: 'MALE' },
  })
  const navigate = useNavigate()

  const emailRegister = register('email', {
    required: '이메일을 입력해 주세요.',
  })

  const passwordRegister = register('password', {
    required: '비밀번호를 입력해 주세요.',
  })

  const repasswordRegister = register('repassword', {
    required: '비밀번호를 입력해 주세요.',
  })

  const birthdayRegister = register('birthday', {
    required: '생년월일을 입력해 주세요.',
  })
  const phoneNumberRegister = register('phoneNumber', {
    required: '핸드폰번호를 입력해 주세요.',
  })
  const genderRegister = register('gender', {
    required: '성별을 선택해 주세요.',
    value: 'MALE',
  })

  const nameRegister = register('name', { required: '이름을 입력해 주세요.' })

  const validation = ({ password, repassword }: SignupForm, callback: () => void) => {
    if (password !== repassword) {
      return
    }
    callback()
  }

  const onSubmit = handleSubmit(
    async ({ email, repassword, password, birthday, phoneNumber, gender, name }: SignupForm) => {
      validation(
        {
          email,
          password,
          repassword,
          birthday,
          phoneNumber,
          gender,
          name,
        },
        async () => {
          try {
            const {
              data: { body },
            } = await signup({
              email,
              password,
              birthday,
              phoneNumber,
              gender,
              name,
            })
            setCookie('token', body)
            navigate('/tenant/list')
          } catch (e: any) {
            console.error(e)
          }
        }
      )
    }
  )

  return {
    emailRegister,
    passwordRegister,
    repasswordRegister,
    birthdayRegister,
    phoneNumberRegister,
    genderRegister,
    handleSubmit,
    nameRegister,
    onSubmit,
    setValue,
  }
}

export default useSignupForm
