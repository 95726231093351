// Created by kdw0601 on 2024-07-04

import { ContractFindListV1Dto } from '../../../../api/dto/ContractFindListV1Dto';
import { contractListTableBuilder } from './contractListTableBuilder';
import { ContractStatus, ContractStatusType } from '../../../../repositories/contract/vo/ContractStatus';
import dayjs from '../../../../util/dayjs';
import { useNavigate } from 'react-router-dom';
import { ContractType } from '../../../../api/types/ContractType';
import React from 'react';
import ListPopover from '../../../common/listPopover/ListPopover';
import classNames from 'classnames/bind';
import styles from './ContractListTableRow.module.scss';

const cx = classNames.bind(styles);

interface Props {
  data: ContractFindListV1Dto;
}

const remainingTextMap = {
  [ContractStatusType.WAIT]: '대기',
  [ContractStatusType.CANCEL]: '취소',
  [ContractStatusType.TERMINATION]: '종료',
  [ContractStatusType.REJECT]: '반려',
} as const;

const ContractListTableRow = (props: Props) => {
  const {
    contractId,
    contractDate,
    companyName,
    businessNumber,
    number,
    endDate,
    startDate,
    productName,
    supplyPrice,
    status,
    type,
  } = props.data;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/contract/${contractId}`);
  };

  const addSubContract = () => {
    navigate(`/contract/new?mainContractId=${contractId}`);
  };

  const Row = contractListTableBuilder.createRowComponent({
    status: { children: ContractStatus[status] },
    contractNumber: { children: number },
    contractType: { children: type === ContractType.ADDITIONAL ? '추가계약' : '-' },
    contractDate: { children: contractDate },
    companyName: { children: companyName },
    businessNumber: { children: businessNumber },
    productName: { children: productName },
    supplyPrice: { children: supplyPrice.toLocaleString() + '원' },
    startDate: { children: startDate },
    endDate: { children: endDate },
    remainingDays: {
      children: status === 'COMPLETE' ? dayjs(endDate).diff(dayjs(new Date()), 'day') + '일' : remainingTextMap[status],
    },
    actions: {
      children: (
        <ListPopover
          options={[
            {
              name: '추가 계약',
              value: addSubContract,
              hidden: status !== 'COMPLETE' || type === ContractType.ADDITIONAL,
            },
          ]}
          onChange={addSubContract}
        >
          <button
            className={cx('more')}
            onClick={(e) => {
              e.stopPropagation();
            }}
            disabled={status !== 'COMPLETE' || type === ContractType.ADDITIONAL}
          />
        </ListPopover>
      ),
    },
  });

  return <Row onClick={handleClick} />;
};

export default ContractListTableRow;
