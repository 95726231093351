import { toSearchParams } from '../../util/searchBuilder'
import { get } from '../../util/fetch'
import { GetMatchStatisticsListRs } from './rs'

export const getMatchStatisticsList = (params: {
  page: number
  size: number
  keyword: string
}) => {
  const {keyword, size, page} = params;
  const paramString = toSearchParams({
    page,
    size,
  });

  return get<GetMatchStatisticsListRs>(`/api/v1/matching/current-situation${paramString}&keyword=${keyword}`)
}