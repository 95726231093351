import React, { Suspense } from 'react';
import classNames from 'classnames/bind';
import style from './TenantPage.module.scss';
import Layout from '../../../components/common/Layout/Layout';
import TenantList from '../../../components/tenant/list/TenantList';
import Spinner from '../../../components/common/spinner/Spinner';
import Tab from '../../../components/common/tab/Tab';
import { TENANT_LIST_TABS, TenantListTabType } from '../constants/TenantListTabType';
import { useSearchParamState } from '../../../hooks/useSearchParamState';

const cx = classNames.bind(style);

const TenantPage = () => {
  const [status, setStatus, setSearchParams] = useSearchParamState('status', 'all');

  const handleChangeTab = (tab: string) => {
    setStatus(tab);
    setSearchParams((param) => {
      param.delete('page');
      param.delete('size');
      return param;
    });
  };

  return (
    <Layout>
      <Suspense fallback={<Spinner />}>
        <div className={cx('container')}>
          <h1 className={cx('title')}>기업계정 목록</h1>
          <Tab tab={status} setTab={handleChangeTab} list={TENANT_LIST_TABS} />
          <TenantList status={status as TenantListTabType} />
        </div>
      </Suspense>
    </Layout>
  );
};

export default TenantPage;
