import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import style from './ProductList.module.scss';
import Pagination from '../../common/pagination/Pagination';
import usePagination from '../../../hooks/usePagination';
import { useQuery } from 'react-query';
import InputText from '../../common/input/InputText';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect';
import { getProductListV1 } from '../../../api/function/product';
import ProductListTable from './ProductListTable';
import ButtonCommon from '../../common/button/ButtonCommon';
import { Link } from 'react-router-dom';
import { useCheckAuthorizedAction } from '../../../feature/authority/utils/withAuthority';

const cx = classNames.bind(style);

const ProductList = () => {
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = usePagination();
  const isAuthorizedCreate = useCheckAuthorizedAction('createEditUseProduct');

  const { data, refetch } = useQuery(
    ['productList', pagination],
    async () => getProductListV1({ page: pagination.page, size: pagination.size }, search),
    {}
  );

  useEffect(() => {
    refetch();
  }, [pagination]);

  useDebouncedEffect(
    () => {
      refetch();
    },
    500,
    [search]
  );

  return (
    <>
      <div className={cx('toolbar')}>
        <div className={cx('search')}>
          <InputText
            placeholder={'상품명, 상품번호를 입력해주세요.'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {isAuthorizedCreate && (
          <Link to={'/product/new'}>
            <ButtonCommon>상품 추가하기</ButtonCommon>
          </Link>
        )}
      </div>
      <ProductListTable list={data?.data.list ?? []} />
      {data && (
        <Pagination
          page={{
            size: data.data.pagination.size,
            page: data.data.pagination.page + 1,
            totalPageCount: data.data.pagination.totalPages,
            totalItemCount: data.data.pagination.totalCount,
          }}
          pageHandler={setPagination}
        />
      )}
    </>
  );
};

export default ProductList;
