import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';

import style from './TenantDetail.module.scss';
import { getAcceptMemberDownload } from '../../../repositories/company/detail/CompanyInfoDetailRepository';
import { CompanyInfoDetailResponse } from '../../../repositories/company/detail/CompanyInfoDetailResponse';
import { TENANT_ACCOUNT_STATUS, TENANT_AGREEMENT_STATUS } from '../TenantCodeDefinetion';
import useCompanyInfoDetailForm from './useCompanyInfoDetailForm';
import { getBaseUrl } from '../../../util/fetch';
import { ContractApprovalStatusType } from '../../../types/CompanyApprovalStatusType';
import TenantStatusAndCurationOption from './TenantStatusAndCurationOption';

const cx = classNames.bind(style);

interface Props {
  data: CompanyInfoDetailResponse;
}

const TenantDetailInfo = ({ data }: Props) => {
  // const [isOpenApprovalStatusPopover, setIsOpenApprovalStatusPopover] = useState(false);

  const {
    managerRegis,
    ceoNameRegis,
    phoneNumberRegis,
    businessNumberRegis,
    companyNameRegis,
    accountStatus,
    approvedStatus,
    // setApprovedStatus,
    onSubmit,
    reset,
    rejectedReasonRegis,
    errors,
  } = useCompanyInfoDetailForm(data);

  const {
    email,
    agreementList,
    lastLoginDateTime,
    joinDateTime,
    approvedStatus: originalApprovedStatus,
    businessLicenseFileUid,
    contractFileUid,
    companyName,
  } = data;

  useEffect(() => {
    if (!data) return;

    const { companyName, ceoName, managerName, phoneNumber, businessNumber } = data;

    reset({ businessNumber, companyName, ceoName, managerName, phoneNumber });
  }, [data]);

  const canChangeApprovalStatus = Array.of<ContractApprovalStatusType>(
    'APPROVAL_WAITING',
    'APPROVAL_DENIED',
    'RE_APPROVAL_WAITING'
  ).includes(originalApprovedStatus);

  return (
    <>
      <form className={cx('info')} onSubmit={onSubmit}>
        <strong className={cx('sub')}>기업 계정 정보</strong>

        <div className={cx('format')}>
          <label className={cx('th')}>ID(이메일)</label>
          <span className={cx('text')}>{email}</span>
        </div>
        <div className={cx('format')}>
          <label className={cx('th')}>이름</label>
          <input {...managerRegis} className={cx('input')} name={'managerName'} readOnly />
        </div>
        <div className={cx('format')}>
          <label className={cx('th')}>전화번호</label>
          <input {...phoneNumberRegis} className={cx('input')} name={'phoneNumber'} readOnly />
        </div>
        <div className={cx('format')}>
          <label className={cx('th')}>최근 로그인</label>
          <span className={cx('text')}>{dayjs(lastLoginDateTime).format('YYYY년MM월DD일 HH:mm')}</span>
        </div>
        <div className={cx('format')}>
          <label className={cx('th')}>회원가입일</label>
          <span className={cx('text')}>{dayjs(joinDateTime).format('YYYY년MM월DD일 HH:mm')}</span>
        </div>
        <div className={cx('format')}>
          <label className={cx('th')}>회원상태</label>
          <span className={cx('text')}>{TENANT_ACCOUNT_STATUS[accountStatus]}</span>
        </div>
        {Array.of<ContractApprovalStatusType>('APPROVAL_COMPLETE', 'CONTRACT_COMPLETE').includes(
          originalApprovedStatus
        ) && (
          <div className={cx('format')}>
            <label className={cx('th')}>가입승인 리스트</label>
            <a
              className={cx('text', 'excel-download')}
              onClick={(e) => {
                e.preventDefault();
                getAcceptMemberDownload(data.id);
              }}
            >
              다운로드
            </a>
          </div>
        )}

        <strong className={cx('sub')}>기업 정보</strong>
        <div className={cx('format')}>
          <label className={cx('th')}>기업명</label>
          <input {...companyNameRegis} className={cx('input')} name={'phoneNumber'} readOnly />
        </div>
        <div className={cx('format')}>
          <label className={cx('th')}>사업자등록번호</label>
          <input {...businessNumberRegis} className={cx('input')} name={'phoneNumber'} readOnly />
        </div>
        <div className={cx('format')}>
          <label className={cx('th')}>대표자명</label>
          <input {...ceoNameRegis} className={cx('input')} name={'phoneNumber'} readOnly />
        </div>
        <div className={cx('format')}>
          <label className={cx('th')}>사업자등록증</label>
          <div>
            {businessLicenseFileUid && (
              <a
                className={cx('text')}
                href={`${getBaseUrl()}/api/file/view/${businessLicenseFileUid}`}
                target={'_blank'}
                rel={'noreferrer'}
                download={`${companyName}_사업자등록증.pdf`}
              >
                다운로드
              </a>
            )}
          </div>
        </div>
        {/* ATS-10697 더이상 사용안됨     */}
        {/*      <div className={cx('format')}>
        <label className={cx('th')}>서비스 이용계약</label>
        {canChangeApprovalStatus ? (
          <ListPopover
            isOpen={isOpenApprovalStatusPopover}
            content={
              <ul>
                {[
                  { name: '승인대기', value: 'APPROVAL_WAITING' },
                  { name: '승인', value: 'APPROVED' },
                  { name: '거절', value: 'APPROVAL_DENIED' },
                ].map(({ name, value }) => (
                  <li
                    key={value}
                    onClick={() => {
                      setApprovedStatus(value as ContractApprovalStatusType);
                      setIsOpenApprovalStatusPopover(false);
                    }}
                  >
                    {name}
                  </li>
                ))}
              </ul>
            }
            close={() => setIsOpenApprovalStatusPopover(false)}
          >
            <span className={cx('text', 'cursor')} onClick={() => setIsOpenApprovalStatusPopover(true)}>
              {TENANT_APPROVING_STATUS[approvedStatus === 'RE_APPROVAL_WAITING' ? 'APPROVAL_WAITING' : approvedStatus]}
            </span>
          </ListPopover>
        ) : (
          <span className={cx('text')}>{TENANT_APPROVING_STATUS[approvedStatus]}</span>
        )}
        {approvedStatus !== 'TENANT_CREATED' && contractFileUid && (
          <a
            className={cx('text', 'download-license')}
            href={`${getBaseUrl()}/api/file/view/${contractFileUid}`}
            target={'_blank'}
            rel={'noreferrer'}
            download={`${companyName}_사업자등록증.pdf`}
          >
            계약서 다운로드
          </a>
        )}
      </div>*/}
        {approvedStatus === 'APPROVAL_DENIED' && (
          <div className={cx('format')}>
            <label className={cx('th')} />
            <input {...rejectedReasonRegis} className={cx('input')} placeholder={'거절 사유를 입력해주세요'} />
            {errors.reason?.message && <span className={cx('text', 'red')}>{errors.reason?.message}</span>}
          </div>
        )}
        {canChangeApprovalStatus && (
          <div className={cx('format', 'save-guide')}>
            <label className={cx('th')} />
            <div className={cx('text')}>※ 하단의 저장 버튼을 클릭해야 계약 상태 처리가 완료됩니다.</div>
          </div>
        )}
        {!!agreementList.length && (
          <>
            <strong className={cx('sub')}>약관 동의내용</strong>
            {agreementList.map(
              (
                {
                  type,
                  version,
                  agreement,
                }: {
                  version: number;
                  agreement: boolean;
                  type: 'TERMS' | 'POLICY' | 'MARKETING_MESSAGE' | 'MARKETING_EMAIL';
                },
                i: number
              ) => (
                <div key={i} className={cx('format')}>
                  <label className={cx('th')}>{TENANT_AGREEMENT_STATUS[type]}</label>
                  <span className={cx('text')}>
                    {agreement ? '동의함' : '동의안함'} ver.{version}
                  </span>
                </div>
              )
            )}
          </>
        )}
      </form>
      <TenantStatusAndCurationOption />
    </>
  );
};

export default TenantDetailInfo;
