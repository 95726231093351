// Created by kdw0601 on 2022-05-27
import React, { useEffect, useState } from 'react';
import { forwardRef, InputHTMLAttributes } from 'react';
import styles from './InputText.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export interface InputNumberProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'onChange' | 'min' | 'max'> {
  onEnter?: () => void;
  onChange?: (val: number, rangeOut: boolean) => void;
  precision?: number;
  min?: number;
  max?: number;
  error?: string;
  noComma?: boolean;
}

const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(function InputText(props, ref) {
  const { onChange, onEnter, min, max, precision = 1, value, className, error, noComma, ...attr } = props;
  const [beforeValue, setBeforeValue] = useState(0);
  const [controlledValue, setControlledValue] = useState(value ?? '');

  useEffect(() => {
    setControlledValue(value ?? '');
  }, [value]);

  const handlePressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onEnter && onEnter();
      handleBlur(e.currentTarget.value);
    }
  };

  const handleBlur = (newVal: string) => {
    if (newVal === '') {
      setControlledValue('');
      onChange && onChange(NaN, false);
      return;
    }
    let val = Number(newVal.replace(/,/g, ''));

    if (isNaN(val)) {
      setControlledValue(beforeValue[noComma ? 'toString' : 'toLocaleString']());
      return;
    }

    let rangeOut = false;

    if (min !== undefined) {
      rangeOut ||= min > val;
      val = Math.max(val, min);
    }

    if (max !== undefined) {
      rangeOut ||= max < val;
      val = Math.min(val, max);
    }

    val = Math.floor(val / precision) * precision;
    setControlledValue(val[noComma ? 'toString' : 'toLocaleString']());

    setBeforeValue(val);
    onChange && onChange(val, rangeOut);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setControlledValue(e.currentTarget.value);
  };

  return (
    <div className={cx('wrapper')}>
      <input
        step={precision}
        className={cx('input', error && 'error', className)}
        {...attr}
        value={controlledValue}
        onChange={handleChange}
        onBlur={(e) => handleBlur(e.currentTarget.value)}
        onKeyDown={handlePressEnter}
        ref={ref}
      />
      {error && <div className={cx('error-message')}>{error}</div>}
    </div>
  );
});

export default InputNumber;
