import { useQuery, useQueryClient } from 'react-query'
import {
  changeContractSettlementState,
  getContractSettlementList,
} from '../../../repositories/contract/contractRepository'
import usePagination from '../../../hooks/usePagination'
import { useEffect, useState } from 'react'
import SettlementStatus from '../../../repositories/contract/vo/SettlementStatus'
import useDebouncedEffect from '../../../hooks/useDebouncedEffect'
import { useNavigate } from 'react-router-dom'
import { useAlert, useConfirm } from '../../common/alert/AlertContextProvider'
import { AxiosError, AxiosResponse } from 'axios'

const TODAY = new Date()

function getDaysInMonth(date: Date) {
  return `${new Date(date.getFullYear(), date.getMonth(), 0, 0, -date.getTimezoneOffset()).getDate()}`.padStart(2, '0')
}

const useSettlesList = () => {
  const searchParams = new URLSearchParams(location.search)
  const [pagination, setPagination, resetPagination] = usePagination()
  const [settlementStatusFilter, setSettlementStatusFilter] = useState<(keyof typeof SettlementStatus)[]>([
    'SETTLEMENT_COMPLETED',
    'UNSETTLED',
  ])
  const [isChangingSettlementStatus, setIsChangingSettlementStatus] = useState(false)
  const [startDate, setStartDate] = useState(TODAY)
  const [endDate, setEndDate] = useState(TODAY)
  const [bLimitDate, setBLimitDate] = useState(false)
  const keyword = searchParams.get('search')
  const [search, setSearch] = useState(keyword ?? '')
  const navigate = useNavigate()
  const confirm = useConfirm()
  const alert = useAlert()
  const queryClient = useQueryClient()

  const { data, refetch, isFetching } = useQuery(
    ['settlesList', pagination, settlementStatusFilter, bLimitDate, startDate, endDate],
    async () => {
      const rs = await getContractSettlementList({
        startDate: bLimitDate ? startDate.toISOString().substring(0, 8) + '01' : '2000-01-01',
        endDate: bLimitDate ? endDate.toISOString().substring(0, 8) + getDaysInMonth(endDate) : '9999-12-31',
        keyword: keyword ?? '',
        pageableRq: {
          page: pagination.page,
          size: pagination.size,
          sort: ['createdDateTime:desc'],
        },
        settlementStatus:
          settlementStatusFilter.length % Object.entries(SettlementStatus).length
            ? settlementStatusFilter[0]
            : undefined,
      })

      return {
        list: rs?.data?.list ? rs.data.list : [],
        pagination: rs?.data?.pagination,
      }
    },
    { refetchOnWindowFocus: false }
  )

  useDebouncedEffect(
    () => {
      const params = new URLSearchParams(location.search)
      search ? params.set('search', search) : params.delete('search')
      navigate(params.toString() ? '?' + params : '')
    },
    500,
    [search]
  )

  useEffect(() => {
    resetPagination()
    setSearch(keyword ?? '')
    refetch()
  }, [keyword])

  const handleChangeSettlementState = async (id: number, state: keyof typeof SettlementStatus) => {
    confirm(`회원에게 ${SettlementStatus[state]} 처리가 노출됩니다.\n유의해 주세요!`, {
      onConfirm: async () => {
        setIsChangingSettlementStatus(true)
        try {
          await changeContractSettlementState(id, state)
          await queryClient.invalidateQueries('settlesList')
        } catch (e) {
          alert((e as AxiosError<AxiosResponse>).response?.data.data.cause)
        } finally {
          setIsChangingSettlementStatus(false)
        }
      },
      confirmButtonType: 'red-line',
      title: `${SettlementStatus[state]} 처리 하시겠습니까?`,
    })
  }

  const handleChangeDateRange = (start: Date, end: Date) => {
    setStartDate(start)
    setEndDate(end)
  }

  const isLoading = isFetching || isChangingSettlementStatus

  return {
    data,
    search,
    setSearch,
    settlementStatusFilter,
    setSettlementStatusFilter,
    bLimitDate,
    setBLimitDate,
    setPagination,
    startDate,
    endDate,
    handleChangeDateRange,
    handleChangeSettlementState,
    isLoading,
  }
}

export default useSettlesList
