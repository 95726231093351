// Created by kdw0601 on 2024-08-08
import styles from './PopupDetail.module.scss';
import classNames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import { usePopupDetailStore } from './hooks/usePopupDetailStore';
import React, { useEffect, useState } from 'react';
import { getPopupDetail } from '../../../api/function/popup';
import Layout from '../../common/Layout/Layout';
import InputText from '../../common/input/InputText';
import Input from '../../challenge/create/common/Input';
import dayjs from '../../../util/dayjs';
import { DatePicker } from '../../common/datePicker';
import NewSelect from '../../common/select/NewSelect';

const cx = classNames.bind(styles);

const getHourSelectValue = (date: Date) => {
  const hour = date.getHours();
  return {
    label: `${hour > 11 ? '오후' : '오전'} ${hour % 12 || 12}시`,
    value: String(hour).padStart(2, '0'),
  };
};

const getMinuteSelectValue = (date: Date) => {
  const minute = date.getMinutes();
  return {
    label: `${String(minute).padStart(2, '0')}분`,
    value: String(minute).padStart(2, '0'),
  };
};

const PopupDetailDateRange = () => {
  const { set } = usePopupDetailStore((state) => state.actions);
  const startDateTime = usePopupDetailStore((state) => state.data.startDateTime);
  const endDatetime = usePopupDetailStore((state) => state.data.endDatetime);
  const startDateTimeObj = new Date(startDateTime);
  const endDateTimeObj = new Date(endDatetime);

  const handleChangeStartDateTime = (date: Date) => {
    const dateString = dayjs(date).format('YYYY-MM-DDTHH:mm:00');
    set('startDateTime')(dateString);
    if (date > endDateTimeObj) {
      set('endDatetime')(dateString);
    }
  };

  const handleChangeEndDateTime = (date: Date) => {
    const dateString = dayjs(date).format('YYYY-MM-DDTHH:mm:00');
    set('endDatetime')(dateString);

    if (date < startDateTimeObj) {
      set('startDateTime')(dateString);
    }
  };

  return (
    <>
      <DatePicker
        date={startDateTimeObj}
        onChange={handleChangeStartDateTime}
        customInput={<Input className={cx('picker')} readOnly value={dayjs(startDateTime).format('YYYY-MM-DD')} />}
      />
      <NewSelect
        className={cx('time')}
        size={'large'}
        options={Array(24)
          .fill(0)
          .map((v, i) => ({
            value: String(i).padStart(2, '0'),
            label: `${i > 11 ? '오후' : '오전'} ${i % 12 || 12}시`,
          }))}
        value={getHourSelectValue(startDateTimeObj)}
        onChange={(v) =>
          v && handleChangeStartDateTime(new Date(dayjs(startDateTime).format(`YYYY-MM-DDT${v.value}:mm:00`)))
        }
      />
      <NewSelect
        size={'large'}
        options={Array(60)
          .fill(0)
          .map((v, i) => ({
            value: String(i).padStart(2, '0'),
            label: `${i}분`,
          }))}
        value={getMinuteSelectValue(startDateTimeObj)}
        onChange={(v) =>
          v && handleChangeStartDateTime(new Date(dayjs(startDateTime).format(`YYYY-MM-DDTHH:${v.value}:00`)))
        }
      />
      <span>부터</span>
      <DatePicker
        date={endDateTimeObj}
        onChange={handleChangeEndDateTime}
        customInput={<Input className={cx('picker')} readOnly value={dayjs(endDatetime).format('YYYY-MM-DD')} />}
      />
      <NewSelect
        className={cx('time')}
        size={'large'}
        options={Array(24)
          .fill(0)
          .map((v, i) => ({
            value: String(i).padStart(2, '0'),
            label: `${i > 11 ? '오후' : '오전'} ${i % 12 || 12}시`,
          }))}
        value={getHourSelectValue(endDateTimeObj)}
        onChange={(v) =>
          v && handleChangeEndDateTime(new Date(dayjs(endDatetime).format(`YYYY-MM-DDT${v.value}:mm:00`)))
        }
      />
      <NewSelect
        size={'large'}
        options={Array(60)
          .fill(0)
          .map((v, i) => ({
            value: String(i).padStart(2, '0'),
            label: `${String(i).padStart(2, '0')}분`,
          }))}
        value={getMinuteSelectValue(endDateTimeObj)}
        onChange={(v) =>
          v && handleChangeEndDateTime(new Date(dayjs(endDatetime).format(`YYYY-MM-DDTHH:${v.value}:00`)))
        }
      />
      <span>까지</span>
    </>
  );
};

export default PopupDetailDateRange;
