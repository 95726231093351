import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { MinuteRound } from '../../../util/date';
import { getCompanyInfo } from '../../../repositories/contract/contractRepository';
import { SingleValue } from 'react-select';
import { getSimpleProductList } from '../../../repositories/product/productRepository';
import { fileUpload, viewFileByUid } from '../../../repositories/common/FileRepository';
import { useQuery } from 'react-query';
import SimpleProductListEntity from '../../../entities/product/SimpleProductListEntity';
import { SelectOption } from '../../../components/common/select/NewSelect';
import { ProductCompositionVO } from '../../../components/product/detail/vo/ProductCompositionVO';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAlert } from '../../../components/common/alert/AlertContextProvider';
import { AxiosError, AxiosResponse } from 'axios';
import { ContractStatusType } from '../../../repositories/contract/vo/ContractStatus';
import dayjs from 'dayjs';
import { ContractAttachFileDto } from '../../../api/dto/ContractAttachFileDto';
import { getContractDetailV1, saveContractDetailV2 } from '../../../api/function/contract';
import { getProductDetailV1 } from '../../../api/function/product';
import { ContractAttachFileType } from '../../../api/types/ContractAttachFileType';
import {
  totalPriceSelector,
  useProductDetailStore,
} from '../../../components/product/detail/store/useProductDetailStore';
import { SaveContractV2Rq } from '../../../api/function/contract/rq';
import { GetContractDetailV1Rs } from '../../../api/function/contract/rs';

interface ContractDetailForm {
  businessNumber: string;
  companyName: string;
  name: string;
  phone: string;
  email: string;
  memo: string;
  productId: string;
  numberOfApplicants: string;
  contractDate: string;
  startDate: string;
  endDate: string;
}

const calculateFixedPrice = (compositionList?: ProductCompositionVO[]) => {
  if (compositionList?.some(({ limitType }) => limitType === 'LIMIT')) {
    return compositionList?.reduce((acc, v) => {
      return acc + (v.quantity ?? 0) * v.price;
    }, 0);
  }

  return -1;
};

interface Props {
  id?: number;
  mainContractId?: number;
}

const useContractDetailForm = ({ id, mainContractId: mainContractIdParam }: Props) => {
  const [contractDate, setContractDate] = useState<Date>(MinuteRound()); //계약기간
  const [startDate, setStartDate] = useState<Date>(MinuteRound());
  const [endDate, setEndDate] = useState<Date>(
    new Date(
      new Date(startDate ?? new Date()).setFullYear((startDate ?? new Date()).getFullYear() + 1) - 1000 * 24 * 60 * 60
    )
  );

  const [contractStatus, setContractStatus] = useState<ContractStatusType>('WAIT');
  const [attachFileList, setAttachFileList] = useState<ContractAttachFileDto[]>([]);
  const [isBusinessNumberConfirmed, setIsBusinessNumberConfirmed] = useState(false);
  const [fixedPrice, setFixedPrice] = useState(-1);

  const [selectedProduct, setSelectedProduct] = useState<SelectOption | null>(null);
  const [isShowVerifyModal, setIsShowVerifyModal] = useState(false);
  const [publicKey, setPublicKey] = useState('');
  const navigate = useNavigate();
  const prevPath = (useLocation().state as { prevPath: string })?.prevPath;
  const alert = useAlert();
  const setProductInfo = useProductDetailStore((state) => state.actions.setData);
  const productInfo = useProductDetailStore((state) => state.data);
  const supplyPrice = useProductDetailStore(totalPriceSelector);
  // 상위계약번호
  const [mainContractNumber, setMainContractNumber] = useState('');
  const [mainContractDateRange, setMainContractDateRange] = useState<{ min: string; max: string } | null>(null);
  const [mainContractId, setMainContractId] = useState<number | null>(mainContractIdParam || null);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm<ContractDetailForm>();

  const { data: contractData } = useQuery(
    ['contractDetail', id],
    async () => {
      const { data } = await getContractDetailV1(id ?? 0);
      return data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );

  const applyData = (contractData: GetContractDetailV1Rs) => {
    const {
      contractDate,
      endDate,
      startDate,
      memo,
      status,
      fileList,
      contractCompany: { companyName, name, phone, email, businessNumber, publicKey },
      product,
      mainContractId,
    } = contractData;

    setProductInfo({
      ...product,
      compositionList: product.compositionList,
      description: '',
    });
    setContractDate(new Date(contractDate));
    setStartDate(new Date(startDate));
    setEndDate(new Date(endDate));
    setContractStatus(status);
    setPublicKey(publicKey);
    setMainContractId(mainContractId);
    setSelectedProduct({
      value: String(product.productId),
      label: product.name,
    });
    setFixedPrice(calculateFixedPrice(product.compositionList));
    setIsBusinessNumberConfirmed(!!businessNumber);
    setAttachFileList(fileList);

    reset({
      businessNumber,
      companyName,
      name,
      phone,
      email,
      memo,
    });
  };

  useEffect(() => {
    if (!contractData) {
      return;
    }

    applyData(contractData);
  }, [contractData]);

  // 추가계약 데이터 불러오기
  useEffect(() => {
    if (!mainContractIdParam) {
      return;
    }

    (async () => {
      const { data } = await getContractDetailV1(mainContractIdParam);
      if (data.status !== 'COMPLETE') {
        alert('추가 계약을 할 수 없는 계약입니다.');
        navigate('/contract/list');
        return;
      }

      applyData({ ...data, memo: '', status: 'WAIT', mainContractId: mainContractIdParam });
      setMainContractNumber(data.number);
      setMainContractDateRange({ min: data.startDate, max: data.endDate });
    })();
  }, [mainContractIdParam]);

  // 추가계약번호 불러오기
  useEffect(() => {
    if (!mainContractId) {
      return;
    }

    (async () => {
      const { data } = await getContractDetailV1(mainContractId);

      setMainContractNumber(data.number);
      setMainContractDateRange({ min: data.startDate, max: data.endDate });
    })();
  }, [mainContractId]);

  const { data: simpleProductList } = useQuery(
    ['simpleBusinessList'],
    async () => {
      const rs = await getSimpleProductList();
      return (
        rs?.data?.productList
          ?.map((simpleProduct) => SimpleProductListEntity(simpleProduct))
          .map<SelectOption>(({ name, productId }) => ({
            value: String(productId),
            label: name,
          })) ?? []
      );
    },
    { refetchOnWindowFocus: false }
  );

  //상품 정보 조회
  const onChangeSimpleProductInfo = async (option: SingleValue<{ label: string; value: string }>) => {
    const { data } = await getProductDetailV1(Number(option!.value));

    setSelectedProduct(option);
    setError('productId', {});
    setProductInfo(data);
  };

  //사업자 등록번호 조회
  const getBusinessNumberInfo = async () => {
    const bn = getValues('businessNumber');
    if (bn.length !== 10) {
      setError('businessNumber', {
        message: '올바른 사업자등록번호가 아닙니다.',
      });
      return;
    }

    try {
      const {
        data: { businessNumber, companyName, name, phone, email, publicKey },
      } = await getCompanyInfo(getValues('businessNumber'));

      setPublicKey(publicKey);
      setValue('businessNumber', businessNumber);
      setValue('companyName', companyName);
      setValue('name', name);
      setValue('phone', phone);
      setValue('email', email);
      setIsBusinessNumberConfirmed(true);
      setError('businessNumber', {});
    } catch (e) {
      setError('businessNumber', {
        message: '사업자등록번호 조회에 실패하였습니다. 가입 승인 완료 및 테넌트 생성 완료된 기업만 조회가 가능합니다.',
      });
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>, type: ContractAttachFileType) => {
    const file = e.target.files?.[0];
    if (!file) return;
    if (attachFileList.length >= 5) {
      e.target.value = '';
      return;
    }

    const formData = new FormData();
    formData.append('file', file, file.name);
    const { fileUid, fileName } = await fileUpload(formData);

    const reader = new FileReader();
    try {
      const fileUrl = await new Promise<string>((res, rej) => {
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (typeof reader.result === 'string') res(reader.result);
          else rej();
        };
      });

      setAttachFileList((val) => [...val, { fileName, fileUid, fileUrl, type }]);
    } finally {
      e.target.value = '';
    }
  };

  const onSubmit = handleSubmit(async ({ email, companyName, name, phone, memo, businessNumber }) => {
    let isSomeInvalid = false;

    if (!isBusinessNumberConfirmed) {
      isSomeInvalid = true;
    }

    if (isSomeInvalid) return;

    if (!selectedProduct) return;

    if (['COMPLETE', 'CANCEL', 'TERMINATION'].includes(contractStatus) && !isShowVerifyModal) {
      if (contractStatus === contractData?.status) {
        navigate(prevPath ?? '/contract/list');
        return;
      }

      setIsShowVerifyModal(true);
      return;
    }

    const { compositionList, etcPrice, matchingPrice, promotionPrice } = productInfo;

    const req: SaveContractV2Rq = {
      id,
      mainContractId,
      contractCompany: {
        companyName,
        name,
        phone,
        email,
        businessNumber,
        publicKey,
      },
      memo,
      contractDate: contractDate.toISOString().substring(0, 10),
      product: {
        compositionList: compositionList.map(({ compositionId, useType, price, limitType, quantity, overuseType }) => ({
          compositionId,
          useType,
          price,
          limitType,
          overuseType,
          quantity: quantity ?? 0,
          remainingQuantity: quantity ?? 0,
        })),
        productId: Number(selectedProduct.value),
        etcPrice: etcPrice ?? 0,
        matchingPrice: matchingPrice ?? 0,
        promotionPrice: promotionPrice ?? 0,
      },
      status: originalContractStatus === 'REJECT' ? 'WAIT' : contractStatus,
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      supplyPrice,
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
      fileList: attachFileList,
    };

    try {
      await saveContractDetailV2(req);
    } catch (e) {
      alert((e as AxiosError<AxiosResponse>).response?.data.data.cause ?? '');
      setIsShowVerifyModal(false);
      return;
    }

    location.href = prevPath ?? '/contract/list';
  });

  const handleClickBack = () => {
    navigate(prevPath ?? '/contract/list');
  };

  const businessNumberRegis = register('businessNumber', {
    validate: () => {
      if (!isBusinessNumberConfirmed) {
        return '사업자등록번호를 조회해주세요.';
      }
    },
    onChange: (e) => {
      e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '');
      setError('businessNumber', {});
      setValue('companyName', '');
      setValue('name', '');
      setValue('phone', '');
      setValue('email', '');
      setIsBusinessNumberConfirmed(false);
    },
  });

  const companyNameRegis = register('companyName', {});

  const nameRegis = register('name', {});

  const phoneRegis = register('phone', {});

  const emailRegis = register('email', {});

  const contractDateRegis = register('contractDate', {
    validate: () => (!contractDate ? '필수 입력사항입니다.' : undefined),
  });

  const startDateRegis = register('startDate', {
    validate: () => (!startDate ? '필수 입력사항입니다.' : undefined),
  });

  const endDateRegis = register('endDate', {
    validate: () => (!endDate ? '필수 입력사항입니다.' : undefined),
  });

  const productIdRegis = register('productId', {
    validate: () => (!selectedProduct ? '상품을 선택해주세요.' : undefined),
  });

  const memoRegis = register('memo');

  const handleDeleteAttachFile = (uid: string) => {
    setAttachFileList((list) => list.filter(({ fileUid }) => uid !== fileUid));
  };

  const handleClickModalConfirm = () => {
    onSubmit();
  };

  const handleClickModalCancel = () => {
    setIsShowVerifyModal(false);
  };

  const handleClickFileLink = async (fileUid: string, fileName?: string) => {
    if (!fileUid) return;
    await viewFileByUid(fileUid, fileName);
  };

  const handleSetContractDate = (date: Date) => {
    setContractDate(date);
    setError('contractDate', {
      message: !date ? '필수 입력사항입니다.' : undefined,
    });
  };

  const handleSetStartDate = (date: Date) => {
    setStartDate(date);
    setEndDate(date.getTime() > endDate.getTime() ? new Date(date) : endDate);
    setError('startDate', {
      message: !date ? '필수 입력사항입니다.' : undefined,
    });
  };

  const handleSetEndDate = (date: Date) => {
    setEndDate(date.getTime() < startDate.getTime() ? new Date(startDate) : date);
    setError('endDate', { message: !date ? '필수 입력사항입니다.' : undefined });
  };

  const originalContractStatus = contractData?.status ?? 'WAIT';
  const isContractComplete = originalContractStatus === 'COMPLETE';
  const isContractCanceled = originalContractStatus === 'CANCEL' || originalContractStatus === 'TERMINATION';
  const isWaitingForApproval = originalContractStatus === 'WAIT';
  const isRejected = originalContractStatus === 'REJECT';
  const isContractCompleteOrCanceled = isContractComplete || isContractCanceled;
  const contractDateRangeError = {
    message: errors.startDate?.message || errors.endDate?.message,
  };

  return {
    businessNumberRegis,
    companyNameRegis,
    nameRegis,
    phoneRegis,
    emailRegis,
    memoRegis,
    productIdRegis,
    onSubmit,
    contractDate,
    setContractDate: handleSetContractDate,
    startDate,
    setStartDate: handleSetStartDate,
    endDate,
    setEndDate: handleSetEndDate,
    getBusinessNumberInfo,
    onChangeSimpleProductInfo,
    handleFileUpload,
    setValue,
    simpleProductList,
    contractStatus,
    setContractStatus,
    attachFileList,
    errors,
    selectedProduct,
    handleDeleteAttachFile,
    isShowVerifyModal,
    handleClickModalConfirm,
    handleClickModalCancel,
    isContractCompleteOrCanceled,
    isContractCanceled,
    handleClickFileLink,
    startDateRegis,
    endDateRegis,
    contractDateRangeError,
    contractDateRegis,
    fixedPrice,
    handleClickBack,
    isContractComplete,
    isWaitingForApproval,
    isRejected,
    mainContractNumber,
    mainContractDateRange,
    mainContractId,
  };
};

export default useContractDetailForm;
