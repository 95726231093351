import React, { ComponentPropsWithoutRef, FC, forwardRef, Ref } from 'react';
import classNames from 'classnames/bind';
import style from './Input.module.scss';

const cx = classNames.bind(style);

type Props = {
  classNames?: string;
  ref?: Ref<HTMLInputElement>;
  block?: boolean;
} & ComponentPropsWithoutRef<'input'>;

const Input = forwardRef<HTMLInputElement, Props>(function Input(Props, ref) {
  return <input className={cx('input', Props.classNames, Props.block && 'block')} {...Props} ref={ref} />;
});

export default Input;
