import classNames from 'classnames/bind'
import React, { ComponentPropsWithoutRef, FC } from 'react'
import style from './LineButton.module.scss'

const cx = classNames.bind(style)

type Props = {
  children: JSX.Element | string
} & ComponentPropsWithoutRef<'button'>

const LineButton: FC<Props> = (Props) => {
  return (
    <button type={'button'} className={cx('button')} {...Props}>
      {Props.children}
    </button>
  )
}

export default LineButton
