import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { sessionAtom } from '../../store/auth.store'
import Spinner from '../../components/common/spinner/Spinner'

const HomePage = () => {
  const navigate = useNavigate()
  const session = useRecoilValue(sessionAtom)

  useEffect(() => {
    if (session) {
      navigate('/tenant/list')
    } else {
      navigate('/login')
    }
  })

  return <Spinner />
}

export default HomePage
