import { saveContents } from '../../../../repositories/challenge/detail/CreateChallengeRepository'
import { useContext } from 'react'
import { ChallengeContext } from '../../../../pages/challenge/list/ChallengeContext'
import { AxiosError, AxiosResponse } from "axios";
import { useAlert } from '../../../common/alert/AlertContextProvider'

const useContents = () => {
  const {
    matchingChallengeId,
    CONTENT_LIST: { list },
  } = useContext(ChallengeContext)

  const alert = useAlert()

  const submitContents = async (temporary: boolean) => {
    await saveContents({
      matchingChallengeId: matchingChallengeId!,
      list: list.map(({ matchingChallengeContentId, contents, name, title }) =>
        Object.assign(
          {
            contents: JSON.stringify(contents),
            title,
            name,
          },
          matchingChallengeContentId === -1
            ? {}
            : {
                matchingChallengeContentId,
              }
        )
      ),
      saveYn: !temporary,
    }).catch((e) => {
      alert((e as AxiosError<AxiosResponse>).response?.data.data.cause ?? '')
    })
  }

  return { submitContents }
}

export default useContents
