// Created by kdw0601 on 2023-10-23
import styles from './SortableColumn.module.scss'
import classNames from 'classnames/bind'
import { ReactComponent as SortIcon } from '../../../asset/icon/icon_sort_18_filled.svg'
import React, { PropsWithChildren, useContext } from 'react'

const cx = classNames.bind(styles)

export interface ISortData {
  orderType: string
  direction: string
}

interface Props {
  sortKey: string
}

interface ISortableContext {
  filter: ISortData
  setFilter: (filterValue: ISortData) => void
}

export const SortableColumnContext = React.createContext<ISortableContext>({
  filter: { orderType: '', direction: '' },
  setFilter: () => {
    throw new Error('SortableColumnContext is not provided')
  },
})

const SortableColumn = ({ sortKey, children }: PropsWithChildren<Props>) => {
  const { filter, setFilter } = useContext(SortableColumnContext)

  return (
    <th>
      <button
        className={cx('sort-button')}
        onClick={() => {
          const { orderType, direction } = filter

          setFilter({
            orderType: sortKey,
            direction: orderType === sortKey ? (direction === 'ASC' ? 'DESC' : 'ASC') : 'ASC',
          })
        }}
      >
        {children}
        <SortIcon />
      </button>
    </th>
  )
}

export default SortableColumn
