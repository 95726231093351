import React, { FC } from 'react'
import classNames from 'classnames/bind'

import styles from './PasswordChangeCompleteModal.module.scss'
import Modal from '../../common/modal/Modal'
import ButtonCommon from '../../common/button/ButtonCommon'

const cx = classNames.bind(styles)

type Props = {
  logout: () => void
}

const PasswordChangeCompleteModal: FC<Props> = ({ logout }) => {
  return (
    <Modal contentClassName={cx('content')}>
      <div className={cx('wrapper')}>
        <div className={cx('icon-lock')}></div>
        <h1>비밀번호 변경 완료</h1>
        <p>
          비밀번호 변경이 완료되었습니다.
          <br />
          다시 로그인을 해주세요.
        </p>
        <ButtonCommon size={'large'} type={'black'} className={cx('re-login')} onClick={logout} wide>
          확인
        </ButtonCommon>
      </div>
    </Modal>
  )
}

export default PasswordChangeCompleteModal
