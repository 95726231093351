const ProductType = {
  SELECT: 'JOBDA Select',
  TRIAL: 'JOBDA Trial',
  UNLIMITED: 'JOBDA Unlimited',
  SUITE: 'JOBDA Suite',
  BASIC: 'JOBDA 베이직',
  PREMIUM: 'JOBDA 프리미엄',
  ENTERPRISE: 'JOBDA 엔터프라이즈',
  TRIAL_V1: 'TRIAL_V1',
  UNLIMITED_V1: 'UNLIMITED_V1',
  CUSTOM_V1: 'CUSTOM_V1',
} as const;

export type ProductType = keyof typeof ProductType;

export default ProductType;
