import React, { FC, PropsWithChildren } from 'react'
import classNames from 'classnames/bind'
import style from './Modal.module.scss'
import Portal from '../portal/Portal'

const cx = classNames.bind(style)

interface Props {
  onClose?: () => void
  backdropClassName?: string
  contentClassName?: string
  isCloseOnClickBackdrop?: boolean
}

const Modal: FC<PropsWithChildren<Props>> = ({
  backdropClassName,
  isCloseOnClickBackdrop,
  onClose,
  contentClassName,
  children,
}) => {
  return (
    <Portal domId={'modal-root'}>
      <div
        className={cx('backdrop', backdropClassName)}
        onClick={() => {
          if (isCloseOnClickBackdrop && onClose) onClose()
        }}
      >
        <section
          className={cx('content', contentClassName, 'modal-scroll')}
          onClick={(e) => {
            if (isCloseOnClickBackdrop && onClose) e.stopPropagation()
          }}
        >
          {children}
        </section>
      </div>
    </Portal>
  )
}

export default Modal
