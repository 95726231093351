import { FC, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

interface Props {
  domId: string
  children: React.ReactNode
}

const Portal: FC<Props> = ({ children, domId }) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  return mounted
    ? createPortal(children, document.querySelector(`#${domId}`) as Element) as unknown as JSX.Element
    : null
}

export default Portal
