import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import style from './ProductDetailPage.module.scss';
import Layout from '../../../components/common/Layout/Layout';
import { useParams } from 'react-router-dom';
import { useProductDetailStore } from '../../../components/product/detail/store/useProductDetailStore';
import { getCompositionList, getProductDetailV1 } from '../../../api/function/product';
import ProductDetailV1 from '../../../components/product/detail/ProductDetailV1';
import { useCheckAuthorizedAction } from '../../../feature/authority/utils/withAuthority';

const cx = classNames.bind(style);

const ProductDetailPage = () => {
  const { id } = useParams();
  const { setData, initComposition } = useProductDetailStore((state) => state.actions);
  const isAuthorizedEdit = useCheckAuthorizedAction('createEditUseProduct');
  useEffect(() => {
    if (id) {
      getProductDetailV1(Number(id)).then((res) => setData(res.data));
      return;
    }

    getCompositionList().then((res) => initComposition(res.data.compositionList));
  }, [id]);

  return (
    <Layout>
      <div className={cx('container')}>
        <h1 className={cx('title')}>상품 {isAuthorizedEdit ? (id ? '수정' : '등록') : '정보'}</h1>
        <ProductDetailV1 />
      </div>
    </Layout>
  );
};

export default ProductDetailPage;
