import React, { FC } from 'react'
import classNames from 'classnames/bind'

import style from './Spinner.module.scss'
const cx = classNames.bind(style)

interface Props {
  size?: 'small' | 'large'
}

const Spinner: FC<Props> = ({ size = 'large' }) => {
  return (
    <div className={cx('container')}>
      <div className={cx('spinner', size)} />
    </div>
  )
}

export default Spinner
