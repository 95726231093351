import React, { createContext, Dispatch, PropsWithChildren, useContext, useReducer } from 'react'
import Modal from '../modal/Modal'
import ButtonCommon, { ButtonCommonProps } from '../button/ButtonCommon'
import classNames from 'classnames/bind'
import styles from './AlertContextProvider.module.scss'

const cx = classNames.bind(styles)

interface IAlertContext {
  title?: string;
  message: string;
  type: 'ALERT' | 'CONFIRM' | null;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmButtonType?: ButtonCommonProps['type'];
}

const initState: IAlertContext = {
  title: undefined, message: '', type: null, onConfirm: undefined, onCancel: undefined, confirmButtonType: undefined
}

type ActionType =
  ({ type: 'SHOW_ALERT'} & Omit<IAlertContext, 'type' | 'onCancel'>)
  | { type: 'CLOSE' }
  | ({ type: 'SHOW_CONFIRM'} & Omit<IAlertContext, 'type'>);

type DispatchType = Dispatch<ActionType> | null

const GlobalAlert = createContext<IAlertContext>({ message: '', type: null })

const ModalDispatchContext = createContext<DispatchType>(null)

function reducer(state: IAlertContext, action: ActionType): IAlertContext {
  switch (action.type) {
    case 'SHOW_ALERT':
      return { ...action, type: 'ALERT' }
    case 'SHOW_CONFIRM':
      return { ...action, type: 'CONFIRM' }
    case 'CLOSE':
      return { ...initState }
  }
}

const AlertContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [state, dispatch] = useReducer(reducer, initState)
  const { type, message, onConfirm, onCancel, title, confirmButtonType } = state

  const handleClickCancel = () => {
    onCancel && onCancel()
    dispatch({ type: 'CLOSE' })
  }

  const handleClickConfirm = () => {
    onConfirm && onConfirm()
    dispatch({ type: 'CLOSE' })
  }

  return (
    <GlobalAlert.Provider value={state}>
      <ModalDispatchContext.Provider value={dispatch}>
        {children}
        {type &&
          <Modal contentClassName={cx('modal-container')}>
            {title && (
              <h3 className={cx('title')}>
                {title}
              </h3>
            )}
            <div className={cx('sub')}>
              {message}
            </div>
            <div className={cx('buttons')}>
              {type === 'CONFIRM' && <ButtonCommon onClick={handleClickCancel} type={'secondary'}>취소</ButtonCommon>}
              <ButtonCommon onClick={handleClickConfirm} type={confirmButtonType}>확인</ButtonCommon>
            </div>
          </Modal>
        }
      </ModalDispatchContext.Provider>
    </GlobalAlert.Provider>
  )
}

export const useAlert = () => {
  const dispatch = useContext(ModalDispatchContext)

  if (!dispatch) {
    throw 'Dispatch error'
  }

  return (message: string, options?: Omit<IAlertContext, 'type' | 'onCancel' | 'message'>) => {
    dispatch({ type: 'SHOW_ALERT', message, ...options })
  }
}

export const useConfirm = () => {
  const dispatch = useContext(ModalDispatchContext)

  if (!dispatch) {
    throw 'Dispatch error'
  }

  return (message: string, options?: Omit<IAlertContext, 'type' | 'message'>) => {
    dispatch({ type: 'SHOW_CONFIRM', message, ...options })
  }
}

export default AlertContextProvider