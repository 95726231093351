import React, { useState } from 'react';
import classNames from 'classnames/bind';

import style from './ContractListToolbar.module.scss';
import InputText from '../../../components/common/input/InputText';
import { Link } from 'react-router-dom';
import ButtonCommon from '../../../components/common/button/ButtonCommon';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect';
import { useSearchParamState } from '../../../hooks/useSearchParamState';
import { useCheckAuthorizedAction } from '../../../feature/authority/utils/withAuthority';

const cx = classNames.bind(style);

const ContractListPage = () => {
  const [search, setSearch] = useState('');
  const [, setKeyword] = useSearchParamState('keyword', '');
  const isAuthorizedCreate = useCheckAuthorizedAction('createContract');

  useDebouncedEffect(
    () => {
      setKeyword(search);
    },
    500,
    [search]
  );

  return (
    <div className={cx('toolbar')}>
      <div className={cx('search')}>
        <InputText
          placeholder={'상품명, 상품번호를 입력해주세요.'}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {isAuthorizedCreate && (
        <Link to={'/contract/new'}>
          <ButtonCommon>계약 생성하기</ButtonCommon>
        </Link>
      )}
    </div>
  );
};
export default ContractListPage;
