// Created by kdw0601 on 2022-10-19
import {ReactComponent as delete32Line} from '../../../asset/icon/icon_delete_32_line.svg';
import {ReactComponent as add18Line} from '../../../asset/icon/icon_add_18_line.svg'
import {ReactComponent as arrowBottom24Line} from '../../../asset/icon/icon_arrow_bottom_24_line.svg'
import {ReactComponent as iconEvent24Line} from '../../../asset/icon/icon_event_24_line.svg'

import React from 'react';
import { SVGAttributes } from 'react'

interface Props extends SVGAttributes<SVGElement> {
  type: keyof typeof icons;
}

const icons = {
  delete32Line,
  add18Line,
  arrowBottom24Line,
  iconEvent24Line,
};
const Icon = (props: Props) => {
  const Icon = icons[props.type];
  return <Icon {...props}/>
}

export default Icon