// Created by ksy0105 on 2022-03-15

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export type Dayjs = dayjs.Dayjs;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Seoul');

export default (date?: dayjs.ConfigType) => dayjs.tz(date);

//30분 단위 시간 끊기
export const MinuteRound = (date = new Date(), minuteStep = 30): Date => {
  const ms = 1000 * 60 * minuteStep;

  return new Date(Math.ceil(date.getTime() / ms) * ms);
};

const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;

export function getTodayDate() {
  return new Date(new Date().getTime() - tzOffset);
}
