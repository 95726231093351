// Created by kdw0601 on 2024-07-04

import React from 'react';
import { useQuery } from 'react-query';
import { getContractListV1 } from '../../../api/function/contract';
import classNames from 'classnames/bind';
import style from '../../product/list/ProductList.module.scss';
import ContractListV1Table from './table/ContractListV1Table';
import PaginationV2 from '../../common/pagination/PaginationV2';
import { usePaginationSearchParams } from '../../common/pagination/hooks/usePaginationSearchParams';

const cx = classNames.bind(style);

interface Props {
  businessNumber?: string;
}

const ContractListV1 = ({ businessNumber }: Props) => {
  const { searchParamString, page, size } = usePaginationSearchParams();
  const params = businessNumber ? `keyword=${businessNumber}&page=${page}&size=${size}` : searchParamString;
  const { data } = useQuery(['contractList', params], async () => getContractListV1(params), {
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <ContractListV1Table list={data?.data.list ?? []} />
      {data && <PaginationV2 page={data.data.pagination} />}
    </>
  );
};

export default ContractListV1;
