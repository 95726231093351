import React, { useEffect, useState } from 'react';
import dayjs from '../../../util/dayjs';
import classNames from 'classnames/bind';
import ModalExpiredSession from './ModalExpiredSession';
import useAuth from '../../../hooks/useAuth';

import style from './Header.module.scss';

const ONE_SECOND = 1000;
const formatLeftSessionTime = (seconds: number) => {
  const h = String(Math.floor(seconds / 3600));
  const m = String(Math.floor(seconds / 60) % 60);
  const s = String(seconds % 60);
  return `${h.padStart(2, '0')}:${m.padStart(2, '0')}:${s.padStart(2, '0')}`;
};

const cx = classNames.bind(style);
const SessionTimer = () => {
  const { session, logout } = useAuth();
  const [leftSessionTime, setLeftSessionTime] = useState<number>(0);

  useEffect(() => {
    if (!session) return;

    const diff = dayjs(session?.expiredDateTime).diff(dayjs(), 'seconds');
    if (diff > 0) setLeftSessionTime(diff);
    const interval = setInterval(() => {
      const leftSeconds = dayjs(session?.expiredDateTime).diff(dayjs(), 'seconds');
      if (leftSeconds >= 0) setLeftSessionTime(leftSeconds);
      else logout();
    }, ONE_SECOND);
    return () => {
      clearInterval(interval);
    };
  }, [session]);

  if (!session) {
    return null;
  }

  return (
    <>
      <div className={cx('session-timer')}>{formatLeftSessionTime(leftSessionTime)}</div>
      {leftSessionTime === 0 && <ModalExpiredSession />}
    </>
  );
};

export default SessionTimer;
