import { get, post } from '../../../util/fetch';
import { GetPopupDetailRs, GetPopupListRs } from './rs';
import { PostPopupOpenTypeRq, PostPopupRq } from './rq';

export const getPopupList = () => {
  return get<GetPopupListRs>('/api/v1/pop-up');
};

export const postPopup = (rq: PostPopupRq) => {
  return post('/api/v1/pop-up', rq);
};

export const postPopupOpenType = (rq: PostPopupOpenTypeRq) => {
  return post('/api/v1/pop-up/open-type', rq);
};

export const getPopupDetail = (id: number) => {
  return get<GetPopupDetailRs>(`/api/v1/pop-up/${id}`);
};
