// Created by kdw0601 on 2024-07-04
// import styles from './ContractListV1Table.module.scss'
// import classNames from 'classnames/bind'

// const cx = classNames.bind(styles)

import Table from '../../../common/table/Table';
import React from 'react';
import { ContractListTableHeader } from './contractListTableBuilder';
import ContractListTableRow from './ContractListTableRow';
import { ContractFindListV1Dto } from '../../../../api/dto/ContractFindListV1Dto';

interface Props {
  list: ContractFindListV1Dto[];
}

const ContractListV1Table = ({ list }: Props) => {
  return (
    <Table>
      <ContractListTableHeader />
      <tbody>
        {list.map((v) => (
          <ContractListTableRow key={v.contractId} data={v} />
        ))}
      </tbody>
    </Table>
  );
};

export default ContractListV1Table;
