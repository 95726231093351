// Created by kdw0601 on 2022-10-18
import styles from './MultiSelectFilter.module.scss'
import classNames from 'classnames/bind'
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'

const cx = classNames.bind(styles)

interface Props<T> {
  options: { label: string; value: T; color?: string }[]
  className?: string
  onSelect?: (val: T) => void
}

const MultiSelectFilter = <T,>(props: PropsWithChildren<Props<T>>) => {
  const { onSelect, children, className, options } = props
  const [isVisible, setIsVisible] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleClickOption = (e: React.MouseEvent, value: T) => {
    onSelect && onSelect(value)
    setIsVisible(false)
    window.removeEventListener('mousedown', mouseDownHandler)
  }

  const mouseDownHandler = useCallback((e: MouseEvent) => {
    if (dropdownRef.current?.contains(e.target as Node)) {
      return
    }

    setIsVisible(false)
    window.removeEventListener('mousedown', mouseDownHandler)
  }, [])

  const handleClickArea = () => {
    setIsVisible((v) => !v)
    if (!isVisible) {
      window.addEventListener('mousedown', mouseDownHandler)
    }
  }

  useEffect(() => {
    return () => {
      window.removeEventListener('mousedown', mouseDownHandler)
    }
  }, [])

  return (
    <div className={cx('click-area')} ref={dropdownRef}>
      <div className={className} onClick={handleClickArea}>
        {children}
      </div>
      <div className={cx('dropdown', 'state-changer', isVisible && 'visible')}>
        {options.map((v, i) => (
          <div key={i} style={{ color: v.color }} onClick={(e) => handleClickOption(e, v.value)}>
            {v.label}
          </div>
        ))}
      </div>
    </div>
  )
}

export default MultiSelectFilter
