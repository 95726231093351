import React, { FC } from 'react'
import classNames from 'classnames/bind'
import style from './FormContent.module.scss'

const cx = classNames.bind(style)

interface Props {
  title: string | JSX.Element
  require?: boolean
  children: JSX.Element
}

const FormContent: FC<Props> = ({ title, require = false, children }) => {
  return (
    <div className={cx('container')}>
      <div className={cx('title', require && 'active')}>
        <span>{title}</span>
      </div>
      {children}
    </div>
  )
}

export default FormContent
