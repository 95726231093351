import React, { Suspense, useContext, useRef, useState } from 'react'
import classNames from 'classnames/bind'
import style from './Step2SelectPosition.module.scss'
import Input from '../common/Input'
import useOnClickOutside from '../../../common/useOnClickOutside'
import PositionList from './PositionList'
import Spinner from '../../../common/spinner/Spinner'
import SearchPosition from './SearchPosition'
import { ChallengeContext } from '../../../../pages/challenge/list/ChallengeContext'
import NewSelect from '../../../common/select/NewSelect'
import Message from '../../../common/message/Message'

const cx = classNames.bind(style)

const Step2SelectPosition = () => {
  const { JOB_GROUP_INFO, showMessage } = useContext(ChallengeContext)
  const [search, setSearch] = useState('')
  const [jobGroupCode, setJobGroupCode] = useState({
    label: '전체',
    value: 'all',
  })
  const [showList, setShowList] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useOnClickOutside(inputRef, () => {
    setShowList(false)
  })

  return (
    <>
      <div className={cx('container')}>
        <h2 className={cx('title')}>기업 포지션 선택</h2>
        <p className={cx('desc')}>
          매칭챌린지에 참여하는 포지션을 추가해주세요.
        </p>
        <div className={cx('search-box')}>
          <NewSelect
            size={'large'}
            className={cx('select')}
            value={jobGroupCode}
            options={[
              {
                label: '전체',
                value: 'all',
              },
              ...(JOB_GROUP_INFO ?? []).map(({ name, code }) => ({
                label: name,
                value: code,
              })),
            ]}
            onChange={(e) => {
              setJobGroupCode({ label: e!.label, value: e!.value })
            }}
          />

          <div className={cx('search-wrapper')} ref={inputRef}>
            <Input
              classNames={cx('search')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onClick={() => {
                setShowList(true)
              }}
            />
            <Suspense fallback={<Spinner />}>
              <SearchPosition
                show={showList}
                setShowList={setShowList}
                search={search}
                jobGroupCode={jobGroupCode.value}
              />
            </Suspense>
          </div>
        </div>
        <div className={cx('sub-title')}>
          <h2 className={cx('title', 'no-margin')}>참여 매칭 포지션</h2>
        </div>
        <PositionList />
      </div>
      {showMessage && (
        <Message>
          <span>{showMessage}</span>
        </Message>
      )}
    </>
  )
}

export default Step2SelectPosition
