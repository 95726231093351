import { useContext, useEffect } from 'react'
import { ChallengeContext } from '../../../../pages/challenge/list/ChallengeContext'
import { saveSelectPosition } from '../../../../repositories/challenge/detail/CreateChallengeRepository'
import { useAlert } from '../../../common/alert/AlertContextProvider'
import { AxiosError, AxiosResponse } from "axios";

const useSelectPosition = () => {
  const {
    matchingChallengeId,
    step,
    setStep,
    setShowMessage,
    SELECT_POSITION: { revealPositions },
  } = useContext(ChallengeContext)

  const alert = useAlert()

  const submitSelectPosition = async (temporary: boolean) => {
    await saveSelectPosition({
      matchingChallengeId: matchingChallengeId!,
      revealPositions: revealPositions.map(({ revealPositionId }, order) => ({
        order,
        revealPositionId: revealPositionId,
      })),
    })
      .then(() => {
        !temporary && setStep(step + 1)
      })
      .catch((e) => {
        alert((e as AxiosError<AxiosResponse>).response?.data.data.cause ?? '')
        // setShowMessage(e?.response?.data?.data?.cause ?? '')
        // setTimeout(() => {
        //   setShowMessage('')
        // }, 2000)
      })
  }

  return { submitSelectPosition }
}

export default useSelectPosition
