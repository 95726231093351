import React, { useEffect } from 'react';
import { usePopupListStore } from './store/usePopupListStore';
import { getPopupList } from '../../../api/function/popup';
import PopupListTable from './PopupListTable';
import InputText from '../../common/input/InputText';
import { Link } from 'react-router-dom';
import ButtonCommon from '../../common/button/ButtonCommon';

const PopupList = () => {
  const { list, needsRefresh } = usePopupListStore((state) => state.data);
  const setData = usePopupListStore((state) => state.actions.setData);

  const fetchData = () => {
    getPopupList().then((v) => setData(v.data.list));
  };

  useEffect(fetchData, []);
  useEffect(() => {
    if (needsRefresh) {
      fetchData();
    }
  }, [needsRefresh]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Link to={'/popup/new'}>
          <ButtonCommon>팝업 등록하기</ButtonCommon>
        </Link>
      </div>
      <PopupListTable list={list} />
    </>
  );
};

export default PopupList;
