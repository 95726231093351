import classNames from 'classnames/bind'
import React, { ComponentPropsWithoutRef, FC } from 'react'
import style from './BlackButton.module.scss'

const cx = classNames.bind(style)

type Props = {
  children: JSX.Element | string
  size: 'medium' | 'large' | 'small'
} & ComponentPropsWithoutRef<'button'>

const BlackButton: FC<Props> = (Props) => {
  return (
    <button
      type={'button'}
      className={cx('button', Props.size)}
      disabled={Props.disabled}
      {...Props}
    >
      {Props.children}
    </button>
  )
}

export default BlackButton
