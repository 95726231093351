import React, { FC, useContext } from 'react'
import classNames from 'classnames/bind'
import { ReactComponent as BasicInfoIcon } from '../../../../asset/icon/icon_file_done_24_line.svg'
import { ReactComponent as SelectPositionIcon } from '../../../../asset/icon/icon_choice_24_line.svg'
import { ReactComponent as ParticipationIcon } from '../../../../asset/icon/icon_list_24_line.svg'
import { ReactComponent as ContentsIcon } from '../../../../asset/icon/icon_person_24_line.svg'

import style from './Step.module.scss'
import { ChallengeContext } from '../../../../pages/challenge/list/ChallengeContext'
import useBasicInfo from '../step1BasicInfo/useBasicInfo'
import useSelectPosition from '../step2SelectPosition/useSelectPosition'
import useParticipation from '../step3ParticipationList/useParticipation'
import useContents from '../step4Contents/useContents'

const cx = classNames.bind(style)

interface Props {
  step: number
  setStep: (step: number) => void
}

const Step: FC<Props> = ({ step, setStep }) => {
  const { matchingChallengeId, initialize } = useContext(ChallengeContext)
  const { submitBasicInfo, validation } = useBasicInfo()
  const { submitSelectPosition } = useSelectPosition()
  const { submitParticipation } = useParticipation()
  const { submitContents } = useContents()

  return (
    <div className={cx('left')}>
      <h1 className={cx('title')}>매칭 챌린지 만들기</h1>
      <ol className={cx('step-list')}>
        {[
          { name: '기본 정보', key: 'basic' },
          { name: '기업 포지션 선택', key: 'select' },
          { name: '참여 포지션 목록', key: 'list' },
          { name: '인재 모집 컨텐츠', key: 'contents' },
        ].map(({ name, key }, idx) => (
          <li
            key={name + idx}
            className={cx(
              'step',
              idx > 0 && (!matchingChallengeId || validation()) && 'disabled',
              step === idx + 1 && 'active'
            )}
            onClick={async () => {
              if (matchingChallengeId && !validation()) {
                step === 1 && (await submitBasicInfo(true))
                step === 2 && (await submitSelectPosition(true))
                step === 3 && (await submitParticipation(true))
                step === 4 && (await submitContents(true))

                setStep(idx + 1)
              }
            }}
          >
            <div className={cx('step-left')}>
              {key === 'basic' && <BasicInfoIcon className={cx('icon')} />}
              {key === 'select' && (
                <SelectPositionIcon className={cx('icon')} />
              )}
              {key === 'list' && <ParticipationIcon className={cx('icon')} />}
              {key === 'contents' && <ContentsIcon className={cx('icon')} />}
              <span>{name}</span>
            </div>
            <div className={cx('right')}>
              {step === idx + 1 && (
                <div className={cx('dots')}>
                  <span />
                  <span />
                  <span />
                </div>
              )}
            </div>
          </li>
        ))}
      </ol>
    </div>
  )
}

export default Step
