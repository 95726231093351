import { get, post, put, remove } from '../../../util/fetch'
import FirstStepRequest from './request/FirstStepRequest'
import SecondStepRequest from './request/SecondStepRequest'
import ParticipationListResponse from './response/ParticipationListResponse'
import ModifyClosingEndDateRequest from './request/ModifyClosingEndDateRequest'
import ThirdStepRequest from './request/ThirdStepRequest'
import BasicInfoResponse from './response/BasicInfoResponse'
import TempContentsListResponse from './response/tempContentsListResponse'

export const modifyClosingDateTime = (request: ModifyClosingEndDateRequest) => {
  return put('/api/reveal-position/closing-date-time', { ...request })
}

export const searchPositionList = (
  name: string,
  jobGroupCode: string,
  { page, size }: { page: number; size: number }
) => {
  return get<{
    contents: ParticipationListResponse[]
    pageMeta: { page: number; size: number; totalItemCount: number }
  }>(
    `/api/reveal-position?name=${name}&jobGroupCode=${jobGroupCode}&page=${page}&size=${size}`
  )
}

export const getBasicInfo = (matchingChallengeId: number) => {
  return get<BasicInfoResponse>(
    `/api/matching-challenge/${matchingChallengeId}`
  )
}

export const getSelectPosition = (matchingChallengeId: number) => {
  return get<ParticipationListResponse[]>(
    `/api/matching-challenge/${matchingChallengeId}/participate-positions`
  )
}

export const getContentsPosition = (matchingChallengeId: number) => {
  return get<TempContentsListResponse[]>(
    `/api/matching-challenge/${matchingChallengeId}/contents`
  )
}

export const saveBasicInfo = (request: FirstStepRequest) => {
  return post<{ matchingChallengeId: number }>(
    '/api/matching-challenge/first-step',
    { ...request }
  )
}

export const modifyPositionName = (request: {
  name: string
  revealPositionId: number
}) => {
  return put('/api/reveal-position/name', { ...request })
}

export const saveSelectPosition = (request: SecondStepRequest) => {
  return post('/api/matching-challenge/second-step', { ...request })
}

export const saveContents = (request: ThirdStepRequest) => {
  return post('/api/matching-challenge/third-step', { ...request })
}

export const getParticipationList = (matchingChallengeId: number) => {
  return get<ParticipationListResponse[]>(
    `/api/matching-challenge/${matchingChallengeId}/participate-positions/step-three`
  )
}

export const getJDValid = (request: {
  positionId: number
  publicKey: string
}) => {
  return post<{ isOk: boolean }>('/api/reveal-position/valid-jobda', {
    ...request,
  })
}

export const getJDURL = (request: {
  publicKey: string
  positionId: number
}) => {
  return post<{ url: string }>('/api/matching-challenge/jobda-position-url', {
    ...request,
  })
}

export const removeChallengePosition = (request: {
  matchingChallengeId: number
  revealPositionId: number
}) => {
  return remove('/api/matching-challenge/position', { ...request })
}
