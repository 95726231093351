import React, { FC } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import classNames from 'classnames/bind';
import style from './Header.module.scss';

import dayjs from 'dayjs';

const LeftIcon = (props: any) => (
  <svg width="current" height="current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3897 17.9299C14.7216 18.2452 15.2463 18.2317 15.5616 17.8998C15.8769 17.5679 15.8635 17.0432 15.5315 16.7279L10.6794 12.1184L15.5315 7.50882C15.8635 7.1935 15.8769 6.66882 15.5616 6.3369C15.2463 6.00498 14.7216 5.99153 14.3897 6.30685L8.9096 11.5129C8.90222 11.5198 8.89495 11.5269 8.88777 11.5341C8.57259 11.851 8.56502 12.3632 8.87483 12.6893C8.8791 12.6938 8.88342 12.6982 8.88777 12.7026M14.3897 17.9299L8.9096 12.7238L14.3897 17.9299Z"
      fill="current"
    />
  </svg>
);

const RightIcon = (props: any) => (
  <svg width="current" height="current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.61033 6.07012C9.27842 5.75481 8.75373 5.76826 8.43841 6.10018C8.12309 6.43209 8.13655 6.95678 8.46846 7.2721L13.3206 11.8816L8.46846 16.4912C8.13655 16.8065 8.12309 17.3312 8.43841 17.6631C8.75373 17.995 9.27842 18.0085 9.61033 17.6932L15.0904 12.4871C15.0978 12.4802 15.1051 12.4731 15.1122 12.4659C15.4274 12.149 15.435 11.6368 15.1252 11.3107C15.1209 11.3062 15.1166 11.3018 15.1122 11.2974M9.61033 6.07012L15.0904 11.2762L9.61033 6.07012Z"
      fill="current"
    />
  </svg>
);

const cx = classNames.bind(style);

interface Props {
  setMode: (mode: 'DAY' | 'MONTH' | 'YEAR') => void;
  mode: 'DAY' | 'MONTH' | 'YEAR';
}

const Header: FC<ReactDatePickerCustomHeaderProps & Props> = ({
  date,
  decreaseMonth,
  increaseMonth,
  increaseYear,
  decreaseYear,
  setMode,
  mode,
}) => {
  return (
    <>
      <div className={cx('container')}>
        {mode !== 'MONTH' && (
          <button
            type={'button'}
            className={cx('button')}
            onClick={(e) => {
              mode === 'DAY' && decreaseMonth();
              mode === 'YEAR' && decreaseYear();
            }}
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <LeftIcon fill={'white'} width={24} height={24} />
          </button>
        )}
        <div className={cx('middle')}>
          <span
            className={cx('year')}
            onClick={() => {
              setMode('YEAR');
            }}
          >
            {date.getFullYear()}
          </span>
          <span
            className={cx('month')}
            onClick={() => {
              setMode('MONTH');
            }}
          >
            {dayjs(date).format('MM')}월
          </span>
        </div>
        {mode !== 'MONTH' && (
          <button
            type={'button'}
            className={cx('button')}
            onClick={(e) => {
              e.stopPropagation();
              mode === 'DAY' && increaseMonth();
              mode === 'YEAR' && increaseYear();
            }}
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <RightIcon fill={'white'} width={24} height={24} />
          </button>
        )}
      </div>
    </>
  );
};
export default Header;
