import ChallengeListResponse from '../../../repositories/challenge/list/response/ChallengeListResponse'
import { boolean, number, string } from '../../../util/Entity'

export const ChallengeListEntity = ({
  id,
  matchingChallengeName,
  participatingCompanyCount,
  participatingPositionCount,
  applyApplicantCount,
  finalPassCount,
  jobdaOpenYn,
  periodOfAcceptanceStartDateTime,
  periodOfAcceptanceEndDateTime,
  status,
  interviewProposal,
}: ChallengeListResponse) => ({
  id: number(id),
  matchingChallengeName: string(matchingChallengeName),
  participatingCompanyCount: number(participatingCompanyCount),
  participatingPositionCount: number(participatingPositionCount),
  applyApplicantCount: number(applyApplicantCount),
  finalPassCount: number(finalPassCount),
  jobdaOpenYn: boolean(jobdaOpenYn),
  periodOfAcceptanceStartDateTime: string(periodOfAcceptanceStartDateTime),
  periodOfAcceptanceEndDateTime: string(periodOfAcceptanceEndDateTime),
  interviewProposal: number(interviewProposal),
  status: string(
    status,
    (v: string) =>
      v === 'END' ||
      v === 'EDITING' ||
      v === 'START' ||
      v === 'READY' ||
      v === ''
  ),
})
