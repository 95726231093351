import React, { Suspense, useCallback, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import classNames from 'classnames/bind';
import style from './TenantList.module.scss';
import { companyListExcelDownload } from '../../../repositories/company/list/CompanyListRepositroy';
import { TENANT_APPROVING_STATUS } from '../TenantCodeDefinetion';
import ListPopover from '../../common/popover/ListPopover';
import debounce from 'lodash.debounce';
import Spinner from '../../common/spinner/Spinner';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getBaseUrl } from '../../../util/fetch';
import Alert from '../Alert';
import Empty from '../../common/empty/Empty';
import SortableColumn, { SortableColumnContext } from '../../common/sortableColumn/SortableColumn';
import { tenantListTabToStatusList, TenantListTabType } from '../../../pages/tenant/constants/TenantListTabType';
import { getTenantListV2 } from '../../../api/function/tenant';
import { usePaginationSearchParams } from '../../common/pagination/hooks/usePaginationSearchParams';
import PaginationV2 from '../../common/pagination/PaginationV2';
import { useSearchParamState } from '../../../hooks/useSearchParamState';
import { useCheckAuthorizedAction } from '../../../feature/authority/utils/withAuthority';

const cx = classNames.bind(style);

interface Props {
  status: TenantListTabType;
}

const TenantList = ({ status }: Props) => {
  const [search, setSearch, setSearchParams] = useSearchParamState('keyword', '');
  const [load, setLoad] = useState(false);
  const [idx, setIdx] = useState<null | number>(null);
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { reset: resetPage } = usePaginationSearchParams();
  const isAuthorizedApproveTenant = useCheckAuthorizedAction('approveTenant');
  const [tempApprovedList, setTempApprovedList] = useState<number[]>([]);

  const filteredStatus = tenantListTabToStatusList(status);
  const [filter, setFilter] = useState({
    orderType: searchParams.get('orderType') || '',
    direction: searchParams.get('direction') || '',
  });

  const handleChangeFilter = (v: typeof filter) => {
    setFilter(v);
    setSearchParams((params) => {
      params.set('orderType', v.orderType);
      params.set('direction', v.direction);
      return params;
    });
  };

  const queryClient = useQueryClient();
  const { data: companyAccountData, refetch: accountRefetch } = useQuery(
    ['companyAccountList', searchParams.toString()],
    async () => {
      const tab = searchParams.get('status');
      if (tab) {
        searchParams.delete('status');
        filteredStatus.forEach((v) => searchParams.append('statusList', v));
      }
      return getTenantListV2(searchParams.toString());
    }
  );

  const refetch = useCallback(
    debounce((keyword: string) => {
      resetPage();
      setSearch(keyword);
    }, 500),
    []
  );

  return (
    <>
      {error && <Alert outsideClick={() => setError(false)}>이미 승인이 진행중인 기업입니다.</Alert>}
      {alert && (
        <Alert outsideClick={() => setAlert(false)}>
          <strong className={cx('text')}>가입승인이 완료되었습니다.</strong>
          <p className={cx('p')}>
            승인이 완료되면{' '}
            <a
              rel={'noreferrer'}
              target={'_blank'}
              href={
                'https://teams.microsoft.com/l/channel/19%3a1852660ce0bb4e9aac65dc2525c82164%40thread.tacv2/ATS%2520%25EA%25B8%25B0%25EC%2597%2585%25EA%25B3%2584%25EC%25A0%2595%2520%25EC%258A%25B9%25EC%259D%25B8%2520%25EC%2595%258C%25EB%25A6%25BC%25EB%25B0%25A9?groupId=b8feb48c-32de-494f-94bd-a299f188c284&tenantId=e7c43225-bbc0-4154-a4e0-a53613b72b07'
              }
            >
              Teams Channel
            </a>{' '}
            채널 알람으로 확인할 수 있어요!
          </p>
        </Alert>
      )}
      <div className={cx('container')}>
        <div className={cx('tool')}>
          <div className={cx('left')}>
            <button className={cx('button', 'margin')} onClick={() => companyListExcelDownload()}>
              엑셀 다운로드
            </button>
          </div>
          <input
            className={cx('input')}
            placeholder={'기업명, 사업자등록번호'}
            onChange={(e) => {
              refetch(e.target.value);
            }}
          />
        </div>
        <Suspense fallback={<Spinner />}>
          <table className={cx('table')}>
            <thead className={cx('header')}>
              <tr>
                <SortableColumnContext.Provider value={{ filter, setFilter: handleChangeFilter }}>
                  <SortableColumn sortKey={'STATUS'}>상태</SortableColumn>
                  <td>ID</td>
                  <td>담당자</td>
                  <td>대표자명</td>
                  <td>전화번호</td>
                  <td>기업명</td>
                  <td>사업자등록번호</td>
                  <SortableColumn sortKey={'LAST_LOGIN_DATE'}>최근 로그인</SortableColumn>
                  <SortableColumn sortKey={'REGIST_DATE'}>회원가입일</SortableColumn>
                  <td />
                </SortableColumnContext.Provider>
              </tr>
            </thead>
            <tbody>
              {companyAccountData?.data.list.length ? (
                companyAccountData?.data.list.map(
                  ({
                    id,
                    approvedStatus,
                    attachFileUid,
                    email,
                    managerName,
                    phoneNumber,
                    companyName,
                    businessNumber,
                    ceoName,
                    lastLoginDateTime,
                    joinDateTime,
                  }) => (
                    <tr
                      key={id}
                      className={cx('row')}
                      onClick={() => {
                        navigate(`/tenant/detail/${id}`);
                      }}
                    >
                      <td>
                        {TENANT_APPROVING_STATUS[approvedStatus as 'APPROVED' | 'APPROVING' | 'DENY' | 'PENDING']}
                      </td>
                      <td>{email}</td>
                      <td>{managerName}</td>
                      <td>{ceoName}</td>
                      <td>{phoneNumber}</td>
                      <td>{companyName}</td>
                      <td>{businessNumber}</td>
                      <td>{lastLoginDateTime}</td>
                      <td>{joinDateTime}</td>
                      <td>
                        <ListPopover
                          isOpen={id === idx}
                          content={
                            <ul>
                              {/*                              {isAuthorizedApproveTenant &&
                              tenantListTabToStatusList('pending').includes(approvedStatus) && (
                                <li
                                  onClick={async (e) => {
                                    e.stopPropagation();
                                    await putApproveTenant([id]);
                                    setTempApprovedList((v) => [...v, id]);
                                    setIdx(null);
                                    await queryClient.invalidateQueries('companyAccountList');
                                  }}
                                  className={cx({ disabled: tempApprovedList.includes(id) })}
                                >
                                  가입승인{tempApprovedList.includes(id) && '(승인중)'}
                                </li>
                              )}*/}
                              <li
                                onClick={async (e) => {
                                  e.stopPropagation();
                                  setIdx(null);
                                }}
                                className={cx({ disabled: !attachFileUid })}
                              >
                                {attachFileUid ? (
                                  <a
                                    className={cx('link')}
                                    href={`${getBaseUrl()}/api/file/view/${attachFileUid}`}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                  >
                                    사업자등록증
                                  </a>
                                ) : (
                                  '사업자등록증'
                                )}
                              </li>
                            </ul>
                          }
                          close={() => setIdx(null)}
                        >
                          <button
                            className={cx(
                              'more',
                              id === idx && 'active'
                              // !!check.size && 'disabled'
                            )}
                            onClick={(e) => {
                              e.stopPropagation();
                              setIdx(id);
                            }}
                          />
                        </ListPopover>
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td colSpan={10}>
                    <Empty />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Suspense>
        {companyAccountData && <PaginationV2 page={companyAccountData.data.pagination} />}
      </div>
      {load && <Spinner />}
    </>
  );
};

export default TenantList;
