// Created by kdw0601 on 2022-10-17
import React from 'react';
import Layout from '../../../components/common/Layout/Layout';
import SettlesList from '../../../components/settles/list/SettlesList';
import { withRedirectUnauthorized } from '../../../feature/authority/utils/withAuthority';

const SettlesPage = () => {
  return (
    <Layout>
      <h2>정산 내역</h2>
      <SettlesList />
    </Layout>
  );
};

export default withRedirectUnauthorized('settles', SettlesPage);
