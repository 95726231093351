import { useContext, useEffect } from 'react'
import { ChallengeContext } from '../../../../pages/challenge/list/ChallengeContext'
import { saveBasicInfo } from '../../../../repositories/challenge/detail/CreateChallengeRepository'
import dayjs from 'dayjs'
import BasicInfoResponse from '../../../../repositories/challenge/detail/response/BasicInfoResponse'
import { PARTICIPATING } from './Step1BasicInfo'

const useBasicInfo = () => {
  const {
    BASIC_INFO: {
      name,
      bannerBackgroundColor,
      bannerTextColor,
      periodOfAcceptanceEndDateTime,
      periodOfAcceptanceStartDateTime,
      limitApplyCount,
      participatingCompaniesText,
      participatingCompanyStatus,
      hashTag,
      thumbnail,
      setName,
      setHashTag,
      setThumbnail,
      setLimitApplyCount,
      setParticipatingCompanyStatus,
      setParticipatingCompaniesText,
      setBannerBackgroundColor,
      setBannerTextColor,
      setPeriodOfAcceptanceStartDateTime,
      setPeriodOfAcceptanceEndDateTime,
    },
    VALIDATION: {
      bgValid,
      colorValid,
      startDateValid,
      endDateValid,
      applyLimitCount,
      setApplyLimitCount,
    },
    matchingChallengeId,
    setMatchingChallengeId,
    setStep,
  } = useContext(ChallengeContext)

  //Validation
  const validation = (): boolean => {
    if (
      !name ||
      !participatingCompaniesText ||
      !hashTag.length ||
      !thumbnail.thumbnailImageUUID ||
      !bannerTextColor ||
      !bannerBackgroundColor ||
      !bgValid ||
      !colorValid ||
      !startDateValid ||
      !endDateValid ||
      !applyLimitCount
    )
      return true

    return false
  }

  //STEP1 저장
  const submitBasicInfo = async (temporary: boolean) => {
    try {
      const { data } = await saveBasicInfo(
        Object.assign(
          {
            name,
            bannerBackgroundColor,
            bannerTextColor,
            periodOfAcceptanceStartDateTime: dayjs(
              periodOfAcceptanceStartDateTime
            ).format('YYYY-MM-DDTHH:mm:ss'),
            periodOfAcceptanceEndDateTime: dayjs(
              periodOfAcceptanceEndDateTime
            ).format('YYYY-MM-DDTHH:mm:ss'),
            limitApplyCount,
            participatingCompaniesText,
            participatingCompanyStatus: participatingCompanyStatus.value,
            hashTag: hashTag.map(({ name }) => name).join(','),
            ...thumbnail,
          },
          { matchingChallengeId }
        )
      )
      !matchingChallengeId && setMatchingChallengeId(data.matchingChallengeId)
      !temporary && setStep(2)
    } catch (e: any) {
      throw new Error(e)
    }
  }

  //STEP1 불러오기
  const loadBasicInfo = (basicInfo: BasicInfoResponse) => {
    const {
      name,
      bannerBackgroundColor,
      bannerTextColor,
      hashTag,
      participatingCompanyStatus,
      participatingCompaniesText,
      periodOfAcceptanceEndDateTime,
      periodOfAcceptanceStartDateTime,
      limitApplyCount,
      thumbnailImageUUID,
      thumbnailImageHeight,
      thumbnailImageWidth,
      thumbnailImageX,
      thumbnailImageY,
      thumbnailOriginImageUUID,
    } = basicInfo
    setName(name) //챌린지명
    //참여기업 및 포지션정보
    setParticipatingCompaniesText(participatingCompaniesText)
    setParticipatingCompanyStatus(
      PARTICIPATING.filter(
        ({ value }) => value === participatingCompanyStatus
      )[0]
    )
    //해시태그
    setHashTag(
      [...hashTag.split(',')].map((name, order) => {
        return { name, order }
      })
    )
    //잡다접수기간
    setPeriodOfAcceptanceStartDateTime(
      new Date(
        dayjs(periodOfAcceptanceStartDateTime).format('YYYY-MM-DD HH:mm')
      )
    )
    setPeriodOfAcceptanceEndDateTime(
      new Date(dayjs(periodOfAcceptanceEndDateTime).format('YYYY-MM-DD HH:mm'))
    )
    //인재 복수지원 포지션 개수
    setLimitApplyCount(limitApplyCount)
    //배너이미지
    setThumbnail({
      thumbnailImageUUID,
      thumbnailImageHeight,
      thumbnailImageWidth,
      thumbnailImageX,
      thumbnailImageY,
      thumbnailOriginImageUUID,
    })
    //배너,텍스트 색상
    setBannerTextColor(bannerTextColor)
    setBannerBackgroundColor(bannerBackgroundColor)
    setApplyLimitCount(
      (1 <= limitApplyCount && limitApplyCount <= 20) ||
        limitApplyCount === 9999
    )
  }

  return { submitBasicInfo, loadBasicInfo, validation }
}

export default useBasicInfo
