import { ComponentType, ReactNode, useEffect } from 'react';
import { authorityByAccountMap, AuthorityRequiredActionType } from '../constants/constants';
import { useAccountStore } from '../../../components/account/hooks/useAccountStore';

export const useCheckAuthorizedAction = (action: AuthorityRequiredActionType) => {
  const roleType = useAccountStore((state) => state.data.roleType);
  return !!roleType && authorityByAccountMap[action][roleType];
};

export const useAuthorizeRender = () => {
  const roleType = useAccountStore((state) => state.data.roleType);

  return (action: AuthorityRequiredActionType, element: ReactNode) => {
    if (!roleType || !authorityByAccountMap[action][roleType]) {
      return null;
    }

    return element;
  };
};

export const withHideUnauthorized = <Props extends JSX.IntrinsicAttributes>(
  action: AuthorityRequiredActionType,
  WrappedComponent: ComponentType<Props>
): ComponentType<Props> => {
  const Component = (props: Props) => {
    const isAuthorized = useCheckAuthorizedAction(action);
    if (!isAuthorized) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };

  return Component;
};

export const withRedirectUnauthorized = <Props extends JSX.IntrinsicAttributes>(
  action: AuthorityRequiredActionType,
  WrappedComponent: ComponentType<Props>,
  redirectUrl?: string
): ComponentType<Props> => {
  const AuthorizedComponent = withHideUnauthorized(action, WrappedComponent);

  const Component = (props: Props) => {
    const roleType = useAccountStore((state) => state.data.roleType);

    const isAuthorized = useCheckAuthorizedAction(action);

    useEffect(() => {
      if (roleType === null) {
        return;
      }

      if (!isAuthorized) {
        alert('권한이 없습니다.');
        location.href = redirectUrl ?? '/tenant/list';
      }
    }, [roleType]);

    return <AuthorizedComponent {...props} />;
  };

  return Component;
};
