import React, { ComponentPropsWithoutRef, FC, ReactElement } from 'react'
import classNames from 'classnames/bind'

import styles from './ListPopover.module.scss'
import Popover from './Popover'
import * as ReactTinyPopover from 'react-tiny-popover'

const cx = classNames.bind(styles)

interface Props {
  isOpen: boolean
  close?: (e: MouseEvent) => void
  content: ReactElement<ComponentPropsWithoutRef<'ul'>>
  className?: string
  align?: ReactTinyPopover.PopoverAlign
  positions?: ReactTinyPopover.PopoverPosition[]
  children: JSX.Element
}

const ListPopover: FC<Props> = ({
  isOpen,
  close,
  content,
  className,
  align,
  children,
  positions,
}) => {
  return (
    <Popover
      isOpen={isOpen}
      close={close}
      content={content}
      align={align}
      positions={positions}
      className={cx('list-popover', className)}
    >
      {children}
    </Popover>
  )
}

export default ListPopover
