// Created by kdw0601 on 2022-10-19
import styles from './SettlesList.module.scss'
import classNames from 'classnames/bind'
import React from 'react'
import InputText from '../../common/input/InputText'
import useSettlesList from './useSettlesList'
import Table from '../../common/table/Table'
import MultiSelectFilter from '../../common/selectFilter/MultiSelectFilter'
import SettlementStatus from '../../../repositories/contract/vo/SettlementStatus'
import { ReactComponent as DownIcon } from '../../../asset/icon/icon_arrow_bottom_24_line.svg'
import Empty from '../../common/empty/Empty'
import Checkbox from '../../challenge/create/common/Checkbox'
import Pagination from '../../common/pagination/Pagination'
import StateChanger from '../../common/selectFilter/StateChanger'
import Spinner from '../../common/spinner/Spinner'
import Tab from '../../common/tab/Tab'
import MonthRangePicker from '../../common/monthPicker/MonthRangePicker'

const cx = classNames.bind(styles)

const SettlesList = () => {
  const {
    data,
    search,
    setSearch,
    settlementStatusFilter,
    setSettlementStatusFilter,
    bLimitDate,
    setBLimitDate,
    setPagination,
    startDate,
    endDate,
    handleChangeDateRange,
    handleChangeSettlementState,
    isLoading,
  } = useSettlesList()

  return (
    <>
      <Tab
        tab={'POSTPAID'}
        setTab={() => false}
        list={[
          { key: 'POSTPAID', name: '후불 정산' },
          { key: 'PREPAID', name: '선불 충전', option: { disabled: true } },
        ]}
      />
      <div className={cx('toolbar')}>
        <div className={cx('date-wrap')}>
          <div className={cx('checkbox')}>
            <Checkbox id={'limit-date'} checked={bLimitDate} onChange={(e) => setBLimitDate(e.currentTarget.checked)} />
            <label htmlFor={'limit-date'}>기간조회여부</label>
            <MonthRangePicker start={startDate} end={endDate} disabled={!bLimitDate} onChange={handleChangeDateRange} />
          </div>
        </div>
        <div className={cx('search')}>
          <InputText
            placeholder={'계약번호, 기업명, 사업자 등록번호를 입력해주세요.'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <Table>
        <thead>
          <tr className={cx('column-group')}>
            <td colSpan={2} />
            <td colSpan={5}>기업</td>
            <td colSpan={2}>계약</td>
            <td colSpan={3}>정산</td>
          </tr>
          <tr>
            <td>
              <MultiSelectFilter
                className={cx('filter')}
                options={Object.entries(SettlementStatus).map(([value, label]) => ({ value, label }))}
                value={settlementStatusFilter}
                onChange={(v) => setSettlementStatusFilter(v as (keyof typeof SettlementStatus)[])}
              >
                상태
                <DownIcon />
              </MultiSelectFilter>
            </td>
            <td>기간</td>
            <td>기업명</td>
            <td>사업자등록번호</td>
            <td>담당자</td>
            <td>이메일</td>
            <td>전화번호</td>
            <td>계약상품</td>
            <td>계약번호</td>
            <td>지원자수</td>
            <td>역검 제출자수</td>
            <td>정산 금액(원)</td>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={12} style={{ height: 500 }}>
                <Spinner />
              </td>
            </tr>
          ) : data?.list.length ? (
            data.list.map((v) => (
              <tr key={v.contractSettlementId}>
                <td>
                  <StateChanger
                    className={cx('filter')}
                    options={Object.entries(SettlementStatus).map(([value, label]) => ({
                      value: value as keyof typeof SettlementStatus,
                      label,
                    }))}
                    onSelect={(val) =>
                      val !== v.settlementStatus && handleChangeSettlementState(v.contractSettlementId, val)
                    }
                  >
                    <span style={{ color: v.settlementStatus === 'SETTLEMENT_COMPLETED' ? 'blue' : 'red' }}>
                      {SettlementStatus[v.settlementStatus]}
                    </span>
                    <DownIcon />
                  </StateChanger>
                </td>
                <td>{v.period && `${v.period.replace('-', '년 ')}월`}</td>
                <td>{v.company.companyName}</td>
                <td>{v.company.businessNumber}</td>
                <td>{v.company.name}</td>
                <td>{v.company.email}</td>
                <td>{v.company.phone}</td>
                <td>{v.contract.productName}</td>
                <td>{v.contract.contractNumber}</td>
                <td>
                  {v.settlementQuantityList
                    .find((v) => v.compositionType === 'APPLICANT_QUANTITY')
                    ?.quantity.toLocaleString()}
                </td>
                <td>
                  {v.settlementQuantityList
                    .find((v) => v.compositionType === 'ACCA_QUANTITY')
                    ?.quantity.toLocaleString()}
                </td>
                <td>{v.supplyPrice?.toLocaleString()}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={12}>
                <Empty message={'정산 내역이 없습니다.'} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {data?.pagination && (
        <Pagination
          page={{
            size: data.pagination.size,
            page: data.pagination.page + 1,
            totalPageCount: data.pagination.totalPages,
            totalItemCount: data.pagination.totalCount,
          }}
          pageHandler={setPagination}
        />
      )}
    </>
  )
}

export default SettlesList
