// Created by kdw0601 on 2024-08-08
import { usePopupDetailStore } from './hooks/usePopupDetailStore';
import React from 'react';
import Radio from '../../common/radio/Radio';
import { PopupStopViewingType, popupStopViewingTypeText } from '../../../api/types/PopupStopViewingType';
import classNames from 'classnames/bind';
import styles from './PopupDetail.module.scss';

const cx = classNames.bind(styles);

const PopupDetailStopViewing = () => {
  const { set } = usePopupDetailStore((state) => state.actions);
  const stopViewingType = usePopupDetailStore((state) => state.data.stopViewingType);

  return (
    <div className={cx('stop-viewing-wrap')}>
      {[PopupStopViewingType.TODAY, PopupStopViewingType.PERMANENT, PopupStopViewingType.DISABLED].map((v) => (
        <label htmlFor={v} key={v}>
          <Radio id={v} checked={stopViewingType === v} onClick={() => set('stopViewingType')(v)} />{' '}
          {popupStopViewingTypeText[v]}
        </label>
      ))}
    </div>
  );
};

export default PopupDetailStopViewing;
