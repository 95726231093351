import React, { FC, Fragment, useState } from 'react'
import classNames from 'classnames/bind'
import style from './ChallengeList.module.scss'
import Pagination from '../../common/pagination/Pagination'
import { useQuery } from 'react-query'
import {
  challengeDisTerminate,
  challengeList,
  challengeTerminate,
  modifyJobdaOpenYn,
} from '../../../repositories/challenge/list/ChallengeRepository'
import dayjs from 'dayjs'
import PaginationResponse from '../../../repositories/common/PaginationResponse'
import { ChallengeListEntity } from '../../../entities/challenge/list/ChallengeListEntity'
import Switch from '../../common/switch/Switch'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as MoreIcon } from '../../../asset/icon/icon_more_vert_24_line.svg'
import ListPopover from '../../common/popover/ListPopover'
import ChallengeCreateModal from '../create/ChallengeCreateModal'
import SortableColumn, { SortableColumnContext } from '../../common/sortableColumn/SortableColumn'

const cx = classNames.bind(style)

const STATUS_TEXT = {
  READY: '준비중',
  START: '진행중',
  END: '종료',
  EDITING: '설정중',
}

interface Props {
  search: string
  createModal: boolean
}

const ChallengeList: FC<Props> = ({ search, createModal }) => {
  const [pagination, setPagination] = useState<PaginationResponse>({
    isFirst: false,
    isLast: false,
    itemCount: 10,
    offset: 0,
    page: 1,
    size: 10,
    totalItemCount: 1,
    totalPageCount: 1,
  })
  const [idx, setIdx] = useState<number | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [challengeId, setChallengeId] = useState<null | number>(null)
  const [filter, setFilter] = useState({
    orderType: '',
    direction: '',
  })

  const { data, refetch } = useQuery(
    ['challengeList', pagination, search, showModal, createModal, filter],
    async () => {
      const rs = await challengeList({
        page: pagination.page,
        size: pagination.size,
        search,
        filter,
      })
      return {
        contents: rs.data.contents.map((challenge) => ChallengeListEntity(challenge)),
        pageMeta: rs.data.pageMeta,
      }
    }
  )

  const navigate = useNavigate()

  return (
    <>
      <div className={cx('container')}>
        {data?.contents?.length ? (
          <table className={cx('table')}>
            <thead className={cx('header')}>
              <SortableColumnContext.Provider value={{ filter: filter, setFilter: setFilter }}>
                <tr>
                  {(
                    [
                      { name: '상태', value: '' },
                      { name: '매칭 챌린지명', value: 'MATCHING_CHALLENGE_NAME' },
                      { name: '참여기업', value: '' },
                      { name: '전체 포지션', value: '' },
                      { name: '전체 지원자', value: '' },
                      { name: '면접제안', value: '' },
                      { name: '최종합격', value: '' },
                      { name: '잡다 게시', value: '' },
                      { name: '접수 기간', value: 'END_DATE_TIME' },
                      { name: '', value: '' },
                    ] as { name: string; value: any }[]
                  ).map(({ name, value }) => (
                    <Fragment key={value}>
                      {value ? <SortableColumn sortKey={value}>{name}</SortableColumn> : <th>{name}</th>}
                    </Fragment>
                  ))}
                </tr>
              </SortableColumnContext.Provider>
            </thead>
            <tbody>
              {data?.contents.map(
                ({
                  id,
                  matchingChallengeName,
                  participatingCompanyCount,
                  participatingPositionCount,
                  applyApplicantCount,
                  finalPassCount,
                  jobdaOpenYn,
                  interviewProposal,
                  periodOfAcceptanceEndDateTime,
                  periodOfAcceptanceStartDateTime,
                  status,
                }) => (
                  <tr
                    className={cx('row')}
                    key={id}
                    onClick={() => {
                      navigate(`/challenge/detail/${id}`)
                    }}
                  >
                    <td className={cx('status')}>
                      <div className={cx('wrapper')}>
                        <span className={cx('circle', status)} />
                        {STATUS_TEXT[status as 'START' | 'END' | 'EDITING']}
                      </div>
                    </td>
                    <td>{matchingChallengeName}</td>
                    <td>{participatingCompanyCount}</td>
                    <td>{participatingPositionCount}</td>
                    <td>{applyApplicantCount}</td>
                    <td>{interviewProposal}</td>
                    <td>{finalPassCount}</td>
                    <td onClick={(e) => e.stopPropagation()}>
                      <Switch
                        disabled={status === 'EDITING'}
                        isOn={jobdaOpenYn}
                        toggle={async () => {
                          await modifyJobdaOpenYn(id, !jobdaOpenYn)
                          await refetch()
                        }}
                      />
                    </td>
                    <td>
                      {dayjs(periodOfAcceptanceStartDateTime).format('YYYY년 MM월 DD일')} -{' '}
                      {dayjs(periodOfAcceptanceEndDateTime).format('YYYY년 MM월 DD일')}
                    </td>
                    <td className={cx('more')}>
                      <ListPopover
                        isOpen={idx === id}
                        content={
                          <ul>
                            <li
                              onClick={(e) => {
                                e.stopPropagation()
                                setChallengeId(id)
                                setShowModal(true)
                                setIdx(null)
                              }}
                            >
                              챌린지 수정
                            </li>
                            {status !== 'END' && status !== 'EDITING' && (
                              <li
                                onClick={async (e) => {
                                  e.stopPropagation()
                                  await challengeTerminate(id)
                                  await refetch()
                                  setIdx(null)
                                }}
                              >
                                챌린지 종료
                              </li>
                            )}
                            {status === 'END' && (
                              <li
                                onClick={async (e) => {
                                  e.stopPropagation()
                                  await challengeDisTerminate(id)
                                  await refetch()
                                  setIdx(null)
                                }}
                              >
                                챌린지 활성화
                              </li>
                            )}
                          </ul>
                        }
                        close={() => setIdx(null)}
                      >
                        <button
                          className={cx('more-btn')}
                          onClick={(e) => {
                            e.stopPropagation()
                            setIdx(id)
                          }}
                        >
                          <MoreIcon />
                        </button>
                      </ListPopover>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        ) : (
          <div className={cx('empty')}>
            <i className={cx('img')} />
            <strong className={cx('title')}>등록한 챌린지가 없습니다.</strong>
            <p className={cx('desc')}>챌린지를 생성해주세요.</p>
          </div>
        )}

        {!!data?.contents.length && (
          <Pagination
            page={data?.pageMeta}
            pageHandler={async (page) => {
              setPagination(page)
            }}
          />
        )}
      </div>
      {showModal && <ChallengeCreateModal matchingChallengeId={challengeId!} close={() => setShowModal(false)} />}
    </>
  )
}

export default ChallengeList
