import { createTable } from '../../../../util/createTable';

export const contractListTableBuilder = createTable({
  status: {},
  contractNumber: {},
  contractType: {},
  contractDate: {},
  companyName: {},
  businessNumber: {},
  productName: {},
  supplyPrice: {},
  startDate: {},
  endDate: {},
  remainingDays: {},
  actions: {},
});

export const ContractListTableHeader = contractListTableBuilder.createHeaderComponent({
  status: { children: '계약상태' },
  contractNumber: { children: '계약번호' },
  contractType: { children: '계약종류' },
  contractDate: { children: '계약일자' },
  companyName: { children: '기업명' },
  businessNumber: { children: '사업자등록번호' },
  productName: { children: '판매상품명' },
  supplyPrice: { children: '공급가액(VAT제외)' },
  startDate: { children: '사용시작일시' },
  endDate: { children: '사용종료일시' },
  remainingDays: { children: '종료까지 남은 기간' },
  actions: { children: '' },
});
