import React, { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.scss'
import '@midasit/date/dist/index.css'
import '@midasit/date/dist/react-datepicker.css'
import { createRoot } from 'react-dom/client'
import AlertContextProvider from './components/common/alert/AlertContextProvider'
import { RecoilRoot } from 'recoil'

declare module 'react-query/types/react/QueryClientProvider' {
  interface QueryClientProviderProps {
    children?: ReactNode
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
})

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <AlertContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AlertContextProvider>
    </QueryClientProvider>
  </RecoilRoot>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
