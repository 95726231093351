import React from 'react';
import classNames from 'classnames/bind';
import style from './Header.module.scss';
import SessionTimer from './SessionTimer';
import ButtonCommon from '../button/ButtonCommon';
import useAuth from '../../../hooks/useAuth';
import { useAccountStore } from '../../account/hooks/useAccountStore';

const cx = classNames.bind(style);

const Header = () => {
  const name = useAccountStore((state) => state.data.name);
  const { refreshSessionWithCoolDown, isCoolDown } = useAuth();
  const onClickRefreshSession = async () => {
    refreshSessionWithCoolDown();
  };

  return (
    <div className={cx('container')}>
      <div className={cx('left')}></div>
      <div className={cx('right')}>
        <div className={cx('session')}>
          <SessionTimer />
          <ButtonCommon
            size="small"
            className={cx(['secondary'])}
            onClick={onClickRefreshSession}
            disabled={isCoolDown}
          >
            연장
          </ButtonCommon>
        </div>
        <div className={cx('user')}>
          <span className={cx('circle')}>{name[0]}</span>
          <span className={cx('name')}>{name}</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
