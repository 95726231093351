// Created by kdw0601 on 2022-05-19
import styles from './Table.module.scss'
import classNames from 'classnames/bind'
import React, { HTMLAttributes } from 'react'

const cx = classNames.bind(styles)

const Table = (props: HTMLAttributes<HTMLTableElement>) => {
  return (
    <table {...props} className={cx('table', props.className)} />
  )
}

export default Table