import React, { useState } from 'react'
import {
  SetBasicInfo,
  BasicInfo,
} from '../../../repositories/challenge/detail/response/BasincInfo'
import ParticipationListResponse from '../../../repositories/challenge/detail/response/ParticipationListResponse'
import ContentsListResponse from '../../../repositories/challenge/detail/response/ContentsListResponse'
import { useQuery } from 'react-query'
import {
  getJobGroup,
  getWorkingArea,
} from '../../../repositories/common/CommonRepository'
import { MinuteRound } from '../../../util/date'
import { challengeInfo } from '../../../repositories/challenge/list/ChallengeRepository'
import { useParams } from 'react-router-dom'

const BASIC_INFO = {
  name: '',
  bannerBackgroundColor: '',
  bannerTextColor: '',
  periodOfAcceptanceStartDateTime: MinuteRound(),
  periodOfAcceptanceEndDateTime: new Date(
    MinuteRound().setMinutes(MinuteRound().getMinutes() + 30)
  ),
  limitApplyCount: 0,
  participatingCompaniesText: '',
  participatingCompanyStatus: { label: '', value: '' },
  hashTag: [],
  thumbnail: {
    thumbnailImageUUID: '',
    thumbnailImageHeight: 0,
    thumbnailImageWidth: 0,
    thumbnailImageX: 0,
    thumbnailImageY: 0,
    thumbnailOriginImageUUID: '',
  },
  status: '',
}

export const ChallengeContext = React.createContext<{
  BASIC_INFO: BasicInfo & SetBasicInfo
  SELECT_POSITION: {
    revealPositions: (ParticipationListResponse & { modify: boolean })[]
    setRevealPositions: (
      v: (ParticipationListResponse & { modify: boolean })[]
    ) => void
  }
  CONTENT_LIST: {
    list: (ContentsListResponse & { order: number; modify: boolean })[]
    setList: (
      v: (ContentsListResponse & { order: number; modify: boolean })[]
    ) => void
  }
  VALIDATION: {
    colorValid: boolean
    bgValid: boolean
    startDateValid: boolean
    endDateValid: boolean
    applyLimitCount: boolean
    setApplyLimitCount: (valid: boolean) => void
    setColorValid: (valid: boolean) => void
    setBgValid: (valid: boolean) => void
    setStartDateValid: (valid: boolean) => void
    setEndDateValid: (valid: boolean) => void
  }
  step: number
  setStep: (step: number) => void
  matchingChallengeId: number | null
  setMatchingChallengeId: (id: number | null) => void
  JOB_GROUP_INFO: { id: number; name: string; code: string }[]
  setJOB_GROUP_INFO: (job: { id: number; name: string; code: string }[]) => void
  setWORKING_AREA: (
    list: {
      id: number
      code: string
      name: string
      alias: string
    }[]
  ) => void
  WORKING_AREA: {
    id: number
    code: string
    name: string
    alias: string
  }[]
  initialize: (idInit?: boolean) => void
  showMessage: string
  setShowMessage: (message: string) => void
}>({
  BASIC_INFO: {
    ...BASIC_INFO,
    setName: () => null,
    setBannerBackgroundColor: () => null,
    setBannerTextColor: () => null,
    setPeriodOfAcceptanceStartDateTime: () => null,
    setPeriodOfAcceptanceEndDateTime: () => null,
    setLimitApplyCount: () => null,
    setParticipatingCompaniesText: () => null,
    setParticipatingCompanyStatus: () => null,
    setThumbnail: () => null,
    setHashTag: () => null,
    setStatus: () => null,
  },
  SELECT_POSITION: {
    revealPositions: [],
    setRevealPositions: () => null,
  },
  CONTENT_LIST: {
    list: [],
    setList: () => null,
  },
  VALIDATION: {
    bgValid: false,
    colorValid: false,
    startDateValid: false,
    endDateValid: false,
    applyLimitCount: false,
    setApplyLimitCount: () => null,
    setBgValid: () => null,
    setColorValid: () => null,
    setStartDateValid: () => null,
    setEndDateValid: () => null,
  },
  step: 0,
  setStep: () => null,
  matchingChallengeId: null,
  setMatchingChallengeId: () => null,
  JOB_GROUP_INFO: [],
  setJOB_GROUP_INFO: () => null,
  setWORKING_AREA: () => null,
  WORKING_AREA: [],
  initialize: () => null,
  showMessage: '',
  setShowMessage: () => null,
})

export const ChallengeProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const { id } = useParams()

  const [matchingChallengeId, setMatchingChallengeId] = useState<number | null>(
    null
  )
  const [step, setStep] = useState(1)
  //BASIC_INFO
  const [name, setName] = useState('')
  const [bannerBackgroundColor, setBannerBackgroundColor] = useState('')
  const [bannerTextColor, setBannerTextColor] = useState('')
  const [periodOfAcceptanceStartDateTime, setPeriodOfAcceptanceStartDateTime] =
    useState<Date>(MinuteRound())
  const [periodOfAcceptanceEndDateTime, setPeriodOfAcceptanceEndDateTime] =
    useState<Date>(
      new Date(MinuteRound().setMinutes(MinuteRound().getMinutes() + 30))
    )
  const [limitApplyCount, setLimitApplyCount] = useState(1)
  const [participatingCompaniesText, setParticipatingCompaniesText] =
    useState('')
  const [participatingCompanyStatus, setParticipatingCompanyStatus] = useState({
    label: '참여기업',
    value: 'PARTICIPATING_COMPANY',
  })
  const [hashTag, setHashTag] = useState<{ name: string; order: number }[]>([])
  const [thumbnail, setThumbnail] = useState({
    thumbnailImageUUID: '',
    thumbnailImageHeight: 0,
    thumbnailImageWidth: 0,
    thumbnailImageX: 0,
    thumbnailImageY: 0,
    thumbnailOriginImageUUID: '',
  })
  const [revealPositions, setRevealPositions] = useState<
    (ParticipationListResponse & { modify: boolean })[]
  >([])
  const [list, setList] = useState<
    (ContentsListResponse & { order: number; modify: boolean })[]
  >([])
  const [JOB_GROUP_INFO, setJOB_GROUP_INFO] = useState<
    { id: number; name: string; code: string }[]
  >([])
  const [WORKING_AREA, setWORKING_AREA] = useState<
    {
      id: number
      code: string
      name: string
      alias: string
    }[]
  >([])

  //validation
  const [bgValid, setBgValid] = useState(true)
  const [colorValid, setColorValid] = useState(true)
  const [startDateValid, setStartDateValid] = useState(true)
  const [endDateValid, setEndDateValid] = useState(true)
  const [applyLimitCount, setApplyLimitCount] = useState(true)
  const [status, setStatus] = useState<
    'READY' | 'START' | 'END' | 'EDITING' | ''
  >('')
  const [showMessage, setShowMessage] = useState('')

  const initialize = (idInit?: boolean) => {
    idInit && setMatchingChallengeId(null)
    setStep(1)
    setName('')
    setBannerBackgroundColor('')
    setBannerTextColor('')
    setPeriodOfAcceptanceEndDateTime(MinuteRound())
    setPeriodOfAcceptanceEndDateTime(
      new Date(MinuteRound().setMinutes(MinuteRound().getMinutes() + 30))
    )
    setLimitApplyCount(0)
    setParticipatingCompaniesText('')
    setParticipatingCompanyStatus({
      label: '참여기업',
      value: 'PARTICIPATING_COMPANY',
    })
    setHashTag([])
    setThumbnail({
      thumbnailImageUUID: '',
      thumbnailImageHeight: 0,
      thumbnailImageWidth: 0,
      thumbnailImageX: 0,
      thumbnailImageY: 0,
      thumbnailOriginImageUUID: '',
    })
    setList([])
    setRevealPositions([])
  }

  //상태조회
  useQuery(
    ['challenge-status', matchingChallengeId, id],
    async () => {
      const rs = await challengeInfo(Number(matchingChallengeId))
      setStatus(rs?.data?.status)
    },
    {
      enabled: !!matchingChallengeId,
    }
  )

  //근무지역
  useQuery(
    ['workingArea', matchingChallengeId, id],
    async () => {
      const rs = await getWorkingArea()
      setWORKING_AREA(rs.data)
    },
    { enabled: !WORKING_AREA.length }
  )

  //직군
  useQuery(
    ['jobgroup', matchingChallengeId, id],
    async () => {
      const rs = await getJobGroup()
      setJOB_GROUP_INFO(rs.data.contents.map((rs) => ({ ...rs })))
    },
    {
      enabled: !JOB_GROUP_INFO.length,
    }
  )

  return (
    <ChallengeContext.Provider
      value={{
        BASIC_INFO: {
          name,
          bannerBackgroundColor,
          bannerTextColor,
          periodOfAcceptanceEndDateTime,
          periodOfAcceptanceStartDateTime,
          limitApplyCount,
          participatingCompaniesText,
          participatingCompanyStatus,
          thumbnail,
          hashTag,
          status,
          setBannerTextColor,
          setLimitApplyCount,
          setName,
          setBannerBackgroundColor,
          setParticipatingCompaniesText,
          setParticipatingCompanyStatus,
          setPeriodOfAcceptanceEndDateTime,
          setPeriodOfAcceptanceStartDateTime,
          setThumbnail,
          setHashTag,
          setStatus,
        },
        SELECT_POSITION: { revealPositions, setRevealPositions },
        CONTENT_LIST: {
          list,
          setList,
        },
        VALIDATION: {
          bgValid,
          setBgValid,
          colorValid,
          setColorValid,
          startDateValid,
          setStartDateValid,
          endDateValid,
          setEndDateValid,
          applyLimitCount,
          setApplyLimitCount,
        },
        step,
        setStep,
        matchingChallengeId,
        setMatchingChallengeId,
        JOB_GROUP_INFO,
        setJOB_GROUP_INFO,
        WORKING_AREA,
        setWORKING_AREA,
        initialize,
        showMessage,
        setShowMessage,
      }}
    >
      {children}
    </ChallengeContext.Provider>
  )
}
