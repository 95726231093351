import ExpiredAndUpdatePasswordForm from '../../../components/account/changePassword/ExpiredAndUpdatePasswordForm'
import { useNavigate } from 'react-router-dom'

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const type = new URLSearchParams(location.search).get('type') ?? ''
  if(type !== 'expired') navigate('/')

  return <ExpiredAndUpdatePasswordForm />
}

export default ResetPasswordPage
