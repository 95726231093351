// Created by kdw0601 on 2024-07-04
// import styles from './ContractListTab.module.scss'
// import classNames from 'classnames/bind'

// const cx = classNames.bind(styles)

import Tab, { TabItem } from '../../common/tab/Tab';
import { ContractStatusType } from '../../../repositories/contract/vo/ContractStatus';
import { useSearchParamState } from '../../../hooks/useSearchParamState';

const tabList: TabItem[] = [
  { key: 'ALL', name: '전체' },
  { key: ContractStatusType.WAIT, name: '계약 승인 대기' },
  { key: ContractStatusType.REJECT, name: '계약 반려' },
  { key: ContractStatusType.COMPLETE, name: '계약 완료' },
  { key: ContractStatusType.TERMINATION, name: '계약 종료' },
];

const ContractListTab = () => {
  const [tab, setTab, setSearchParams] = useSearchParamState('contract-status', 'ALL');

  const handleChangeTab = (tab: string) => {
    setTab(tab);
    setSearchParams((param) => {
      param.delete('page');
      param.delete('size');
      return param;
    });
  };

  return <Tab tab={tab} setTab={handleChangeTab} list={tabList} />;
};

export default ContractListTab;
