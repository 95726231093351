import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import style from './Step1BasicInfo.module.scss';
import FormContent from '../common/FormContent';
import Input from '../common/Input';
import BlackButton from '../common/BlackButton';
import Picture from './common/Picture';
import { ChallengeContext } from '../../../../pages/challenge/list/ChallengeContext';
import { useQuery } from 'react-query';
import { getBasicInfo } from '../../../../repositories/challenge/detail/CreateChallengeRepository';
import NewSelect from '../../../common/select/NewSelect';
import useBasicInfo from './useBasicInfo';
import { useParams } from 'react-router-dom';
import { hexColor } from '../../../../util/patterns';
import Checkbox from '../common/Checkbox';
import { DatePicker, TimePicker } from '@midasit/date';

const cx = classNames.bind(style);

export const PARTICIPATING = [
  { label: '참여기업', value: 'PARTICIPATING_COMPANY' },
  { label: '참여포지션', value: 'PARTICIPATING_POSITION' },
];

const OPEN_YN = [
  { label: '게시', value: 'true' },
  { label: '미게시', value: 'false' },
];

const Step1BasicInfo = () => {
  const { id } = useParams();

  const {
    matchingChallengeId,
    BASIC_INFO: {
      hashTag,
      name,
      limitApplyCount,
      participatingCompanyStatus,
      participatingCompaniesText,
      bannerTextColor,
      bannerBackgroundColor,
      periodOfAcceptanceStartDateTime,
      periodOfAcceptanceEndDateTime,
      status,
      setName,
      setHashTag,
      setLimitApplyCount,
      setParticipatingCompanyStatus,
      setParticipatingCompaniesText,
      setBannerBackgroundColor,
      setBannerTextColor,
      setPeriodOfAcceptanceStartDateTime,
      setPeriodOfAcceptanceEndDateTime,
    },
    VALIDATION: {
      bgValid,
      setBgValid,
      colorValid,
      setColorValid,
      startDateValid,
      setStartDateValid,
      endDateValid,
      setEndDateValid,
      setApplyLimitCount,
      applyLimitCount,
    },
  } = useContext(ChallengeContext);

  const { loadBasicInfo } = useBasicInfo();

  const [hash, setHash] = useState('');
  const [limitYn, setLimitYn] = useState(false);

  const { data, isSuccess } = useQuery(
    ['getBasicInfo1', matchingChallengeId, id],
    () => getBasicInfo(matchingChallengeId ? matchingChallengeId : Number(id)),
    {
      enabled: !!matchingChallengeId || !!id,
      cacheTime: 100,
    }
  );

  useEffect(() => {
    setStartDateValid(!!periodOfAcceptanceStartDateTime);
    setEndDateValid(!!periodOfAcceptanceEndDateTime);

    if (periodOfAcceptanceEndDateTime <= periodOfAcceptanceStartDateTime) {
      setEndDateValid(false);
    }
  }, [periodOfAcceptanceStartDateTime, periodOfAcceptanceEndDateTime]);

  //BasicInfo 불러오기
  useEffect(() => {
    if (isSuccess) {
      loadBasicInfo(data!.data);
    }
  }, [isSuccess]);

  return (
    <form className={cx('container')}>
      <h2 className={cx('title')}>기본정보</h2>
      {/* 챌린지명 */}
      <div className={cx('field')}>
        <FormContent title={'챌린지명'} require>
          <Input
            placeholder={'매칭챌린지명을 입력해주세요.'}
            onChange={(e) => setName(e.target.value)}
            maxLength={50}
            value={name}
          />
        </FormContent>
      </div>
      {/* 참여 기업 및 포지션 정보 */}
      <div className={cx('field')}>
        <FormContent title={'참여 기업 및 포지션 정보'} require>
          <div className={cx('info')}>
            <div className={cx('participatingCompanyStatus')}>
              <NewSelect
                size={'large'}
                options={PARTICIPATING.map(({ label, value }) => ({
                  label,
                  value,
                }))}
                onChange={(e) => {
                  setParticipatingCompanyStatus({
                    label: e!.label,
                    value: e!.value,
                  });
                }}
                value={participatingCompanyStatus}
              />
            </div>
            <Input
              placeholder={'챌린지에 참여하는 기업/포지션 정보를 입력해주세요.'}
              value={participatingCompaniesText}
              maxLength={100}
              onChange={(e) => setParticipatingCompaniesText(e.target.value)}
              block
            />
          </div>
        </FormContent>
      </div>
      {/* 해시태그 */}
      <div className={cx('field')}>
        <FormContent title={'해시태그'} require>
          <div className={cx('hash-tag')}>
            <div className={cx('top', hashTag.length && 'margin')}>
              <Input
                placeholder={'해시태그를 입력해주세요'}
                onChange={(e) => setHash(e.target.value)}
                maxLength={10}
                onKeyDown={(e) => {
                  if (e.key.trim() === 'Enter' && hash.length && hashTag.length < 10) {
                    setHashTag(
                      [...hashTag, { name: hash, order: hashTag.length - 1 }].map(({ name }, order) => ({
                        name,
                        order,
                      }))
                    );
                    setHash('');
                  }
                }}
                disabled={hashTag.length >= 10}
                value={hash}
              />
              <BlackButton
                onClick={() => {
                  if (hash.trim().length && hashTag.length < 10) {
                    setHashTag(
                      [...hashTag, { name: hash, order: hashTag.length - 1 }].map(({ name }, order) => ({
                        name,
                        order,
                      }))
                    );
                    setHash('');
                  }
                }}
                disabled={hashTag.length >= 10}
                size={'large'}
              >
                추가하기
              </BlackButton>
            </div>
            <ul className={cx('bottom')}>
              {hashTag.map(({ name, order }) => {
                return (
                  <li className={cx('keyword')} key={name + order}>
                    #{name}
                    <button
                      className={cx('remove-hash')}
                      type={'button'}
                      onClick={() =>
                        setHashTag(
                          hashTag.filter((hash) => hash.order !== order).map(({ name }, order) => ({ name, order }))
                        )
                      }
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </FormContent>
      </div>
      {/* 접수 기간 */}
      <div className={cx('field')}>
        <FormContent title={'접수 기간'} require>
          <>
            <div className={cx('time')}>
              <DatePicker
                date={periodOfAcceptanceStartDateTime}
                onChange={(date: Date) => setPeriodOfAcceptanceStartDateTime(date)}
                disabled={status === 'START'}
                customInput={<Input className={cx('picker')} />}
                minDate={new Date()}
              />
              <TimePicker
                date={periodOfAcceptanceStartDateTime}
                onChange={(date: Date) => setPeriodOfAcceptanceStartDateTime(date)}
                disabled={status === 'START'}
                customInput={<Input className={cx('picker')} />}
                minDate={new Date()}
              />

              <span className={cx('hyphen')} />
              <DatePicker
                date={periodOfAcceptanceEndDateTime}
                onChange={(date: Date) => setPeriodOfAcceptanceEndDateTime(date)}
                disabled={status === 'START'}
                customInput={<Input className={cx('picker')} />}
                minDate={periodOfAcceptanceStartDateTime}
              />
              <TimePicker
                date={periodOfAcceptanceEndDateTime}
                onChange={(date: Date) => setPeriodOfAcceptanceEndDateTime(date)}
                disabled={status === 'START'}
                customInput={<Input className={cx('picker')} />}
                minDate={periodOfAcceptanceStartDateTime}
              />
            </div>
          </>
        </FormContent>
        {status === 'START' && <span className={cx('error')}>챌린지 진행중에는 접수기간을 수정할 수 없습니다.</span>}
        {(!endDateValid || !startDateValid) && <span className={cx('error')}>접수기간을 확인해주세요.</span>}
      </div>

      {/* 복수지원 가능 포지션 개수 */}
      <div className={cx('field')}>
        <FormContent title={'복수지원 가능 포지션 개수'} require>
          <div className={cx('openYn', (limitYn || limitApplyCount === 9999) && 'limit')}>
            <Input
              type={'number'}
              maxLength={2}
              value={limitYn || limitApplyCount === 9999 ? '제한없음' : limitApplyCount}
              onChange={(e) => {
                setLimitApplyCount(Number(e.target.value));
                setApplyLimitCount(1 <= Number(e.target.value) && Number(e.target.value) <= 20);
              }}
              disabled={limitYn || limitApplyCount === 9999}
            />
            <Checkbox
              className={cx('limitCheck')}
              id={'limitApplyCount'}
              checked={limitYn || limitApplyCount === 9999}
              onChange={(e) => {
                setLimitYn(e.target.checked);
                setLimitApplyCount(e.target.checked ? 9999 : 1);
                e.target.checked && setApplyLimitCount(true);
              }}
            />
            <label className={cx('limitYn')} htmlFor={'limitApplyCount'}>
              제한없음
            </label>
          </div>
        </FormContent>
        {!applyLimitCount && <span className={cx('error')}>최소 1개 최대 20개로 설정해주세요.</span>}
      </div>
      {/* 배너 이미지 */}
      <div className={cx('field')}>
        <FormContent title={'배너 이미지'} require>
          <div className={cx('banner-img')}>
            <p className={cx('desc')}>
              채용 공고에 노출될 메인 이미지를 선택해주세요 (제한 50MB)
              <br /> (확장자 .gif, .jpg, .jpeg, .png)
            </p>
            <Picture />
          </div>
        </FormContent>
      </div>
      <div className={cx('field')}>
        <div className={cx('color-collection')}>
          {/* 배경 색상 */}
          <div className={cx('half')}>
            <FormContent title={'배너 배경 색상'} require>
              <div className={cx('openYn')}>
                <div className={cx('picker-wrapper')}>
                  <Input
                    value={bannerBackgroundColor}
                    onChange={(e) => {
                      setBgValid(hexColor(e.target.value));
                      setBannerBackgroundColor(e.target.value);
                    }}
                    maxLength={7}
                    placeholder={'#ffffff'}
                  />
                  <div className={cx('preview-color')} style={{ background: bannerBackgroundColor }} />
                </div>
              </div>
            </FormContent>
            {!bgValid && <span className={cx('error')}>배경색상 형식을 확인해주세요.</span>}
          </div>
          {/* 텍스트 색상 */}
          <div className={cx('half')}>
            <FormContent title={'배너 텍스트 색상'} require>
              <div className={cx('openYn')}>
                <div className={cx('picker-wrapper')}>
                  <Input
                    value={bannerTextColor}
                    onChange={(e) => {
                      setColorValid(hexColor(e.target.value));
                      setBannerTextColor(e.target.value);
                    }}
                    maxLength={7}
                    placeholder={'#ffffff'}
                  />
                  <div className={cx('preview-color')} style={{ background: bannerTextColor }} />
                </div>
              </div>
            </FormContent>
            {!colorValid && <span className={cx('error')}>텍스트색상 형식을 확인해주세요.</span>}
          </div>
        </div>
      </div>
    </form>
  );
};
export default Step1BasicInfo;
