import React, { Suspense } from 'react';
import classNames from 'classnames/bind';

import style from './ContractListPage.module.scss';
import Layout from '../../../components/common/Layout/Layout';
import Spinner from '../../../components/common/spinner/Spinner';
import ContractListV1 from '../../../components/contract/list/ContractListV1';
import ContractListTab from '../../../components/contract/list/ContractListTab';
import ContractListToolbar from '../../../components/contract/list/ContractListToolbar';

const cx = classNames.bind(style);

const ContractListPage = () => {
  return (
    <Layout>
      <div className={cx('container')}>
        <h1 className={cx('title')}>계약 목록</h1>
        <Suspense fallback={<Spinner />}>
          <ContractListTab />
          <ContractListToolbar />
          <ContractListV1 />
        </Suspense>
      </div>
    </Layout>
  );
};
export default ContractListPage;
