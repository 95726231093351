import { atom } from 'recoil'

export interface SessionVO {
  token: string
  expiredDateTime: string
}

export interface LoginVO extends SessionVO {
  passwordExpiryDate: string
}

export const sessionAtom = atom<SessionVO | null>({
  key: 'sessionAtom',
  default: null,
})

export const passwordExpiryDateAtom = atom<string | null>({
  key: 'passwordExpiryDateAtom',
  default: null,
})
