export function toSearchParams(params: any) {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value instanceof Array) {
      value.forEach(v => searchParams.set(key, v));
      return;
    }
    value && searchParams.set(key, String(value));
  })

  return searchParams.toString() && `?${searchParams}`
}