import { AccountDto } from '../../../api/dto/AcountDto';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface Actions {
  setData: (data: AccountDto) => void;
}

interface IAccountStore {
  actions: Actions;
  data: AccountDto;
}

const initialData: AccountDto = {
  roleType: null, // roleType이 null이면 아직 로딩되지 않은 것
  birthDate: '',
  email: '',
  gender: '',
  name: '',
  phone: '',
};

export const useAccountStore = create<IAccountStore>()(
  immer((set) => ({
    data: initialData,
    actions: {
      setData: (data) =>
        set((state) => {
          state.data = data;
        }),
    },
  }))
);
