// Created by kdw0601 on 2022-06-02
import styles from './ButtonCommon.module.scss'
import classNames from 'classnames/bind'
import React, { HTMLAttributes } from 'react'

const cx = classNames.bind(styles)

export interface ButtonCommonProps extends HTMLAttributes<HTMLButtonElement>{
  size?: 'small' | 'middle' | 'large';
  type?: 'primary' | 'secondary' | 'red-line' | 'gray-line' | 'red' | 'black';
  wide?: boolean;
  submitType?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

const ButtonCommon = ({className, size, type, wide, submitType, disabled, loading, children, ...props}: ButtonCommonProps) => {


  return (
    <button type={submitType ? 'submit' : 'button'} disabled={disabled}
            className={cx('button', size, type, wide && 'wide', className)} {...props}>
      {loading && <div className={cx('loader')}/>}
      {children}
    </button>
  )
}

export default ButtonCommon