import { DependencyList, useEffect, useState } from 'react'

const useDebouncedEffect = (effect: () => void, timeout: number, deps?: DependencyList) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      return;
    }
    const timeoutId = setTimeout(effect, timeout);

    return () => {
      clearTimeout(timeoutId);
    }
  }, deps)
}

export default useDebouncedEffect;