import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import IntroPage from './pages/intro/IntroPage';
import HomePage from './pages/home/HomePage';
import './index.scss';
import TenantPage from './pages/tenant/list/TenantPage';
import TenantDetailPage from './pages/tenant/detail/TenantDetailPage';
import Error404 from './pages/404/Error404';
import ChallengePage from './pages/challenge/list/ChallengePage';
import ChallengeDetailPage from './pages/challenge/detail/ChallengeDetailPage';
import ContractListPage from './pages/contract/list/ContractListPage';
import ContractDetailPage from './pages/contract/detail/ContractDetailPage';
import ProductListPage from './pages/product/list/ProductListPage';
import ProductDetailPage from './pages/product/detail/ProductDetailPage';
import UsagePage from './pages/statistics/usage/UsagePage';
import SettlesPage from './pages/statistics/settles/SettlesPage';
import useSessionRefresh from './hooks/useSessionRefresh';
import ResetPasswordPage from './pages/account/reset-password/ResetPasswordPage';
import TenantContractMigrationPage from './pages/temp/TenantContractMigrationPage';
import MatchPage from './pages/statistics/match/MatchPage';
import { withRedirectUnauthorized } from './feature/authority/utils/withAuthority';
import { useFetchAccountInfo } from './hooks/useFetchAccountInfo';
import PopupListPage from './pages/popup/PopupListPage';
import PopupDetailPage from './pages/popup/PopupDetailPage';
import SettingsMatchPage from './pages/settings/SettingsMatchPage'

const NewProductPage = withRedirectUnauthorized('createEditUseProduct', ProductDetailPage, '/product/list');
const NewContractPage = withRedirectUnauthorized('createContract', ContractDetailPage, '/contract/list');

function App() {
  useSessionRefresh();
  useFetchAccountInfo();

  return (
    <Suspense>
      <Routes>
        <Route path={'/'} element={<HomePage />} />
        <Route path={'/login'} element={<IntroPage />} />

        {/* 사용자계정 관리 */}
        <Route path={'/account/reset-password'} element={<ResetPasswordPage />} />

        {/* 기업계정 관리 */}
        <Route path={'/tenant/list'} element={<TenantPage />} />
        <Route path={'/tenant/contract-migration'} element={<TenantContractMigrationPage />} />
        <Route path={'/tenant/detail/:id'} element={<TenantDetailPage />} />

        {/* 매칭챌린지 관리 */}
        <Route path={'/challenge/list'} element={<ChallengePage />} />
        <Route path={'/challenge/detail/:id'} element={<ChallengeDetailPage />} />

        {/* 상품관리 */}
        <Route path={'/product/list'} element={<ProductListPage />} />
        <Route path={'/product/new'} element={<NewProductPage />} />
        <Route path={'/product/:id'} element={<ProductDetailPage />} />

        {/*계약관리*/}
        <Route path={'/contract/list'} element={<ContractListPage />} />
        <Route path={'/contract/new'} element={<NewContractPage />} />
        <Route path={'/contract/:id'} element={<ContractDetailPage />} />

        {/*조회 및 통계*/}
        <Route path={'/statistics/usage'} element={<UsagePage />} />
        <Route path={'/statistics/settles'} element={<SettlesPage />} />
        <Route path={'/statistics/match'} element={<MatchPage />} />

        {/* 팝업 관리 */}
        <Route path={'/popup/list'} element={<PopupListPage />} />
        <Route path={'/popup/:id'} element={<PopupDetailPage />} />
        <Route path={'/popup/new'} element={<PopupDetailPage />} />

        {/* 매칭 직무 관리 */}
        <Route path={'/settings/match'} element={<SettingsMatchPage />} />


        <Route path={'*'} element={<Error404 />} />
      </Routes>
    </Suspense>
  );
}

export default App;
