import { getCookie } from './cookie';
import FetchController from './fetchController';

const DEVELOP_URL = 'https://ats-bo.ats.kr-dv-jainwon.com';
const STAGING_URL = 'https://ats-bo.ats.kr-st-jainwon.com';
const STAGING2_URL = 'https://st2-ats-bo.ats.kr-st-jainwon.com';
const PRODUCTION_URL = 'https://ats-bo.ats.kr-pr-jainwon.com';

export const METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const getATSBaseUrl = (): string => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return 'https://api.match.jobda.im';
    case 'staging2':
      return 'https://st2-api.ats.kr-st-jainwon.com';
    case 'staging':
      return 'https://api.ats.kr-st-jainwon.com';
    default:
      return 'https://api.ats.kr-dv-jainwon.com';
  }
};

export const getBaseUrl = (): string => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return PRODUCTION_URL;
    case 'staging':
      return STAGING_URL;
    case 'staging2':
      return STAGING2_URL;
    default:
      return DEVELOP_URL;
  }
};

export const customAxios = FetchController.axiosInstance;

const goToLoginWithRedirectUri = () => {
  location.href = `/login?redirect=${encodeURIComponent(location.pathname)}`;
};

customAxios.interceptors.response.use(undefined, (e) => {
  const errorMessages: string[] = [e.response?.data.data.cause, e.response.data.message].filter(
    (v) => typeof v === 'string'
  );

  const parsedErrorCodes: string[] = [e.response?.data.data.code, e.response?.data.code].filter(
    (v) => typeof v === 'string'
  );
  const gotoLoginErrorCodes: string[] = ['UnavailableTokenException', 'IllegalArgumentException'];

  if (errorMessages.find((v) => v.includes('로그인 정보'))) {
    goToLoginWithRedirectUri();
  }

  throw e;
});

const formFetch = async <T>(method: string, path: string, formData: FormData) => {
  const init: {
    method: string;
    headers: { token?: string };
    body?: BodyInit;
  } = {
    method: method,
    headers: {},
    body: formData,
  };

  const token = getCookie('token');
  if (token) init.headers.token = token;

  const rs = await fetch(getBaseUrl() + path, init);
  const json = await rs.json();

  if (!rs.ok) throw json;
  return json as Promise<T>;
};

export const get = <T>(path: string): Promise<{ data: T }> => {
  return FetchController.sendFetchRequest('get', path);
};

export const post = <T>(path: string, data?: unknown): Promise<{ data: T }> => {
  return FetchController.sendFetchRequest('post', path, data);
};

export const remove = <T>(path: string, data?: unknown): Promise<{ data: T }> => {
  return FetchController.sendFetchRequest('delete', path, data);
};

export const put = <T>(path: string, data?: unknown): Promise<{ data: T }> => {
  return FetchController.sendFetchRequest('put', path, data);
};

//TODO: 정리해야함
export const postForm = async <T>(path: string, formData: FormData): Promise<T> => {
  return await formFetch<T>(METHOD.POST, path, formData);
};

export const postFileDownload = (path: string, fileName: string, body?: any) => {
  const token = getCookie('token');

  customAxios
    .post(
      path,
      body,
      token
        ? {
            headers: { Authorization: `Bearer ${token}` },
            responseType: 'blob',
          }
        : {}
    )
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
};

export const getFiledownloadXlsx = (path: string, fileName: string) => {
  const token = getCookie('token');
  customAxios
    .get(
      path,
      token
        ? {
            headers: { Authorization: `Bearer ${token}` },
            responseType: 'blob',
          }
        : {}
    )
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
};

export const getFileDownload = (path: string, fileName?: string) => {
  const token = getCookie('token');
  customAxios
    .get(
      path,
      token
        ? {
            headers: { Authorization: `Bearer ${token}` },
            responseType: 'blob',
          }
        : {}
    )
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName ?? '파일'}`);
      document.body.appendChild(link);
      link.click();
    });
};
