import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import useAuth from './useAuth'

const useSessionRefresh = () => {
  const location = useLocation()
  const { refreshSessionWithCoolDown } = useAuth()

  const ignorePathnames = ['/', '/login']
  useEffect(() => {
    if (ignorePathnames.includes(location.pathname)) return
    refreshSessionWithCoolDown()
  }, [location])
}

export default useSessionRefresh
