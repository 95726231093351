import React from 'react'
import classNames from 'classnames/bind'

import style from './Error404.module.scss'

const cx = classNames.bind(style)

const Error404 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('content')}>
        <strong>404</strong>
        <span>페이지를 찾을수 없습니다.</span>
      </div>
    </div>
  )
}

export default Error404
