import React, { useCallback } from 'react';
import classNames from 'classnames/bind';

import styles from '../Pagination.module.scss';
import { Props } from '../PaginationV2';
import { SingleValue } from 'react-select';
import { usePaginationSearchParams } from './usePaginationSearchParams';

const cx = classNames.bind(styles);

const usePaginationEventV2 = ({ page: { totalPages, totalCount } }: Props) => {
  const { setPage, setSize, page, size } = usePaginationSearchParams();

  const possiblePrev = useCallback(() => {
    return page !== 1;
  }, [totalPages, totalCount, size, page]);

  const possibleNext = useCallback(() => {
    return page !== totalPages;
  }, [totalPages, totalCount, size, page]);

  const drawPage = useCallback(() => {
    let pageList: any[] = [];
    const minScope = page <= 2 ? 1 : page + 2 > totalPages ? (totalPages - 5 < 1 ? 1 : totalPages - 4) : page - 2;
    const maxScope = page <= 2 ? (totalPages < 5 ? totalPages : 5) : page + 2 > totalPages ? totalPages : page + 2;

    for (let i = minScope; i <= maxScope; i++) {
      pageList = [
        ...pageList,
        <li key={i} className={cx(i === page && 'active')} onClick={() => setPage(i)}>
          {i}
        </li>,
      ];
    }
    return pageList;
  }, [totalPages, totalCount, size, page]);

  const next = () => setPage(page + 1);

  const prev = () => setPage(page - 1);

  const changeSize = useCallback(
    (val: SingleValue<{ value: string }>) => {
      if (!val) return;

      setSize(Number(val.value));
    },
    [totalPages, totalCount, size, page]
  );

  return {
    next,
    prev,
    possibleNext,
    possiblePrev,
    changeSize,
    drawPage,
  };
};

export default usePaginationEventV2;
