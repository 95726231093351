// Created by kdw0601 on 2022-10-19
import styles from './MonthPicker.module.scss'
import classNames from 'classnames/bind'
import InputText from '../input/InputText'
import React, { useEffect, useState } from 'react'
import Icon from '../icon/Icon'

const cx = classNames.bind(styles)

interface Props {
  value: Date;
  disabled?: boolean;
  onChange?: (newDate: Date) => void;
}

function formatDate(date: Date) {
  if (isNaN(date.getTime())) {
    return '';
  }

  return date.toISOString().substring(0, 7).replace('-', '.');
}

const TIMEZONE_OFFSET = new Date().getTimezoneOffset() * 60000;

const MonthPicker = ({value,onChange, disabled}: Props) => {
  const [innerDate, setInnerDate] = useState(value);
  const [innerText, setInnerText] = useState(formatDate(innerDate));

  useEffect(() => {
    setInnerDate(value);
    setInnerText(formatDate(value))
  }, [value])

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInnerText(e.currentTarget.value);
  }

  const handleChangeDate = () => {
    const newDate = new Date(new Date(innerText).getTime() - TIMEZONE_OFFSET);
    if (isNaN(newDate.getTime()) || newDate.toISOString().startsWith('+')) {
      setInnerText(formatDate(innerDate));
      return;
    }

    setInnerDate(newDate);
    setInnerText(formatDate(newDate));
    onChange && onChange(newDate);
  }

  return (
    <div className={cx('wrap')}>
      <Icon className={cx('icon')} type={'iconEvent24Line'}/>
      <InputText className={cx('input')} value={innerText} readOnly onChange={handleChangeText} onEnter={handleChangeDate} onBlur={handleChangeDate} disabled={disabled}/>
    </div>
  )
}

export default MonthPicker