import React, { FC } from 'react';
import classNames from 'classnames/bind';
import style from './Tab.module.scss';

const cx = classNames.bind(style);

interface Props {
  tab: string;
  setTab: (v: string) => void;
  list: TabItem[];
  callback?: () => void;
}

export type TabItem = { name: string; key: string; option?: { disabled: boolean } };

const Tab: FC<Props> = ({ list, tab, setTab, callback }) => {
  return (
    <ul className={cx('tab')}>
      {list.map(({ key, name, option }) => (
        <li
          className={cx(key === tab && 'active', option?.disabled && 'disabled')}
          key={key}
          onClick={() => {
            !option?.disabled && setTab(key);
            callback && callback();
          }}
        >
          {name}
        </li>
      ))}
    </ul>
  );
};

export default Tab;
