import React from 'react'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames/bind'
import {
  BaseEditor,
  createEditor,
  Descendant,
  Transforms,
  Editor,
  Element as SlateElement,
  Range,
} from 'slate'
import {
  Slate,
  Editable,
  withReact,
  ReactEditor,
  useSlate,
  RenderLeafProps,
} from 'slate-react'
import { RenderElementProps } from 'slate-react/dist/components/editable'
import { withHistory } from 'slate-history'

import styles from './SlateEditor.module.scss'

import ListPopover from '../popover/ListPopover'
import Popover from '../popover/Popover'
import Portal from '../portal/Portal'
import EditorIcon from './EditorIcon'
import { fileUpload } from '../../../repositories/common/FileRepository'
import { getATSBaseUrl } from '../../../util/fetch'

const cx = classNames.bind(styles)

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor
    Element: CustomElement
    Text: CustomText
  }
}

type BlockFormatType =
  | 'title'
  | 'sub-title'
  | 'bulleted-list'
  | 'numbered-list'
  | 'list-item'
  | 'paragraph'
  | 'image'

export type CustomElement = {
  type: BlockFormatType
  children: CustomElement[] | CustomText[]
  label?: string
  url?: string
}

type MarkFormatType = 'bold' | 'underline' | 'highlight' | 'link'

export type CustomText = {
  text: string
  bold?: boolean
  highlight?: boolean
  underline?: boolean
  link?: string
  label?: string
  url?: string
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

type Props = {
  value: Descendant[]
  onChange: (value: Descendant[]) => void
  placeholder?: string
}

const SlateEditor: FC<Props> = ({ value, onChange, placeholder }) => {
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])
  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  )
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  )

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={(newValue) => onChange(newValue)}
    >
      <div className={cx('toolbar')}>
        <TextTypeBlockButton />
        <MarkButton format="bold" />
        <MarkButton format="underline" />
        <BlockButton format="numbered-list" />
        <BlockButton format="bulleted-list" />
        <MarkButton format="highlight" />
        <HyperLinkMarkButton />
        <ImageButton />
      </div>
      <Editable
        onKeyDown={(e) => {
          if (e.key === 'Enter' && isImageActive(editor)) {
            e.preventDefault()
            editor.insertText('\n')
          }
          if (e.shiftKey && e.key === 'Enter') {
            e.preventDefault()
            editor.insertText('\n')
          }
        }}
        renderPlaceholder={({ children, attributes }) => (
          <span {...attributes} className={cx('placeholder')}>
            {children}
          </span>
        )}
        placeholder={placeholder}
        className={cx('editor')}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
      />
      <HoveringToolbar />
    </Slate>
  )
}

const Element = ({ attributes, children, element }: RenderElementProps) => {
  switch (element.type) {
    case 'title':
      return <h1 {...attributes}>{children}</h1>
    case 'sub-title':
      return <h2 {...attributes}>{children}</h2>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'list-item':
      return (
        <li {...attributes}>
          <div>{children}</div>
        </li>
      )
    case 'image':
      return (
        <div {...attributes}>
          <div className={cx('image-block')}>
            <img
              src={element.url}
              alt={element.label}
              style={{ display: 'block' }}
            />
            {children}
          </div>
        </div>
      )
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <b>{children}</b>
  }

  if (leaf.highlight) {
    children = <strong>{children}</strong>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.link) {
    children = (
      <a onClick={() => window.open(leaf.link, '_blank')}>{children}</a>
    )
  }

  return <span {...attributes}>{children}</span>
}

const TextTypeBlockButton = () => {
  const editor = useSlate()
  const [showTypePopover, setShowTypePopover] = useState(false)
  const isActiveTitle = isBlockActive(editor, 'title')
  const isActiveSubTitle = isBlockActive(editor, 'sub-title')
  const isActiveParagraph = isBlockActive(editor, 'paragraph')
  return (
    <ListPopover
      isOpen={showTypePopover}
      close={() => setShowTypePopover(false)}
      className={cx('text-type-popover')}
      content={
        <ul>
          <li
            className={cx('a1')}
            onMouseDown={(event) => {
              event.preventDefault()
              toggleBlock(editor, 'title')
              setShowTypePopover(false)
            }}
          >
            A1
          </li>
          {/*           Deprecated option*/}
          {/*          <li
            className={cx('a2')}
            onMouseDown={(event) => {
              event.preventDefault();
              toggleBlock(editor, 'sub-title');
              setShowTypePopover(false);
            }}
          >
            A2
          </li>*/}
          <li
            className={cx('a3')}
            onMouseDown={(event) => {
              event.preventDefault()
              toggleBlock(editor, 'paragraph')
              setShowTypePopover(false)
            }}
          >
            A2
          </li>
        </ul>
      }
    >
      <button
        className={cx('button')}
        onClick={() => setShowTypePopover((v) => !v)}
      >
        {isActiveTitle ? (
          <EditorIcon name={'text-type-a1'} />
        ) : isActiveSubTitle ? (
          <EditorIcon name={'text-type-a2'} />
        ) : isActiveParagraph ? (
          <EditorIcon name={'text-type-a2'} /> /* Deprecated */
        ) : (
          <EditorIcon name={'text-type'} />
        )}
        <span className={cx('down-arrow')} />
      </button>
    </ListPopover>
  )
}

const HyperLinkMarkButton = () => {
  const editor = useSlate()
  const isActive = isMarkActive(editor, 'link')
  const [showPopover, setShowPopover] = useState(false)
  const [url, setUrl] = useState('')
  const [isValidUrl, setIsValidUrl] = useState(false)

  useEffect(() => {
    setIsValidUrl(!!url)
  }, [url])

  return (
    <Popover
      isOpen={showPopover}
      close={() => {
        setShowPopover(false)
        setUrl('')
      }}
      align={'center'}
      className={cx('hyperlink-popover')}
      content={
        <div className={cx('wrapper')}>
          <input
            placeholder={'URL을 입력하세요.'}
            className={cx('input')}
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && isValidUrl) {
                Editor.addMark(editor, 'link', url)
                setShowPopover(false)
                setUrl('')
              }
            }}
          />
          {/*<Icon*/}
          {/*  name={'check_line'}*/}
          {/*  size={24}*/}
          {/*  className={cx('icon-button', isValidUrl && 'active')}*/}
          {/*  onClick={() => {*/}
          {/*    if (!isValidUrl) return*/}
          {/*    Editor.addMark(editor, 'link', url)*/}
          {/*    setShowPopover(false)*/}
          {/*    setUrl('')*/}
          {/*  }}*/}
          {/*/>*/}
        </div>
      }
    >
      <button
        className={cx('button', isActive && 'active')}
        onClick={() => {
          if (isActive) Editor.removeMark(editor, 'link')
          else setShowPopover((v) => !v)
        }}
      >
        <EditorIcon name={'link'} />
      </button>
    </Popover>
  )
}

const FILE_MAX_SIZE = 52428800
const ImageButton = () => {
  const fileInput = useRef<HTMLInputElement>(null)
  const editor = useSlate()

  const validation = ({ name, size }: { name: string; size: number }) => {
    if (name !== 'gif' && name !== 'jpg' && name !== 'jpeg' && name !== 'png') {
      return false
    }
    if (FILE_MAX_SIZE < size) {
      return false
    }
    return true
  }

  return (
    <button
      className={cx('button')}
      onClick={() => fileInput.current && fileInput.current.click()}
    >
      <input
        className={cx('file-input')}
        type="file"
        accept=".gif, .jpg, .jpeg, .png"
        ref={fileInput}
        onClick={() => {
          if (fileInput.current) fileInput.current.value = ''
        }}
        onChange={async (e) => {
          if (e.target.files) {
            const { name, size } = e.target.files[0]
            const split = name.split('.')
            const exc = split[split.length - 1].toLowerCase()

            if (validation({ name: exc, size })) {
              const formData = new FormData()
              formData.append('file', e.target.files[0], name)
              const { fileName, fileUid } = await fileUpload(formData)

              ImageBlock(editor, { fileUid, fileName })
            }
          }
        }}
      />
      <EditorIcon name={'image'} />
    </button>
  )
}

const ImageBlock = (
  editor: BaseEditor & ReactEditor,
  { fileUid, fileName }: { fileName: string; fileUid: string }
) => {
  ReactEditor.focus(editor)
  Transforms.setNodes(editor, {
    type: 'image',
    url: `${getATSBaseUrl()}/api/common/file/view/${fileUid}`,
    label: fileName,
    children: [],
  })
  Transforms.insertNodes(editor, {
    type: 'paragraph',
    children: [{ text: '' }],
  })
}

const BlockButton = ({
  format,
}: {
  format: 'bulleted-list' | 'numbered-list'
}) => {
  const editor = useSlate()
  return (
    <button
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
      className={cx('button', isBlockActive(editor, format) && 'active')}
    >
      <EditorIcon name={format} />
    </button>
  )
}

const MarkButton = ({
  format,
}: {
  format: 'bold' | 'underline' | 'highlight'
}) => {
  const editor = useSlate()
  return (
    <button
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
      className={cx('button', isMarkActive(editor, format) && 'active')}
    >
      <EditorIcon name={format} />
    </button>
  )
}

const HoveringToolbar = () => {
  const ref = useRef<HTMLDivElement>(null)
  const editor = useSlate()

  useEffect(() => {
    const el = ref.current
    const { selection } = editor

    if (!el) {
      return
    }

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style')
      return
    }

    const domSelection = window.getSelection()
    if (domSelection) {
      const domRange = domSelection.getRangeAt(0)
      const rect = domRange.getBoundingClientRect()
      el.style.opacity = '1'
      el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight - 10}px`
      el.style.left = `${rect.left}px`
    }
  })

  return (
    <Portal domId={'tooltip-root'}>
      <div ref={ref} className={cx('hovering-toolbar', 'toolbar')}>
        <TextTypeBlockButton />
        <MarkButton format="bold" />
        <MarkButton format="underline" />
        <BlockButton format="numbered-list" />
        <BlockButton format="bulleted-list" />
        <MarkButton format="highlight" />
        <HyperLinkMarkButton />
      </div>
    </Portal>
  )
}

const toggleBlock = (
  editor: BaseEditor & ReactEditor,
  format: BlockFormatType
) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  })
  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (
  editor: BaseEditor & ReactEditor,
  format: 'bold' | 'underline' | 'highlight'
) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (
  editor: BaseEditor & ReactEditor,
  format: BlockFormatType
) => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  })

  return !!match
}

const isMarkActive = (
  editor: BaseEditor & ReactEditor,
  format: MarkFormatType
) => {
  const marks = Editor.marks(editor)
  return marks ? !!marks[format] : false
}

const isImageActive = (editor: BaseEditor & ReactEditor) => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'image',
  })

  return !!match
}

export default SlateEditor
