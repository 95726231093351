import React, { FC, useEffect } from 'react';
import classNames from 'classnames/bind';

import style from './TenantDetail.module.scss';
import Tab from '../../common/tab/Tab';
import TenantDetailInfo from './TenantDetailInfo';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { companyInfoDetail } from '../../../repositories/company/detail/CompanyInfoDetailRepository';
import Spinner from '../../common/spinner/Spinner';
import ContractListV1 from '../../contract/list/ContractListV1';
import { useSearchParamState } from '../../../hooks/useSearchParamState';
import { useTenantDetailStore } from './store/useTenantDetailStore';

const cx = classNames.bind(style);

const TenantDetail: FC = () => {
  const [tab, setTab] = useSearchParamState('tab', 'info');
  const setData = useTenantDetailStore((state) => state.actions.setData);
  const { id } = useParams();

  const { data, isFetching } = useQuery('companyInfoDetail', () => companyInfoDetail(id || '').then((v) => v.data), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      setData(data);
    }

    return () => {
      setData(useTenantDetailStore.getInitialState().data);
    };
  }, [data]);

  if (isFetching) return <Spinner />;

  return (
    <div className={cx('container')}>
      <h1 className={cx('title')}>기업 계정 정보</h1>
      <div className={cx('tab')}>
        <Tab
          tab={tab}
          setTab={(v) => setTab(v as 'info' | 'contract' | 'matching')}
          list={[
            { key: 'info', name: '기업정보' },
            { key: 'contract', name: '계약정보' },
            { key: 'matching', name: '매칭정보', option: { disabled: true } },
          ]}
        />
      </div>
      {tab === 'info' && data && <TenantDetailInfo data={data} />}
      {tab === 'contract' && <ContractListV1 businessNumber={data?.businessNumber} />}
    </div>
  );
};

export default TenantDetail;
