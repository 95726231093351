import { get, put } from '../../../util/fetch';
import { GetJobGroupAndJobListRs } from './rs';

export const getJobGroupAndJobList = (jobGroupCodeList: string[] = []) => {
  return get<GetJobGroupAndJobListRs>(`/api/v1/job-group?jobGroupCodeList=${jobGroupCodeList.join(',')}`);
};

export const putJobMatchable = (notMatchableJobCodeList: string[]) => {
  return put(`/api/v1/job/matchable`, { notMatchableJobCodeList });
};
