// Created by kdw0601 on 2022-05-30
import React from 'react';
import classNames from 'classnames/bind';
import styles from './ProductItemRow.module.scss';
import InputNumber from '../../common/input/InputNumber';
import { useProductDetailStore } from './store/useProductDetailStore';

const cx = classNames.bind(styles);

type RowType = [key: string, name: string, value: number | null, setValue: (val: number | null) => void];

interface Props {
  editable?: boolean;
}

const ProductDetailV1EtcRow = ({ editable }: Props) => {
  const { matchingPrice, promotionPrice, etcPrice } = useProductDetailStore((v) => v.data);
  const { setMatchingPrice, setPromotionPrice, setEtcPrice } = useProductDetailStore((v) => v.actions);
  const errors = useProductDetailStore((v) => v.errors);
  const locked = useProductDetailStore((state) => state.data.locked);

  const rows: RowType[] = [
    ['matchingPrice', '매칭', matchingPrice, setMatchingPrice],
    ['promotionPrice', '홍보', promotionPrice, setPromotionPrice],
    ['etcPrice', '기타', etcPrice, setEtcPrice],
  ];

  return (
    <>
      <tr style={{ borderTop: '1px solid #eee' }}>
        <td colSpan={4}>기타</td>
      </tr>
      {rows.map(([key, name, value, setValue]) => (
        <tr key={key}>
          <td style={{ paddingLeft: 40 }}>{name}</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>
            <InputNumber
              placeholder={'금액을 입력하세요.'}
              min={0}
              value={value ?? ''}
              className={cx('price')}
              onChange={(v) => setValue(isNaN(v) ? null : v)}
              error={errors[key] && ' '}
              disabled={locked && !editable}
            />
          </td>
          <td>
            <InputNumber
              placeholder={'금액을 입력하세요'}
              min={1}
              value={(value ?? 0).toLocaleString()}
              className={cx('price', 'align-right')}
              disabled
            />
          </td>
        </tr>
      ))}
    </>
  );
};

export default ProductDetailV1EtcRow;
