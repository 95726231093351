import React, { useContext } from 'react'
import classNames from 'classnames/bind'
import style from './Step3ParticipationList.module.scss'
import { ChallengeContext } from '../../../../pages/challenge/list/ChallengeContext'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd'
import { useQuery } from 'react-query'
import { getParticipationList } from '../../../../repositories/challenge/detail/CreateChallengeRepository'
import { ReactComponent as HandlerIcon } from '../../../../asset/icon/icon_drag_handler_24_line.svg'
import useParticipation from './useParticipation'
import { RECRUITMENT_TYPE } from '../../ChallengeDefinition'

const cx = classNames.bind(style)

const Step3ParticipationList = () => {
  const {
    matchingChallengeId,
    WORKING_AREA,
    SELECT_POSITION: { revealPositions, setRevealPositions },
  } = useContext(ChallengeContext)

  const { convertJobGroupCode, modifyOrdering } = useParticipation()

  useQuery(
    'getParticipationList',
    async () => {
      const rs = await getParticipationList(matchingChallengeId!)
      setRevealPositions(
        rs?.data.map((content) => ({ ...content, modify: false })) ?? []
      )
    },
    { enabled: !!matchingChallengeId }
  )

  return (
    <div className={cx('container')}>
      <h1 className={cx('title')}>참여 포지션 목록</h1>
      {revealPositions.length ? (
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
        // @ts-ignore
        <DragDropContext
          onDragEnd={async (result) => {
            if (!result.destination) return

            const items = [...revealPositions]
            const [reorderedItem] = items.splice(result.source.index, 1)
            items.splice(result.destination.index, 0, reorderedItem)

            setRevealPositions(items)

            await modifyOrdering(items)
          }}
        >
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/*// @ts-ignore*/}
          <Droppable droppableId={'position-revealList'}>
            {(provided: DroppableProvided) => (
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
              // @ts-ignore
              <ul
                className={cx('position-list')}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {revealPositions.map(
                  (
                    {
                      revealPositionId,
                      positionName,
                      companyName,
                      workingAreaCode,
                      jobGroupCode,
                      recruitmentClassification,
                      minAnnualIncome,
                      maxAnnualIncome,
                    },
                    idx
                  ) => (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <Draggable
                      key={`drag-${revealPositionId}`}
                      draggableId={`drag-${revealPositionId}`}
                      index={idx}
                    >
                      {(provided) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          className={cx('position')}
                        >
                          <div className={cx('left')}>
                            <HandlerIcon />
                            <strong className={cx('name')}>
                              {positionName}
                            </strong>
                          </div>
                          <div className={cx('right')}>
                            <span>{companyName}</span>
                            <span>
                              {WORKING_AREA.filter(
                                ({ code }) => Number(code) === workingAreaCode
                              )[0]?.name ?? ''}
                            </span>
                            <span>{convertJobGroupCode(jobGroupCode)}</span>
                            <span>
                              {RECRUITMENT_TYPE[recruitmentClassification]}
                            </span>
                            {!!minAnnualIncome && (
                              <span>
                                {minAnnualIncome
                                  .toString()
                                  .replace(
                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                    ','
                                  )}{' '}
                                만원 이상
                              </span>
                            )}
                          </div>
                        </li>
                      )}
                    </Draggable>
                  )
                )}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className={cx('empty')}>
          <i className={cx('img')} />
          <strong className={cx('title')}>선택된 포지션이 없어요</strong>
          <p className={cx('desc')}>
            포지션을 등록하고 매칭챌린지를 시작해보세요
          </p>
        </div>
      )}
    </div>
  )
}

export default Step3ParticipationList
