export const PopupStopViewingType = {
  TODAY: 'TODAY',
  PERMANENT: 'PERMANENT',
  DISABLED: 'DISABLED',
} as const;

export const popupStopViewingTypeText: Record<PopupStopViewingType, string> = {
  TODAY: '오늘',
  DISABLED: '사용안함',
  PERMANENT: '영구',
};

export type PopupStopViewingType = keyof typeof PopupStopViewingType;
