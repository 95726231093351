import { get, post, put } from '../../../util/fetch';
import { GetTenantListRs } from './rs';
import { SaveCurationOptionRq } from './rq';
import { ContractApprovalStatusTypeV2 } from '../../../types/CompanyApprovalStatusType';

export const putApproveTenant = (
  tenantIdList: number[],
  approvalStatus: Extract<ContractApprovalStatusTypeV2, 'APPROVAL_DENIED' | 'APPROVAL_COMPLETE'>
) => {
  return put('/api/v1/tenant/approval', { tenantIdList, approvalStatus });
};

export const getTenantListV2 = (searchParams?: string) => {
  return get<GetTenantListRs>(`/api/v2/tenant?${searchParams}`);
};

export const saveCurationOptionV1 = (rq: SaveCurationOptionRq) => {
  return post('/api/v1/tenant/curation-option', rq);
};
