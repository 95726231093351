export const hexColor = (hex: string) => {
  const reg = /^#[0-9a-f]{3,6}$/i
  return reg.test(hex)
}

// From ATS
// 숫자를 포함하는지 체크
export const numberPattern = /[0-9]/;

// 영문 소문자를 포함하는지 체크
export const englishLowercasePattern = /[a-z]/;

// 영문 대문자를 포함하는지 체크
export const englishUppercasePattern = /[A-Z]/;

// 특수문자를 포함하는지 체크
export const specialPattern = /[~!@#$%^&*()_+|<>?:{}]/;

// 이메일 형식이 맞는지 체크
export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// 입력 불가능한 일부 특수문자를 포함하는지 체크
export const invalidSpecialPattern = /[~$<>'"?/]/;

// 패스워드에서 입력 불가능한 일부 특수문자를 포함하는지 체크
export const invalidPasswordSpecialPattern = /[$<>'"?/]/;

// 전화번호 형식이 맞는지 체크
export const phonePattern = /(^01([016789]))([0-9]{3,4})([0-9]{4})$/;

// 하이픈 포함된 전화번호 체크
export const mobilePhoneWithHyphenPattern = /^010-?([0-9]{4})-?([0-9]{4})$/;

// 값이 영문과 숫자로만 이루어졌는지 체크
export const numberAndEnglishPattern = /^([0-9]|[a-z]|[A-Z])+$/;
