// Created by kdw0601 on 2022-12-27
import { forwardRef, InputHTMLAttributes } from 'react'
import styles from './Radio.module.scss'
import classNames from 'classnames/bind'
import useForwardedRef from '../../../hooks/useForwardedRef'

const cx = classNames.bind(styles)

const NewCheckbox = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(function NewCheckbox(
  props,
  ref
) {
  const { className, checked, tabIndex, ...htmlAttr } = props
  const inputRef = useForwardedRef(ref)

  const handleClick = () => {
    inputRef.current?.click()
  }

  return (
    <>
      <input
        type={'radio'}
        className={cx('input')}
        checked={checked}
        hidden
        ref={inputRef}
        data-designsystem
        {...htmlAttr}
      />
      <div
        tabIndex={tabIndex !== undefined ? tabIndex : htmlAttr.disabled ? undefined : 0}
        className={cx('radio', className)}
        onClick={handleClick}
        data-designsystem
      />
    </>
  )
})

export default NewCheckbox
