// Created by kdw0601 on 2024-07-04
import styles from './ProductDetailV1.module.scss';
import classNames from 'classnames/bind';
import InputText from '../../common/input/InputText';
import React from 'react';
import { useProductDetailStore } from './store/useProductDetailStore';

const cx = classNames.bind(styles);

const ProductDetailV1TitleAndDescription = () => {
  const name = useProductDetailStore((state) => state.data.name);
  const locked = useProductDetailStore((state) => state.data.locked);
  const description = useProductDetailStore((state) => state.data.description);
  const errors = useProductDetailStore((state) => state.errors);
  const { setName, setDescription } = useProductDetailStore((state) => state.actions);

  return (
    <div className={cx('itemGroup')}>
      <h4>상품 정보</h4>
      {/*상품명*/}
      <div className={cx('item')}>
        <div className={cx('label')}>
          상품명<strong>*</strong>
        </div>
        <div className={cx('value')}>
          <InputText
            disabled={locked}
            defaultValue={name}
            maxLength={50}
            onChange={(e) => setName(e.currentTarget.value)}
            error={errors.name}
          />
        </div>
      </div>
      {/*  설명*/}
      <div className={cx('item')}>
        <div className={cx('label')}>설명</div>
        <div className={cx('value', 'description-value')}>
          <textarea
            className={cx('textarea')}
            disabled={locked}
            maxLength={200}
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductDetailV1TitleAndDescription;
