import React, { FC, MouseEventHandler } from 'react'

import { ReactComponent as BoldIcon } from './asset/editor_icons/icon_bold_48_line.svg'
import { ReactComponent as UnderlineIcon } from './asset/editor_icons/icon_underlined_u_48_line.svg'
import { ReactComponent as NumberedListIcon } from './asset/editor_icons/icon_list_numbered_48_line.svg'
import { ReactComponent as BulletedListIcon } from './asset/editor_icons/icon_list_48_line.svg'
import { ReactComponent as HighlightIcon } from './asset/editor_icons/icon_highlighter_48_filled.svg'
import { ReactComponent as InsertLinkIcon } from './asset/editor_icons/icon_link_48_line.svg'
import { ReactComponent as TextTypeIcon } from './asset/editor_icons/icon_font_size_48_line.svg'
import { ReactComponent as TextTypeA1Icon } from './asset/editor_icons/editor_toolbar_text_type_a1.svg'
import { ReactComponent as TextTypeA2Icon } from './asset/editor_icons/editor_toolbar_text_type_a2.svg'
import { ReactComponent as TextTypeA3Icon } from './asset/editor_icons/editor_toolbar_text_type_a3.svg'
import { ReactComponent as ImageIcon } from './asset/editor_icons/icon_photo_24_line.svg'

export type IconTypes =
  | 'bold'
  | 'underline'
  | 'numbered-list'
  | 'bulleted-list'
  | 'highlight'
  | 'link'
  | 'text-type'
  | 'text-type-a1'
  | 'text-type-a2'
  | 'text-type-a3'
  | 'image'

interface Props {
  name: IconTypes
  size?: 18 | 24 | 32 | 48
  color?: string
  className?: string
  onClick?: MouseEventHandler<HTMLElement>
}

const EditorIcon: FC<Props> = (props) => {
  const { name, size = 24, color = 'black', className, onClick } = props
  const iconProps = {
    width: size,
    height: size,
    fill: color,
    className,
    onClick,
  }

  switch (name) {
    case 'bold':
      return <BoldIcon {...iconProps} />
    case 'underline':
      return <UnderlineIcon {...iconProps} />
    case 'numbered-list':
      return <NumberedListIcon {...iconProps} />
    case 'bulleted-list':
      return <BulletedListIcon {...iconProps} />
    case 'highlight':
      return <HighlightIcon {...iconProps} />
    case 'link':
      return <InsertLinkIcon {...iconProps} />
    case 'text-type':
      return <TextTypeIcon {...iconProps} />
    case 'text-type-a1':
      return <TextTypeA1Icon {...iconProps} />
    case 'text-type-a2':
      return <TextTypeA2Icon {...iconProps} />
    case 'text-type-a3':
      return <TextTypeA3Icon {...iconProps} />
    case 'image':
      return <ImageIcon {...iconProps} />
    default:
      return <></>
  }
}

export default EditorIcon
