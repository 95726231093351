// Created by kdw0601 on 2022-10-17
import styles from './UsagePage.module.scss'
import classNames from 'classnames/bind'
import React from 'react'
import Layout from '../../../components/common/Layout/Layout'
import UsageList from '../../../components/usage/list/UsageList'

const cx = classNames.bind(styles)

const UsagePage = () => {
  return (
    <Layout>
      <h2>사용량 조회</h2>
      <UsageList/>
    </Layout>
  )
}

export default UsagePage