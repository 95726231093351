import React, { useContext, useEffect, useRef, useState } from 'react'
import classNames from 'classnames/bind'
import style from './Step4Contents.module.scss'
import { Descendant } from 'slate'
import { EDITOR_DEFAULT_CONTENT } from './ContentsDefinition'
import { ChallengeContext } from '../../../../pages/challenge/list/ChallengeContext'
import { useQuery } from 'react-query'
import { getContentsPosition } from '../../../../repositories/challenge/detail/CreateChallengeRepository'
import { ReactComponent as AddIcon } from '../../../../asset/icon/icon_add_18_line.svg'
import SlateEditor from '../../../common/editor/SlateEditor'
import useOnClickOutside from '../../../common/useOnClickOutside'
import { ReactComponent as CloseIcon } from '../../../../asset/icon/icon_close_18_line_current.svg'
import { ReactComponent as ModifyIcon } from '../../../../asset/icon/icon_edit_24_line.svg'
import Input from '../common/Input'

const cx = classNames.bind(style)

const Step4Contents = () => {
  const {
    matchingChallengeId,
    CONTENT_LIST: { list, setList },
  } = useContext(ChallengeContext)

  const [modify, setModify] = useState(false)
  const [cur, setCur] = useState(0)
  const [inputTitle, setInputTitle] = useState('')
  const ref = useRef<HTMLInputElement>(null)
  const modifyRef = useRef<HTMLInputElement>(null)

  //컨텐츠 조회
  const { data, isSuccess } = useQuery(
    'getContentsList',
    async () => await getContentsPosition(matchingChallengeId!),
    {
      enabled: !!matchingChallengeId,
      cacheTime: 50,
    }
  )

  useEffect(() => {
    if (isSuccess) {
      setList(
        data.data.length
          ? data.data.map(
              ({ matchingChallengeContentId, title, contents }, order) => {
                return {
                  matchingChallengeContentId,
                  title,
                  name: '',
                  contents: JSON.parse(contents),
                  order,
                  modify: false,
                }
              }
            )
          : [
              {
                order: 0,
                title: '챌린지소개',
                name: '',
                contents: EDITOR_DEFAULT_CONTENT as Descendant[],
                modify: false,
              },
            ]
      )
    }
  }, [isSuccess])

  //컨텐츠 추가 ref
  useOnClickOutside(ref, () => {
    if (inputTitle) {
      setList([
        ...list,
        {
          order: list.length,
          title: inputTitle,
          name: '',
          contents: EDITOR_DEFAULT_CONTENT as Descendant[],
          modify: false,
        },
      ])

      setInputTitle('')
    }
    setModify(false)
  })

  //컨텐츠 수정 Ref
  useOnClickOutside(modifyRef, () => {
    if (inputTitle.length) {
      setList(
        list.map((content) =>
          cur === content.order
            ? { ...content, modify: false, title: inputTitle }
            : { ...content, modify: false }
        )
      )
      setInputTitle('')
    }
  })

  return (
    <div className={cx('container', 'modal-scroll')}>
      <h1 className={cx('title')}>인재 모집 컨텐츠</h1>
      <ul className={cx('content-list')}>
        {list.map(
          ({ title, matchingChallengeContentId, order, modify }, idx) => (
            <li
              className={cx('content', cur === order && 'active')}
              key={`${title}${matchingChallengeContentId}`}
              onClick={() => setCur(order)}
            >
              {modify ? (
                <input
                  className={cx('modify-input')}
                  maxLength={10}
                  value={inputTitle}
                  onChange={(e) => {
                    setInputTitle(e.target.value)
                  }}
                  ref={modifyRef}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && inputTitle.length) {
                      const target = list.filter(
                        (content) => content.order === order
                      )[0]
                      target.title = inputTitle
                      target.modify = false

                      setList([
                        ...list.slice(0, order),
                        target,
                        ...list.slice(order + 1, list.length),
                      ])
                      setInputTitle('')
                    }
                  }}
                />
              ) : (
                <>
                  {title}
                  <button
                    className={cx('modify')}
                    onClick={() => {
                      const target = list.filter(
                        (content) => content.order === order
                      )[0]
                      target.modify = true
                      setInputTitle(target.title)

                      setList([
                        ...list.slice(0, order),
                        target,
                        ...list.slice(order + 1, list.length),
                      ])
                    }}
                  >
                    <ModifyIcon fill={'#121619'} width={18} height={18} />
                  </button>
                  {idx !== 0 && (
                    <button
                      className={cx('remove')}
                      onClick={() => {
                        const newList = list.filter(
                          (content) => content.order !== order
                        )

                        setList(
                          newList.map((content, order) => ({
                            ...content,
                            order,
                          }))
                        )
                        setCur(0)
                      }}
                    >
                      <CloseIcon
                        fill={cur === order ? '#fff' : '#121619'}
                        width={18}
                        height={18}
                      />
                    </button>
                  )}
                </>
              )}
            </li>
          )
        )}
        {list.length < 5 ? (
          <li className={cx('add-content')} onClick={() => setModify(true)}>
            {modify ? (
              <>
                <input
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setModify(false)
                      setList([
                        ...list,
                        {
                          order: list.length,
                          title: inputTitle,
                          name: '',
                          contents: EDITOR_DEFAULT_CONTENT as Descendant[],
                          modify: false,
                        },
                      ])

                      setInputTitle('')
                    }
                  }}
                  onChange={(e) => setInputTitle(e.target.value)}
                  className={cx('add-title')}
                  maxLength={10}
                  placeholder={'소개명'}
                  ref={ref}
                />
              </>
            ) : (
              <>
                <AddIcon
                  className={cx('add-icon')}
                  width={18}
                  height={18}
                  fill={'#ADB5BD'}
                />
                텍스트 입력
              </>
            )}
          </li>
        ) : (
          <></>
        )}
      </ul>
      <div className={cx('editor-box')}>
        {/* Editor 가 Memo 로 인해 rerender 되지않아 Component 새로그려야함 */}
        {list.map(({ order }) =>
          order === cur ? (
            <SlateEditor
              key={order}
              value={list[cur]?.contents ?? []}
              onChange={(value) => {
                const newList = [...list]
                const target = newList.findIndex(({ order }) => order === cur)
                newList[target].contents = value

                setList(newList)
              }}
              placeholder={'챌린지 기술서를 입력하세요.'}
            />
          ) : (
            <></>
          )
        )}
      </div>
    </div>
  )
}

export default Step4Contents
