// Created by kdw0601 on 2024-07-04
import styles from './ProductDetailV1.module.scss';
import classNames from 'classnames/bind';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ButtonCommon from '../../common/button/ButtonCommon';
import { totalPriceSelector, useProductDetailStore } from './store/useProductDetailStore';
import { postProductV1 } from '../../../api/function/product';
import { ProductCompositionSaveDto } from '../../../api/dto/ProductComponsitionSaveDto';
import { useCheckAuthorizedAction } from '../../../feature/authority/utils/withAuthority';

const cx = classNames.bind(styles);

const ProductDetailV1Footer = () => {
  const navigate = useNavigate();
  const locked = useProductDetailStore((state) => state.data.locked);
  const unlock = useProductDetailStore((state) => state.actions.unlock);
  const totalPrice = useProductDetailStore(totalPriceSelector);
  const isAuthorizedEdit = useCheckAuthorizedAction('createEditUseProduct');

  const handleClickSave = async () => {
    const {
      data,
      actions: { setError, setCompositionAttr },
    } = useProductDetailStore.getState();

    // ----------------- validation -----------------
    let hasError = false;

    if (!data.name) {
      setError('name', '상품명을 입력해주세요');
      hasError = true;
    }

    data.compositionList.forEach((v) => {
      if (v.useType === 'USE' && v.divisionType === 'QUANTITY' && v.limitType !== 'UNLIMITED' && !v.quantity) {
        setCompositionAttr(v.type, { error: true });
        hasError = true;
      }
    });

    if (hasError) {
      return;
    }

    // ------------- validation end ----------------

    await postProductV1({
      id: data.productId,
      name: data.name,
      type: data.type,
      compositionList: data.compositionList.map<ProductCompositionSaveDto>((v) => ({
        compositionId: v.compositionId,
        price: v.price,
        compositionType: v.type,
        useType: v.useType,
        // 무제한일 경우 수량은 1로 고정
        quantity: v.limitType === 'UNLIMITED' ? 1 : v.quantity,
        limitType: v.limitType,
        overuseType: v.overuseType,
      })),
      description: data.description,
      payType: data.payType,
      price: totalPrice,
      matchingPrice: data.matchingPrice ?? 0,
      promotionPrice: data.promotionPrice ?? 0,
      etcPrice: data.etcPrice ?? 0,
    });

    navigate('/product/list');
  };

  return (
    <div className={cx('footer')}>
      <Link to={'/product/list'}>
        <ButtonCommon type={'secondary'}>이전</ButtonCommon>
      </Link>
      {isAuthorizedEdit && (
        <ButtonCommon type={'primary'} onClick={locked ? unlock : handleClickSave}>
          {locked ? '수정하기' : '저장하기'}
        </ButtonCommon>
      )}
    </div>
  );
};

export default ProductDetailV1Footer;
