import { post } from '../../util/fetch';
import { SignupResponse } from './SignupResponse';

interface signupRequest {
  email: string;
  password: string;
  birthday: string;
  phoneNumber: string;
  gender: 'MALE' | 'FEMALE';
  name: string;
}

export const signup = (request: signupRequest) => {
  return post<SignupResponse>('/api/v1/administrator/register', request);
};
