import React, { ComponentPropsWithoutRef, FC, forwardRef, Ref } from 'react';
import classNames from 'classnames/bind';

import styles from './ExpiredAndUpdatePasswordForm.module.scss';

import useExpiredAndUpdatePasswordForm from './useExpiredAndUpdatePasswordForm';
import InputText from '../../common/input/InputText';
import ButtonCommon from '../../common/button/ButtonCommon';
import Message from '../../common/message/Message';
import PasswordChangeCompleteModal from './PasswordChangeCompleteModal';

const cx = classNames.bind(styles);

const ErrorMessage = forwardRef<HTMLSpanElement, ComponentPropsWithoutRef<'span'>>(function FormErrorMessage(
  props,
  ref
) {
  return (
    <span className={cx('error')} data-test-id={'error-message'} ref={ref} {...props}>
      {props.children}
    </span>
  );
});

const ExpiredAndUpdatePasswordForm: FC = () => {
  const {
    passwordRegister: passwordRegister,
    newPasswordRegister,
    newPasswordRepeatRegister,
    onSubmit,
    errors,
    showChangedPasswordModal,
    goToLoginPage,
    onPostponePasswordExpiry,
    toastMessage,
  } = useExpiredAndUpdatePasswordForm();

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <h1>비밀번호 유효기간 만료</h1>
        <h2>
          회원님의 소중한 개인정보 보호를 위해 <br />
          비밀번호를 재설정해주세요.
        </h2>
        <form className={cx('form')} onSubmit={onSubmit}>
          <label>현재 비밀번호</label>
          <InputText
            {...passwordRegister}
            className={cx('input-text')}
            placeholder={'현재 사용하는 비밀번호를 입력해 주세요.'}
            password
          />
          {errors.password?.message && <ErrorMessage>{errors.password?.message}</ErrorMessage>}
          <label>변경 비밀번호</label>
          <InputText
            {...newPasswordRegister}
            className={cx('input-text')}
            placeholder={'변경할 비밀번호를 입력해 주세요.'}
            password
          />
          {errors.newPassword?.message && <ErrorMessage>{errors.newPassword?.message}</ErrorMessage>}
          <InputText
            {...newPasswordRepeatRegister}
            className={cx('input-text')}
            placeholder={'변경할 비밀번호를 다시 입력해 주세요.'}
            password
          />
          {errors.newPasswordRepeat?.message && <ErrorMessage>{errors.newPasswordRepeat?.message}</ErrorMessage>}

          <div className={cx('info-section')}>
            <li>비밀번호는 영문, 숫자, 특수문자를 모두 사용하여 8자리 이상으로 설정해주세요.</li>
            <li>특수 문자 중 $, &lt;, &gt;, &apos;, &quot;, ?, / 는 사용할 수 없습니다.</li>
            <li>비밀번호 변경시 로그인된 모든 디바이스에서 로그아웃 됩니다.</li>
            <li>바로 이전에 사용한 비밀번호와 동일한 비밀번호는 사용할 수 없습니다.</li>
          </div>

          <ButtonCommon className={cx('submit-button')} size={'large'} wide submitType>
            변경하기
          </ButtonCommon>

          <div className={cx('defer-change-button')} onClick={onPostponePasswordExpiry}>
            다음에 변경하기 (30일 뒤 알림)
          </div>
        </form>
      </div>

      {showChangedPasswordModal && <PasswordChangeCompleteModal logout={goToLoginPage} />}
      {toastMessage && (
        <Message>
          <span>{toastMessage}</span>
        </Message>
      )}
    </div>
  );
};

export default ExpiredAndUpdatePasswordForm;
