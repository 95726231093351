import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  signupPasswordValidate,
  UPDATE_PASSWORD_ERROR_MESSAGE,
  updatePasswordErrorCodeSet,
  UpdatePasswordErrorCodeType,
} from '../../../util/validate';
import { useMutation } from 'react-query';
import { postponePasswordExpiry, updatePassword } from '../../../repositories/member/MemberRepository';
import { useState } from 'react';
import { transformUpdatePasswordForm } from '../../../repositories/member/request/MemberRequest';
import useAuth from '../../../hooks/useAuth';
import { useAccountStore } from '../hooks/useAccountStore';

export interface UpdatePasswordFormData {
  password: string;
  newPassword: string;
  newPasswordRepeat: string;
}

const useExpiredAndUpdatePasswordForm = () => {
  const navigate = useNavigate();

  const { findRedirectUrl } = useAuth();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setError,
  } = useForm<UpdatePasswordFormData>();

  const { logout } = useAuth();

  const [showChangedPasswordModal, setShowChangedPasswordModal] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const goToLoginPage = () => {
    setShowChangedPasswordModal(false);
    navigate({ pathname: '/login', search: `?redirect=${findRedirectUrl()}` });
    logout();
  };

  const showToastMessage = (content: string, showTime = 2000) => {
    setToastMessage(content);
    setTimeout(() => {
      setToastMessage('');
    }, showTime);
  };

  const onPostponePasswordExpiry = async () => {
    try {
      await postponePasswordExpiry();
    } catch (e) {
      showToastMessage('비밀번호 만료 연장에 실패했어요.');
    } finally {
      navigate(findRedirectUrl());
      setShowChangedPasswordModal(false);
    }
  };

  const email = useAccountStore((state) => state.data.email);

  const updatePasswordResponse = useMutation('updatePassword', async (data: UpdatePasswordFormData) => {
    await updatePassword(transformUpdatePasswordForm(data, true));
  });

  const passwordRegister = register('password', {
    required: '비밀번호를 입력해 주세요.',
  });

  const newPasswordRegister = register('newPassword', {
    required: '비밀번호를 입력해 주세요.',
    validate: (value) => signupPasswordValidate(value, email),
  });

  const newPasswordRepeatRegister = register('newPasswordRepeat', {
    required: '비밀번호를 다시 입력해주세요.',
    validate: (value) => {
      if (value !== watch('newPassword')) return '비밀번호가 일치하지 않아요.';
      return true;
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updatePasswordResponse.mutateAsync(data);
      setShowChangedPasswordModal(true);
      reset();
    } catch (err: any) {
      const errCode: UpdatePasswordErrorCodeType = err.response.data.data.code;
      switch (errCode) {
        case 'AdministratorAuthenticationException':
          setError('password', { message: UPDATE_PASSWORD_ERROR_MESSAGE[errCode] });
          break;
        default:
          showToastMessage(
            updatePasswordErrorCodeSet.has(errCode)
              ? UPDATE_PASSWORD_ERROR_MESSAGE[errCode]
              : '서버가 불안정해요, 잠시 후 다시 시도해 주세요.'
          );
      }
    }
  });

  return {
    passwordRegister,
    newPasswordRegister,
    newPasswordRepeatRegister,
    onSubmit,
    errors,
    showChangedPasswordModal,
    goToLoginPage,
    onPostponePasswordExpiry,
    toastMessage,
  };
};

export default useExpiredAndUpdatePasswordForm;
