import { useQuery } from 'react-query';
import usePagination from '../../../hooks/usePagination';
import { useEffect, useState } from 'react';
import { getUsageList } from '../../../repositories/usage/usageRepository';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect';
import { useNavigate } from 'react-router-dom';
import ProductType from '../../../repositories/usage/vo/ProductType';
import { ContractStatusType } from '../../../repositories/contract/vo/ContractStatus';

const useUsageList = () => {
  const [pagination, setPagination, resetPagination] = usePagination();
  const [contractTypeFilter, setContractTypeFilter] = useState<(keyof typeof ProductType)[]>(
    Object.keys(ProductType) as (keyof typeof ProductType)[]
  );
  const [contractStatusFilter, setContractStatusFilter] = useState<ContractStatusType[]>(['COMPLETE', 'TERMINATION']);
  const searchParams = new URLSearchParams(location.search);
  const keyword = searchParams.get('search');
  const navigate = useNavigate();

  const [search, setSearch] = useState(keyword ?? '');

  const { data, refetch } = useQuery(
    ['settlesList', pagination, contractStatusFilter, contractTypeFilter],
    async () => {
      return await getUsageList({
        page: pagination.page,
        size: pagination.size,
        keyword: search,
        sort: [],
        contractStatusFilter,
        contractTypeFilter,
      });
    },
    { refetchOnWindowFocus: false }
  );

  useDebouncedEffect(
    () => {
      const params = new URLSearchParams(location.search);
      search ? params.set('search', search) : params.delete('search');
      navigate(params.toString() ? '?' + params : '');
    },
    500,
    [search]
  );

  useEffect(() => {
    resetPagination();
    setSearch(keyword ?? '');
    refetch();
  }, [keyword, contractTypeFilter, contractStatusFilter]);

  return {
    data: data?.data,
    search,
    setSearch,
    setPagination,
    contractTypeFilter,
    setContractTypeFilter,
    contractStatusFilter,
    setContractStatusFilter,
  };
};

export default useUsageList;
