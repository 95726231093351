import React, { FC } from 'react'
import classNames from 'classnames/bind'
import style from './ContractField.module.scss'
import ContractDetailErrorMessage from '../../../pages/contract/detail/ContractDetailErrorMessage'

const cx = classNames.bind(style)

interface Props {
  left: JSX.Element
  leftName: string
  leftRequired?: boolean;
  right?: JSX.Element
  rightName?: string
  block?: boolean
  errorField?: {message?: string};
}

const ContractField: FC<Props> = ({
  left,
  leftRequired,
  leftName,
  right,
  rightName,
  block,
  errorField
}) => {
  return (
    <>
    <div className={cx('container', errorField?.message && 'has-error')}>
      <div className={cx('field', block && 'block')}>
        <span className={cx('name', leftRequired && 'required')}>{leftName}</span>
        {left}
      </div>
      {right && (
        <div className={cx('field')}>
          <span className={cx('name')}>{rightName}</span>
          {right}
        </div>
      )}
    </div>
      {errorField && <ContractDetailErrorMessage className={cx('error')} field={errorField}/>}
    </>
  )
}

export default ContractField
