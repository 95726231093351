export const ContractStatus = {
  WAIT: '승인 대기',
  COMPLETE: '계약 승인',
  TERMINATION: '계약 종료',
  REJECT: '계약 반려',
  CANCEL: '계약 취소',
} as const;

export const ContractStatusType = {
  WAIT: 'WAIT',
  COMPLETE: 'COMPLETE',
  TERMINATION: 'TERMINATION',
  REJECT: 'REJECT',
  CANCEL: 'CANCEL',
} as const;

export type ContractStatusType = keyof typeof ContractStatusType;
