export const TENANT_APPROVING_STATUS: Record<string, string> = {
  APPROVED: '승인',
  APPROVING: '승인대기',
  DENY: '거절',
  PENDING: '승인진행중',
  FAILED: '승인실패',
  TENANT_BEFORE_CREATING: '생성 전',
  TENANT_CREATING: '생성중',
  TENANT_CREATING_FAILED: '생성 실패',
  TENANT_CREATED: '생성 완료',
  APPROVAL_WAITING: '승인 대기',
  APPROVAL_DENIED: '승인 거절',
  RE_APPROVAL_WAITING: '재승인 대기',
  APPROVAL_COMPLETE: '승인 완료',
  CONTRACT_COMPLETE: '계약 완료',
  NOT_AGREEMENT: '가입 동의 전',
};

export const TENANT_ACCOUNT_STATUS = {
  STOP: '비활성화',
  USE: '활성화',
};

export const TENANT_AGREEMENT_STATUS = {
  TERMS: '이용약관',
  POLICY: '개인정보 처리 방침',
  MARKETING_MESSAGE: '마케팅 메시지 동의',
  MARKETING_EMAIL: '마케팅 이메일 수신 동의',
};
