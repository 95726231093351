import { get, getFiledownloadXlsx, post, remove } from '../../../util/fetch'
import { CompanyListResponse } from './response/CompanyListResponse'
import { CompanyIsPendingResponse } from './response/CompanyIsPendingResponse'
import { ISortData } from '../../../components/common/sortableColumn/SortableColumn'

export const companyAccountList = (
  {
    page,
    size,
  }: {
    page: number
    size: number
  },
  { orderType, direction }: ISortData,
  status: string,
  search?: string
) => {
  const searchParams = new URLSearchParams({
    page: page.toString(),
    size: size.toString(),
    orderType: orderType,
    order: direction,
    ...(search ? { search } : {}),
    ...(status ? { status: status } : {}),
  })

  return get<CompanyListResponse>(`/api/tenant/v1?${searchParams.toString()}`)
}

export const companyAcceptList = (
  {
    page,
    size,
  }: {
    page: number
    size: number
  },
  { orderType, direction }: ISortData,
  search?: string
) => {
  const searchParams = new URLSearchParams({
    page: page.toString(),
    size: size.toString(),
    orderType: orderType,
    order: direction,
    search: search ?? '',
  })

  return get<CompanyListResponse>(`/api/v1/tenant/approving?${searchParams.toString()}`)
}

export const companyAcceptIdList = () => {
  return get<{ companyName: string; id: number }[]>('/api/v1/tenant-ids/approving')
}

export const companyListExcelDownload = () => {
  return getFiledownloadXlsx('/api/v1/tenant/excel', '기업계정_목록')
}

export const companyIsPending = () => {
  return get<CompanyIsPendingResponse>('/api/v1/tenant/approved/status')
}

export const approveTenants = (tenantIdList: number[]) => {
  return post(`/api/v1/tenant/approved`, { tenantIdList })
}

export const companyRemove = (id: number) => {
  return remove(`/api/v1/tenant/${id}`)
}
