import classNames from 'classnames/bind'
import React, { ComponentPropsWithoutRef, FC } from 'react'
import style from './GreenButton.module.scss'

const cx = classNames.bind(style)

type Props = {
  children: JSX.Element | string
  size: 'medium' | 'large' | 'small'
} & ComponentPropsWithoutRef<'button'>

const GreenButton: FC<Props> = (Props) => {
  return (
    <button type={'button'} className={cx('button', Props.size)} {...Props}>
      {Props.children}
    </button>
  )
}

export default GreenButton
