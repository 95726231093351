// Created by kdw0601 on 2024-01-31
import { useRef } from 'react'
import { put } from '../../util/fetch'

interface UploadResult {
  fileUid: string;
  publicKey: string;
  endDate: string;
}

const TenantContractMigrationPage = () => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const handleClickChange = async () => {
    if (textAreaRef.current === null) return;
    const changedContracts: UploadResult[] = JSON.parse(textAreaRef.current.value);

    for (const v of changedContracts) {
      await put('/api/v1/contracts/file', v)
    }

    alert('done!!')
  }

  return (
    <div>
      <textarea ref={textAreaRef} placeholder={'바뀐 계약서 목록 붙여넣기'}/>
      <div>
        <button onClick={handleClickChange}>바꿔치기~~~</button>
      </div>
    </div>
  )
}

export default TenantContractMigrationPage