import React, { FC, useContext, useEffect, useState } from 'react'
import Modal from '../../../../common/modal/Modal'
import classNames from 'classnames/bind'
import style from './ModalModifyTeamPicture.module.scss'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { fileUpload } from '../../../../../repositories/common/FileRepository'
import { ChallengeContext } from '../../../../../pages/challenge/list/ChallengeContext'

const cx = classNames.bind(style)

export interface TeamPicture {
  thumbnailImageUUID: string
  thumbnailImageHeight: number
  thumbnailImageWidth: number
  thumbnailImageX: number
  thumbnailImageY: number
  thumbnailOriginImageUUID: string
}

interface Props {
  onClose: () => void
  newFile: any
  teamPicture: TeamPicture
  index: number
}

const ModalModifyTeamPicture: FC<Props> = ({
  onClose,
  newFile,
  teamPicture: {
    thumbnailImageUUID,
    thumbnailImageHeight,
    thumbnailImageWidth,
    thumbnailImageX,
    thumbnailImageY,
    thumbnailOriginImageUUID,
  },
  index,
}) => {
  const {
    BASIC_INFO: { setThumbnail },
  } = useContext(ChallengeContext)
  const [cropper, setCropper] = useState<any>()
  const [image, setImage] = useState<string | null>(null)

  useEffect(() => {
    if (!newFile) {
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result as any)
    }
    reader.readAsDataURL(newFile)
  }, [newFile])

  const imgCropSave = () => {
    cropper.getCroppedCanvas().toBlob(async (blob: Blob) => {
      const formData = new FormData()
      formData.append('file', blob, 'temp.png')
      const data = await fileUpload(formData)
      const fileUid = data.fileUid
      const cropperData = cropper.getData()

      const newThumb = {
        thumbnailImageUUID: newFile ? fileUid : thumbnailOriginImageUUID,
        thumbnailOriginImageUUID: '',
        thumbnailImageX: parseInt(cropperData.x),
        thumbnailImageY: parseInt(cropperData.y),
        thumbnailImageWidth: parseInt(cropperData.width),
        thumbnailImageHeight: parseInt(cropperData.height),
      }

      // if (newFile) {
      //   pictureList = {
      //     pictureUid: '',
      //     originPictureUid: '',
      //     x: 131,
      //     y: 188,
      //     width: 1055,
      //     height: 1055,
      //   }
      // }

      setThumbnail({ ...newThumb })
    })

    onClose()
  }

  const getCropData = () => {
    return thumbnailImageUUID === thumbnailOriginImageUUID
      ? {}
      : {
          x: thumbnailImageX,
          y: thumbnailImageY,
          width: thumbnailImageWidth,
          height: thumbnailImageHeight,
        }
  }

  return (
    <Modal onClose={() => onClose()} contentClassName={cx('modal')}>
      <>
        <div className={cx('modal-header')}>
          <span className={cx('title')}>수정하기</span>
          <div className={cx('btn-group')}>
            <button className={cx('btn', 'cancel')} onClick={() => onClose()}>
              취소
            </button>
            <button className={cx('btn', 'save')} onClick={imgCropSave}>
              저장
            </button>
          </div>
        </div>
        <div className={cx('modal-body')}>
          <Cropper
            style={{ height: 450, width: '100%', backgroundColor: '#212529' }}
            zoomTo={0}
            initialAspectRatio={1.45}
            aspectRatio={1.45}
            src={image ?? ''}
            viewMode={2}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={0.8}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance)
            }}
            data={getCropData()}
            guides={true}
          />
        </div>
      </>
    </Modal>
  )
}
export default ModalModifyTeamPicture
