import React from 'react'
import classNames from 'classnames/bind'
import style from './NewSignup.module.scss'
import useSignupForm from './useSignupForm'

const cx = classNames.bind(style)

const NewSignupForm = () => {
  const {
    emailRegister,
    passwordRegister,
    repasswordRegister,
    birthdayRegister,
    phoneNumberRegister,
    onSubmit,
    genderRegister,
    nameRegister,
    setValue,
  } = useSignupForm()

  return (
    <form className={cx('container')} onSubmit={onSubmit}>
      <div className={cx('format')}>
        <span className={cx('label')}>ID : </span>
        <input {...emailRegister} className={cx('input')} placeholder={'id'} />
      </div>
      <div className={cx('format')}>
        <span className={cx('label')}>Password : </span>
        <input
          {...passwordRegister}
          className={cx('input')}
          placeholder={'password'}
          type={'password'}
        />
      </div>
      <div className={cx('format')}>
        <span className={cx('label')}>rePassword : </span>
        <input
          {...repasswordRegister}
          className={cx('input')}
          placeholder={'id'}
          type={'password'}
        />
      </div>
      <div className={cx('format')}>
        <span className={cx('label')}>Phone : </span>
        <input
          {...phoneNumberRegister}
          className={cx('input')}
          placeholder={'Phone'}
        />
      </div>
      <div className={cx('format')}>
        <span className={cx('label')}>Name : </span>
        <input {...nameRegister} className={cx('input')} placeholder={'Name'} />
      </div>
      <div className={cx('format')}>
        <span className={cx('label')}>Birth : </span>
        <div className={cx('radio-area')}>
          <span
            className={cx('radio')}
            onChange={() => {
              setValue('gender', 'MALE')
            }}
          >
            <input
              {...genderRegister}
              placeholder={'yyyy-mm-dd'}
              type={'radio'}
              value={'MALE'}
              name={'gender'}
            />
            <span className={cx('marker')} />남
          </span>
          <span
            className={cx('radio')}
            onClick={() => {
              setValue('gender', 'FEMALE')
            }}
          >
            <input
              {...genderRegister}
              placeholder={'yyyy-mm-dd'}
              type={'radio'}
              value={'FEMALE'}
              name={'gender'}
            />
            <span className={cx('marker')} />여
          </span>
        </div>
      </div>
      <div className={cx('format')}>
        <span className={cx('label')}>Birthday : </span>
        <input
          {...birthdayRegister}
          className={cx('input')}
          placeholder={'yyyy-mm-dd'}
          name={'birthday'}
        />
      </div>
      <button className={cx('button')}>회원가입</button>
    </form>
  )
}

export default NewSignupForm
