import React, { ChangeEvent, SetStateAction, useContext, useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import classNames from 'classnames/bind';

import style from './Picture.module.scss';
import ModalModifyTeamPicture from './ModalModifyTeamPicture';
import { getBaseUrl } from '../../../../../util/fetch';
import { ChallengeContext } from '../../../../../pages/challenge/list/ChallengeContext';
import { ReactComponent as AddIcon } from '../../../../../asset/icon/icon_add_18_line.svg';
import Message from '../../../../common/message/Message';

const cx = classNames.bind(style);

const FILE_MAX_SIZE = 52428800;

const Picture = () => {
  const {
    BASIC_INFO: { thumbnail },
  } = useContext(ChallengeContext);
  const fileInput = useRef<HTMLInputElement>(null);
  const [newFile, setNewFile] = useState<SetStateAction<Blob | null>>(null);

  const [validMessage, setValidMessage] = useState(false);
  const [showModifyTeamPicture, setShowModifyTeamPicture] = useState(false);

  const fileInputChange = async (_file: Blob & { name: string }) => {
    const fileName = _file.name.split('.');
    const exc = fileName[fileName.length - 1].toLowerCase();

    if (exc !== 'gif' && exc !== 'jpg' && exc !== 'jpeg' && exc !== 'png') {
      setValidMessage(true);
      setTimeout(() => setValidMessage(false), 3000);
      return;
    }
    if (FILE_MAX_SIZE < _file.size) {
      return;
    }
    setShowModifyTeamPicture(true);
    setNewFile(_file);
  };

  const handleFileInput = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      await fileInputChange(e.target.files[0]);
    }
  };

  return (
    <>
      <input
        className={cx('file-input')}
        type="file"
        accept=".gif, .jpg, .jpeg, .png"
        ref={fileInput}
        onClick={() => {
          if (fileInput.current) fileInput.current.value = '';
        }}
        onChange={handleFileInput}
      />
      <div
        className={cx('wrapper')}
        onClick={() => {
          fileInput.current && fileInput.current.click();
        }}
        style={{
          backgroundImage: thumbnail.thumbnailImageUUID
            ? `url(${getBaseUrl()}/api/file/view/${thumbnail.thumbnailImageUUID})`
            : 'none',
        }}
      >
        <FileDrop
          className={cx('picture')}
          onDrop={(files) => {
            if (files) {
              fileInputChange(files[0]);
            }
          }}
        >
          {thumbnail.thumbnailImageUUID ? (
            <></>
          ) : (
            <div className={cx('add-wrapper')}>
              <AddIcon width={32} height={32} fill={'#6A7081'} />
            </div>
          )}
        </FileDrop>
      </div>
      {showModifyTeamPicture && (
        <ModalModifyTeamPicture
          onClose={() => setShowModifyTeamPicture(false)}
          newFile={newFile}
          teamPicture={thumbnail}
          index={0}
        />
      )}
      {validMessage && (
        <Message>
          <span>확장자는 .gif, .jpg, .jpeg, .png 만 가능합니다.</span>
        </Message>
      )}
    </>
  );
};

export default Picture;
