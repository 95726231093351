// Created by kdw0601 on 2022-10-20
import styles from './InputNumberStep.module.scss'
import classNames from 'classnames/bind'
import React, { useEffect, useState } from 'react'
import InputNumber from '../input/InputNumber'

const cx = classNames.bind(styles)

interface Props {
  defaultValue?: number;
  onChange?: (val: number) => void;
  min: number;
  max: number;
}

const InputNumberStep = ({max,min,defaultValue,onChange}: Props) => {
  const [value, setValue] = useState(defaultValue ?? min)

  const handleClickArrow = (addValue: number) => {
    const newValue = value + addValue;
    setValue(newValue > max ? min : newValue < min ? max : newValue);
  }

  useEffect(() => {
    onChange && onChange(value);
  }, [value])

  return (
    <div className={cx('wrap')}>
      <button onClick={() => handleClickArrow(1)}>^</button>
      <InputNumber className={cx('number')} step={1} value={value} onChange={setValue} noComma min={min} max={max}/>
      <button onClick={() => handleClickArrow(-1)}>^</button>
    </div>
  )
}

export default InputNumberStep