import { useForm } from 'react-hook-form'
import useAuth from '../../hooks/useAuth'

export interface LoginForm {
  email: string
  password: string
}

const useLoginForm = () => {
  const { handleSubmit, register, reset } = useForm<LoginForm>()
  const { login, findRedirectUrl } = useAuth()

  const emailRegister = register('email', {
    required: '이메일을 입력해 주세요.',
  })

  const passwordRegister = register('password', {
    required: '비밀번호를 입력해 주세요.',
  })

  const onSubmit = handleSubmit(async (form: LoginForm) => {
    try {
      await login(form, findRedirectUrl())
    } catch (e: any) {
      reset()
    }
  })

  return {
    onSubmit,
    emailRegister,
    passwordRegister,
  }
}

export default useLoginForm
