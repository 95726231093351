import { FC } from 'react'
import classNames from 'classnames/bind'

import styles from './Message.module.scss'
import Portal from '../portal/Portal'
import React from 'react'

const cx = classNames.bind(styles)

interface Props {
  className?: string
  onClose?: (v: boolean) => void
  children: JSX.Element
}

const Message: FC<Props> = ({ className, children, onClose }) => {
  return (
    <Portal domId={'message-root'}>
      <section className={cx('message', className)}>{children}</section>
    </Portal>
  )
}

export default Message
